import React, { useState } from 'react';
import './newhome.css';
import { useLocation } from 'react-router-dom';

const SeoContent = () => {
    const [showMore, setShowMore] = useState(false);
    const location = useLocation();
  
    // Function to extract city name from URL or provide a default city if not found
    const getCityName = () => {
        const pathname = location.pathname;
    
        // Match city name in the URL or default to 'Default City'
        const cityMatch = pathname.split('/')[1]; // Get first segment after '/'
        
        // If no city found in URL, return 'Default City'
        let cityName = cityMatch && cityMatch.length > 0 ? cityMatch.split('-')[0] : 'Ranchi';
        return cityName.charAt(0).toUpperCase() + cityName.slice(1); // Capitalize first letter
      };

    const cityName = getCityName();
  
    const handleToggle = () => {
      setShowMore(!showMore);
    };


  return (
    <div className="showContent">
      <div className="footer-content-wrapper">
        <div>
          <h2>
            Your heaven of beauty Curated just for you with Salon Ease Home
            salon services in {cityName}.
          </h2>
          <div className="hideContent">
            <p className="contentText">
              In the midst of a cosmopolitan city like {cityName}, where traffic
              and conveyance jamms punctuate even the most capable people's
              daily routine, taking time out for self-care appears to be an
              insurmountable task. Men and women turn out to be unstoppable
              forces, balancing multiple roles-from parents to partners, from
              entrepreneurs to lifelong learners. Amid this multiplicity, needs
              for the person are often lost to sight.
            </p>
            {showMore && (
              <>
                <p>
                  At SalonEase, we understand the ordeal of life in this
                  century. This is why we've brought the salon experience right
                  to your doorstep with our top-class home salon services in
                  {cityName}. Indulge in a host of treatments all from the
                  comfort of your home, earning you premium quality service,
                  transparent pricing, and professional-grade monodose products.
                </p>
                <h2>
                  SalonEase: Bringing You Exclusive Personalised Home Salon
                  Services In Posh Localities
                </h2>
                <p>
                  For staying in a cosmopolitan city like {cityName},
                  world-class amenities and a good lifestyle are sought after.
                  Most people opt for upscale localities for their families,
                  with good educational facilities. Living in this city can get
                  very hectic considering the long commutes as well as traffic.
                </p>
                <p>
                  All this has been changed by SalonEase. We have done so by
                  specialising in services required by our clients in the
                  comfort of their homes, for SalonEase is dedicated to quality.
                </p>
                <h2>
                  Pamper Yourself To The Services You Deserve This With Our
                  Women's Salon Services At Home.
                </h2>
                <p>
                  Now is the time to unleash your inner diva and shower that
                  skin with all the attention it deserves. SalonEase affords you
                  a number of safe and relaxing home salon services administered
                  by professional technicians who operate under stiff safety
                  standards. Relish a host of beauty treatments at hand, which
                  include:
                </p>
                <ul className="service-list">
                  <li>
                    <strong>Clean-Up</strong>
                    <p>
                      Our cleaning solutions are designed to leave you feeling
                      radiant in a flash. Our experts exfoliate the skin using
                      gentle products, revitalise your complexion, reduce dark
                      circles, and even out the tone of your face and neck.
                    </p>
                  </li>
                  <li>
                    <strong>Facial</strong>
                  </li>
                  <p>
                    Restore youthfulness and rejuvenate the melting of all your
                    stresses and tension on your face, giving you back glowy
                    skin. From classic fruits to the sophisticated age-defying
                    treatments, a wide choice of facials ranges from hydration,
                    repairing, and reviving your skin for that youthful glow.
                    Our trained specialists will assist you in choosing the
                    right facial appropriate for your skin type and health.
                  </p>
                  <li>
                    <strong>Body Polish</strong>
                  </li>
                  <p>
                    This treatment will clean out dead skin cells and will aid
                    in blood circulation that cleans your skin. That's how you
                    get that fresh, smooth feel.
                  </p>
                  <li>
                    <strong>Body Polish</strong>
                  </li>
                  <p>
                    This body polish uses the rich ingredients for gently
                    exfoliating and polishing the skin to deliver a deep
                    hydration that leaves you to experience velvet softness.
                  </p>
                  <li>
                    <strong>Waxing</strong>
                  </li>
                  <p>
                    We only employ great products in our waxing and during
                    waxing sensitive areas, we use strict hygiene to ensure that
                    there aren't blemishes or side effects. Waxing services
                    include full arms, underarms, full legs, bikini, full body,
                    half/full back, and stomach. Need a trusted nearby waxing
                    service? Book with us today!
                  </p>
                  <li>
                    <strong>Manicure</strong>
                  </li>
                  <p>
                    Sit back, relax, and let the professionals pamper your hands
                    with gentle, moisturising ingredients. Complete with a pop
                    of color on your nails and give your fingertips an oomph.
                  </p>
                  <li>
                    <strong>Pedicure</strong>
                  </li>
                  <p>
                    Relax with our massaging oil that increases blood
                    circulation and relieves any possible pain. Our exemplary
                    care includes clipping, filling, and deep cleaning of your
                    feet and toenails, which make you look radiant and
                    soft-skinned. You may also choose the colour you want for
                    your nails and portray your vibrant personality.
                  </p>
                  <li>
                    <strong>Threading</strong>
                  </li>
                  <p>
                    Our flawless threading services consist of eyebrow shape,
                    facial, upper and lower lip, chin, sideburn, and forehead
                    threading, accomplished with precision by our expert team.
                  </p>
                </ul>

                <h2>
                  Create a Stir By All Heads With Our Hair Services at home
                </h2>
                <p>
                  Take a break from salon visits! SalonEase brings you an
                  extensive hair solution experience straight into your home in
                  {cityName}. Let our highly skilled professionals transform
                  your look using the best products and techniques.
                </p>
                <p>
                  Whether you want a hair-cut, touch-up, or even scalp scrub,
                  our experts at SalonEase have it all for you at the comfort of
                  your very own home.
                </p>
                <ul className="service-list">
                    <li>
                    <strong>Hair Wash</strong>
                    </li>
                    <p>
                    After getting a soothing scalp scrub or henna or hair colour,
                    avail our head wash service to make your hair shine clean.
                    </p>
                    <li>
                    <strong>Hair Blow Dry</strong>
                    </li>
                    <p>
                    Transform your regular hair into extraordinary one by our
                    expertly trained hands with our quick blow dry service.
                    </p>
                    <li>
                    <strong>Scalp Scrub</strong>
                    </li>
                    <p>
                    Relax and enjoy healthy hair growth through our scalp scrub
                    service, which stimulates good circulation of the blood in
                    your scalp.
                    </p>
                    <li>
                    <strong>Hair Root Touch-Up</strong>
                    </li>
                    <p>
                    Choose the colour and we will add it to your hair with our
                    high precision application to ensure the best for you.
                    </p>
                    <li>
                    <strong>Heena Application</strong>
                    </li>
                    <p>
                    Our high-qualified service providers maintain strict hygiene.
                    And, so enjoy mess-free henna applied on the entire mane.
                    </p>
                    <li>
                    <strong>Haircut</strong>
                    </li>
                    <p>
                    Our professional stylists provide you with haircutting
                    services that suit your needs and will be given customised
                    recommendations based on the texture of the hair and the shape
                    of your face.
                    </p>
                    <li>
                    <strong>Hairstyling</strong>
                    </li>
                    <p>
                    Planning a last-minute dinner invitation? Don't stress at all!
                    Our experts will come to your doorstep to do your hair and can
                    be sure of all the turns to come to look at you.
                    </p>
                    <li>
                    <strong>Hair Fall Control Shots Vitaliser</strong>
                    </li>
                    <p>
                    Let your precious locks not fall by the way; unique treatments
                    for all types of hair are offered, which will make you
                    confident and beautiful.
                    </p>
                </ul>
                <h2>
                  Escape the Mayhem of Daily Life with SalonEase Female SPA
                  Services at home
                </h2>
                <p>
                  Experience the life-environment with SalonEase SPA services.
                  Our well-experienced people can offer treatments such as the
                  full body massage and premium facials which will give
                  relaxation and rejuvenation.
                </p>
                <ul className="service-list">
                    <li>
                        <strong>Full Body Massage
                            
                        </strong>
                    </li>
                    
                    <p>
                    Enjoy a restorative massage with natural oils delicately
                    applied to soothe both body and mind easing tensions and
                    stress.
                    </p>
                    <li>
                        <strong>Premium Spa Treatments
                            
                        </strong>
                    </li>
                  
                <p>
                  Treat yourself to the best in advanced facials and customised
                  massage provided to flush out poisons from your skin and leave
                  you refreshed and rejuvenated.
                </p>
                </ul>
                <h2>SalonEase offers Personalised Makeup Services at home</h2>
                <p>
                  Our professionals possess the skills to give you looks for
                  every occasion. Whether it's a party or an expertly designed
                  style for your hair, we are here to bring out the beauty from
                  inside you.
                </p>
                <h2>
                  Be Your Best on Your Special Day with SalonEase's Pre-Bridal
                  Services at home
                </h2>
                <p>
                  Every bride deserves to look beautiful on her wedding day.
                  SalonEase provides high-end packages under Pre-Bridal
                  Services, customised skin, hair, and body treatments, to make
                  you feel and look fabulous for the most memorable day.
                </p>
                <p>
                  Our specialist will provide you with the best possible
                  appearance so you could feel confident while walking down that
                  aisle.
                </p>
              </>
            )}
            <span onClick={handleToggle} className="toggleText">
                <strong>{showMore ? 'Show Less' : 'Show More'}</strong>
              
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Named export instead of default export
export default SeoContent;
