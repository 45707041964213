import React from 'react'
import Homeheader from '../layouts/home_header'
import Footer from '../layouts/footer'
import '../components/home/whychooseus.css'
export default function RefundPolisy() {
    return (
        <div>
            <Homeheader />
            <>

                <div className='why_choose_us_card'>
                    <div className="feat bg-gray pt-5 pb-5 card_pad">
                        <div className="container">
                            <div className="row">
                                <div className="section-head col-sm-12">
                                    <h4><span>Our Refund</span> Policy</h4>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>Effective Date: 17 Aug 2023</h5>
                                    <p>At Salon Ease, we value your satisfaction and strive to provide top-notch salon services. This Refund Policy outlines the conditions under which refunds may be granted for services booked through our application..</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>1. Eligibility for Refunds</h5>
                                    <p>Refunds may be considered under the following circumstances:</p>
                                    <ul>
                                        <li>Cancellation by Salon Ease: In the rare event that we are unable to fulfill your appointment due to unforeseen circumstances, we will initiate a full refund.</li>
                                        <li>Service Quality Concerns: If you are dissatisfied with the quality of the service provided, please contact us within [24 hours] of the appointment to discuss your concerns.</li>
                                    </ul>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>2. Refund Process</h5>
                                    <p>To request a refund, please follow these steps:</p>
                                    <ol>
                                        <li>Contact Customer Support: Reach out to our customer support team via the application or through our designated communication channels.</li>
                                        <li>Provide Details: Clearly state the reason for your refund request, along with relevant details and any supporting evidence.</li>
                                        <li>Allow Processing Time: Our team will review your request and respond within [3-5 business days] with the outcome.</li>
                                    </ol>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>3. Exclusions from Refunds</h5>
                                    <p>Refunds will not be provided under the following circumstances:</p>
                                    <ul>
                                        <li>Change of Mind: If you decide to cancel your appointment for reasons unrelated to service quality.</li>
                                        <li>Failure to Provide Access: If you fail to provide our salon professional access to your specified location at the scheduled appointment time.</li>
                                        <li>Late Cancellation: If you cancel your appointment within [2 hours] of the scheduled time.</li>
                                    </ul>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>4. Partial Refunds</h5>
                                    <p>In some cases, partial refunds may be offered based on the extent of the issue. This will be determined on a case-by-case basis.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>5. Payment Reversals</h5>
                                    <p>Refunds will be processed using the original payment method used for the booking.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>6. Contact Us</h5>
                                    <p>If you have any questions or concerns regarding our refund policy, please write us at:<br/>hello@salonease.in</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>





            </>
            <Footer />
        </div>
    )
}
