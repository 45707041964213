import React from 'react'
import ServiceHead from '../layouts/serviceHead'
import '../components/cart/cartPage.css'
import CartPage from '../components/cart/cartPage'
import Footer from '../layouts/footer'
import Cartheader from '../layouts/cart_header'

export default function cart() {
  return (
    <>
      <Cartheader />
      <CartPage />
      <Footer />
    </>
  )
}
