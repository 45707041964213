import React from 'react'
import NewHome from '../components/home/newHome'
import Footer from '../layouts/footer'
import Homeheader from '../layouts/home_header'

export default function NewHomePage() {
  return (
    <>
      <Homeheader />
      <NewHome />
      <Footer />
    </>
  )
}
