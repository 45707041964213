import React, { useEffect, useRef, useState } from 'react';
import './subcat.css';

// Import icons from react-icons or any other icon library you prefer
import {
  FaScissors,
  FaDroplet,
  FaWind,
  FaSun,
  FaBolt,
  FaFeather,
  FaSparkles,
  FaBars,
  FaTimes,
  FaTag,
} from 'react-icons/fa';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { serverAPI } from '../..';

function SalonSubcategories() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const apiCalledRef = useRef(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }
  const handleServiceClick = item => {
    // // Construct the URL with the required parameters
    const url = `/services/${toKebabCase(item.category)}`;
    // // Navigate to the specified URL
    navigate(url, { state: item });
  };
  const getAllCatrgories = () => {
    axios
      .get(`${serverAPI}/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        setCategories([]);
        console.error('Error fetching categories:', error);
      });
  };

  const handleServiceClick2 = item => {
    const pathname = location.pathname;
    const cityMatch = pathname.split('/')[1]; // Get first segment after '/'
    let cityName =
      cityMatch && cityMatch.length > 0 ? cityMatch.split('-')[0] : '';
    const city = cityName.charAt(0).toLowerCase() + cityName.slice(1); // Capitalize first letter
    console.log('hello', city);
    // console.log(city === '', 'citycity');
    let url = '';
    if (city === '' || city === 'at') {
      url = `/at-home-service/${toKebabCase(item.name)}`;
    } else {
      url = `/${city}/${toKebabCase(item.name)}`;
    }
    console.log(url, 'fgdfgdg');

    setIsModalOpen(false);
    navigate(url, { state: item });
  };
  //   const getAllSubCategories = () => {
  //     axios
  //       .get(`${serverAPI}/subcategories/list/${location.state._id}`)
  //       .then(response => {
  //         setCategories(response.data);
  //       })
  //       .catch(error => {
  //         setCategories([]);
  //         console.error('Error fetching categories:', error);
  //       });
  //   };
  useEffect(() => {
    getAllCatrgories();
    // if (apiCalledRef.current) return; // Exit if API has already been called
    // apiCalledRef.current = true;

    const fetchCategories = async url => {
      try {
        const response = await axios.get(url);
        setSubCategories(response.data);
      } catch (error) {
        setSubCategories([]);
        console.error('Error fetching categories:', error);
      }
    };

    const getSeoKeyword = () => {
      const pathParts = location.pathname.split('/');
      return pathParts[pathParts.length - 1];
    };

    if (location?.state?._id) {
      const subcategoryUrl = `${serverAPI}/subcategories/list/${location.state._id}`;
      fetchCategories(subcategoryUrl);
    } else {
      const seoKeyword = getSeoKeyword();
      const seoKeywordUrl = `${serverAPI}/subcategories/seokeyword?seokeyword=${seoKeyword}`;
      fetchCategories(seoKeywordUrl);
    }
  }, [location]);

  //   useEffect(() => {
  //     getAllSubCategories();
  //   }, []);
  return (
    <div className="salon-container">
      {/* Offer Banner */}
      <div className="offer-banner">
        {/* <FaTag /> */}
        <p>Special Offer: 20% off on all services !</p>
      </div>

      {/* Main Banner */}
      <div className="main-banner">
        <img
          src={
            window.innerWidth > 570
              ? location?.state?.bannerImage_web
              : location?.state?.bannerImage_mobile
          }
          alt="Salon Banner"
          className="main-banner-image"
        />
        {/* <div className="main-banner-overlay" />
        <div className="main-banner-content">
          <h1>Discover Our Services</h1>
          <p>Elevate your style with our expert treatments</p>
        </div> */}
      </div>

      {/* Subcategories Grid */}
      <div className="subcategories-grid">
        {subCategories.map((subcategory, index) => (
          <div key={index} className="subcategory-card">
            <div
              className="subcategory-content"
              onClick={() => {
                handleServiceClick(subcategory);
              }}
            >
              <div className="subcategory-icon">
                <img
                  src={subcategory.image}
                  alt={subcategory.name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' ,  borderRadius: '10px' }}
                />
              </div>
              <h3 className="subcategory-name">{subcategory.name}</h3>
            </div>
          </div>
        ))}
      </div>

      {/* Floating Menu Button */}
      <button
        className="floating-menu-button"
        onClick={() => setIsModalOpen(true)}
      >
        <FaBars size={24} />
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2 className="modal-title">Our Services</h2>
              <button
                className="modal-close-button"
                onClick={() => setIsModalOpen(false)}
              >
                {/* <FaTimes size={24} /> */}
              </button>
            </div>
            {/* category inside popup */}
            <div className="modal-grid">
              {categories.map((subcategory, index) => (
                <div
                  key={index}
                  className="modal-item"
                  onClick={() => {
                    handleServiceClick2(subcategory);
                  }}
                >
                  <div className="modal-item-icon">
                    <img
                      src={subcategory.image}
                      alt={subcategory.name}
                      style={{
                        width: '42px',
                        height: '42px',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <span className="modal-item-name">{subcategory.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SalonSubcategories;
