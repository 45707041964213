import React from 'react'
import Footer from '../layouts/footer'
import Trackorder from '../components/profilepage/trackorder'
import Homeheader from '../layouts/home_header'

export default function TrackOrder() {
    return (
        <>
            <Homeheader />
            <Trackorder />
            <Footer />
        </>
    )
}
