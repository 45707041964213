import React, { useRef, useState, useEffect } from 'react'
import './loginpage.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Back from '../../assets/images/arrow-left.png'
import '../../layouts/login_head.css'
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
import BeatLoader from "react-spinners/BeatLoader";

export default function OTPPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isloding, setIsLoding] = useState(true)
    const phoneNumber = location.state?.phone || ''; // Get the phone number from the state
    const loginState = location.state?.login || '';
    // Create refs for the input fields
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    // Initialize state for the OTP value
    const [otpValue, setOtpValue] = useState(['', '', '', '']);

    // Function to focus on the next input field
    const focusNextInput = (index) => {
        if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    // Function to focus on the previous input field
    const focusPreviousInput = (index) => {
        if (index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    // Function to handle input change
    const handleInputChange = (e, index) => {
        const value = e.target.value;

        // Use a regular expression to ensure only one digit
        const oneDigitValue = value.replace(/[^0-9]/g, '');

        // Limit the total length to 4 characters
        if (oneDigitValue.length <= 1) {
            // Set the input value in the state
            const updatedOtpValue = [...otpValue];
            updatedOtpValue[index] = oneDigitValue;
            setOtpValue(updatedOtpValue);

            // Focus on the next input
            focusNextInput(index);
        }
    };

    // Function to handle keydown event
    const handleKeyDown = (e, index) => {
        // Check if the backspace key is pressed and the input is empty
        if (e.keyCode === 8 && otpValue[index] === '') {
            // Clear the previous input and focus on it
            const previousIndex = index - 1;
            if (previousIndex >= 0) {
                const updatedOtpValue = [...otpValue];
                updatedOtpValue[previousIndex] = '';
                setOtpValue(updatedOtpValue);
                inputRefs[previousIndex].current.focus();
            }
        }
    };
    // Function to handle paste event
    const handlePaste = (e) => {
        // Prevent default paste behavior
        e.preventDefault();

        // Get the pasted text
        const pastedText = e.clipboardData.getData('text/plain');

        // Extract digits from the pasted text
        const digits = pastedText.match(/\d/g);

        // Check if there are exactly 4 digits
        if (digits && digits.length === 4) {
            // Use setTimeout to allow the paste event to complete
            setTimeout(() => {
                // Clear all input fields first
                const updatedOtpValue = ['', '', '', ''];

                // Distribute the digits into the input fields
                for (let i = 0; i < inputRefs.length && i < digits.length; i++) {
                    const inputRef = inputRefs[i];
                    const digit = digits[i];
                    inputRef.current.value = digit;
                    updatedOtpValue[i] = digit; // Update the state
                }

                // Update the state with the new OTP value
                setOtpValue(updatedOtpValue);
            }, 0);
        }
    };

    const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    const handleLogin = () => {
        setIsLoding(false)
        const otp = otpValue.join('');

        axios
            .post(`${serverAPI}/auth/verify-otp`, { phone: phoneNumber, otp: otp }, {
                withCredentials: true, // Include credentials (cookies)
            }) // Use the formatted phone number in the request
            .then((response) => {
                if (response.status === 200) {
                    // User exists, run the login API
                    // let token = res.data.access;
                    localStorage.setItem("jwt", JSON.stringify(response.data.token));
                    toast.success("User Login Succesfully 😃!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setTimeout(() => {
                        if (loginState === true) {
                            setIsLoding(true)
                            // Navigate to "/profile_edit" with updated state
                            navigate("/", { state: { phone: null } });
                        } else {
                            setIsLoding(true)
                            // Navigate to "/profile_edit" with updated state
                            navigate("/profile_edit", { state: { phone: null } });
                        }
                    }, 2500);


                } else {

                    toast.error("Invalid otp or expired try again 😢!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setIsLoding(true)

                }
            })
            .catch((error) => {
                setIsLoding(true)
                toast.error("Invalid otp or expired try again 😢!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            });
    };

    const resendOTP = () => {
        axios
            .post(`${serverAPI}/auth/resend-otp`, { phone: phoneNumber }) // Use the formatted phone number in the request
            .then((response) => {
                if (response.status === 200) {
                    // User exists, run the login API
                    toast.success("OTP Sent Succesfully 😃!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setIsLoding(true)
                } else {
                    toast.success("OTP Sent Fail, please try again 😢!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setIsLoding(true)

                }
            })
            .catch((error) => {
                setIsLoding(true)
                console.error('Error finding or logging in user:', error);
            });
    };

    return (
        <>
            <div className="mobile_active">
                <div className="mobile_nav">
                    <div className="back-nav">
                        <table>
                            <tr>
                                <Link to='/mobillogin'><th> <img src={Back} width="20px" alt='' /></th></Link>
                                <td>Login / Signup</td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
            <div className='container'>
                <div className="mobilelogin">
                    <div className="LOGIN-body">
                        <h3>Enter verfication code</h3>
                        <p>We have send you a 4 digit opt <br /> on {phoneNumber} edit  </p>
                        <div className="otp-inputs">
                            <input
                                type="text"
                                className="otp-input"
                                maxLength="1"
                                ref={inputRefs[0]}
                                value={otpValue[0]}
                                onChange={(e) => handleInputChange(e, 0)}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                onPaste={handlePaste} // Handle paste event
                            />
                            <input
                                type="text"
                                className="otp-input"
                                maxLength="1"
                                ref={inputRefs[1]}
                                value={otpValue[1]}
                                onChange={(e) => handleInputChange(e, 1)}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                            />
                            <input
                                type="text"
                                className="otp-input"
                                maxLength="1"
                                ref={inputRefs[2]}
                                value={otpValue[2]}
                                onChange={(e) => handleInputChange(e, 2)}
                                onKeyDown={(e) => handleKeyDown(e, 2)}
                            />
                            <input
                                type="text"
                                className="otp-input"
                                maxLength="1"
                                ref={inputRefs[3]}
                                value={otpValue[3]}
                                onChange={(e) => handleInputChange(e, 3)}
                                onKeyDown={(e) => handleKeyDown(e, 3)}
                            />
                        </div>
                        {counter === 0 ?
                            <> <div onClick={resendOTP} className='resend' style={{ cursor: 'pointer' }}><h6> Resend OTP</h6></div> </> :
                            <> <div className='resend'><h6>Resend OTP in {counter} sec</h6></div></>
                        }
                        <button onClick={handleLogin} className="login-btn"> {isloding ? <>LOGIN </> : <BeatLoader color="#ffffff" size={10} />}</button>
                    </div>
                </div>

            </div>

        </>
    )
}
