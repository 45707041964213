import React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import ProfileMenu from '../components/profilepage/ProfileMenu';
import Homeheader from '../layouts/home_header';
import Myaccount from '../components/profilepage/myaccount';

export default function account() {
  return (
    <>
        <Homeheader />
        <Myaccount /> 
        <Footer />
    </>
  )
}
