import React, { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import './newquestion.css';

function NewQuestion() {
  const data = [
    {
      id: 1,
      question: 'Salon at Home for Women',
      answer:
        "Visiting the salon can often feel like a hassle, but we have a much more convenient solution for you. Skip the trip and let the salon come to you! With SalonEase, enjoy professional salon services at home, saving you both time and money while receiving excellent results. Our experienced beauticians bring all salon services to your doorstep, eliminating the need to search for 'salon services near me.' Just say SalonEase, and we’ll take care of the rest. From haircuts to pampering treatments, our experts deliver a complete setup that follows all safety measures for a smooth experience. " +
        "The next time you think of heading to the salon, think again! Visit the SalonEase website and get a number of salon and spa service options for females right from the comfort of your home.",
    },
    {
      id: 2,
      question: 'Book Beauty Services at Home',
      answer:
        'SalonEase is a trusted in-home beauty and wellness solution, bringing the best beauty services right to your door. We can replicate the salon experience right in your own home with the option for head-to-toe treatment. Worry not about the supplies because we bring all that is needed to make the process hassle-free. Whether running late, feeling tired, or just cannot make it to the parlor, just book SalonEase. Our experts are always ready to provide quality beauty services at your home whenever you need it.',
    },
    {
      id: 3,
      question: 'Facials Service at Home',
      answer:
        'At times, after a hectic day, nothing feels as relaxing as a face service back home. And the dream comes true with Salon Ease as these professional therapeutic facials are up-to-date, affordable, and done in the comfort of your home, bringing an end to standing in line in parlors or traversing to far-off places. Salon Ease comes with a wide range of facials ranging from a classic to luxury options, so you can freshen up the outer skin with their high-class products. Now, you can even get a spa facial at home through Salon Ease and let go of all your worries.',
    },
    {
        id: 4,
        question: 'Manicure and Pedicure Service at Home',
        answer:
          'Your hands and feet work hard for you so pamper them a bit too! We at Salon Ease are here to give that much-needed rejuvenating manicure and pedicure service in the comfort of your home. All that would take a few minutes for yourself, leaving you feeling fresh for a day packed with activities. Just tap on the Salon Ease website , book your service and we will arrive, bringing everything in tow, to give you that relaxing, hassle-free experience right at home.',
      },
    {
      id: 5,
      question: 'De-Tan & Bleaching Services at Home',
      answer:
        'Loving summer but not the tan? De-tan and bleach services from Salon Ease help your skin take a break. Get an even skin tone while sitting at home, just relax, watch your favorite shows, and let our professionals do the rest. Book detan at an affordable price with Salon Ease and pamper your skin.',
    },
    {
      id: 6,
      question: 'Waxing Services at Home',
      answer:
        'Got a last-minute party, but need smooth legs? No worries! Salon Ease provides professional waxing services right at home with that perfect finish. A wide range of options, from normal wax to chocolate wax, honey wax and Rica, for everyone preference. Our experts prep skin before waxing with pre-wax oils and after wax with gels for it to feel smooth and silky. No more salon lines or waits for Salon Ease!.',
    },
    {
      id: 7,
      question: 'Makeup Services at home',
      answer:
        'Salon Ease brings you professional makeup services right at the comfort of your home. Our well trained makeup artists are equipped with complete understanding of your style to get you all dressed up, without having to see the hassle of traveling around the city. Choose one of our packages to get the makeover you want. You look your best, effortlessly!',
    },
    {
      id: 8,
      question: 'Prebridal services at Home',
      answer: 'Are you preparing for the big day? Salon Ease has a wide range of services, from waxing to facials, massages, and much more-all of which can be done within the comfort of your home. Our packages are affordable and designed to suit your skin type for that extra pampering and so much time to relax as you prepare for the big day. You avoid the stress and traffic that may come with going to a salon on a busy wedding prep day and still have an amazing pampering pre-bridal experience.',
    },
    {
      id: 9,
      question: 'Female Spa at Home',
      answer:
        'Did you know that a spa treatment at home can give you a new life in your body, mind, and soul? Salon Ease makes it so easy! We bring to the comfort of your home the luxury of the spa experience, complete with very skilled therapists and the necessary equipment. Spoil yourself in a relaxing spa experience at home, and temporarily runaway from the bondage of your busy daily grind with Salon Ease.',
    },
    {
      id: 10,
      question: 'Relieving Stress through Massages',
      answer:
        'Under work stress? Relax with a stress-relieving massage service at home from Salon Ease. Our professionals will provide you with the most varied massage options tailored to your needs and help you create a spa-like environment, right in the comfort of your home. It is the best way to relieve stress, refresh your body and mind, and have fun while doing it.',
    },
    
  ];

  const [showStates, setShowStates] = useState(Array(data.length).fill(false));

  const toggleAnswer = index => {
    setShowStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <>
      <div className="newquestion_card">
        <h4>
          <span>Frequently</span> Asked Questions
        </h4>

        <div className="question_card_inner">
          <div className="column">
            {data.map((item, index) => {
              if (index % 2 === 0) {
                return (
                  <div
                    className="question_card_innerBox"
                    key={item.id}
                    onClick={() => toggleAnswer(index)}
                  >
                    <div className="question_card_flex">
                      <h3>{item.question}</h3>
                      {showStates[index] ? (
                        <IoIosArrowUp
                          style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#981313',
                          }}
                        />
                      ) : (
                        <IoIosArrowDown
                          style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#981313',
                          }}
                        />
                      )}
                    </div>
                    {showStates[index] && (
                      <p style={{ paddingTop: '13px' }}>{item.answer}</p>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="column">
            {data.map((item, index) => {
              if (index % 2 !== 0) {
                return (
                  <div
                    className="question_card_innerBox"
                    key={item.id}
                    onClick={() => toggleAnswer(index)}
                  >
                    <div className="question_card_flex">
                      <h3>{item.question}</h3>
                      {showStates[index] ? (
                        <IoIosArrowUp
                          style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#981313',
                          }}
                        />
                      ) : (
                        <IoIosArrowDown
                          style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#981313',
                          }}
                        />
                      )}
                    </div>
                    {showStates[index] && (
                      <p style={{ paddingTop: '13px' }}>{item.answer}</p>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewQuestion;
