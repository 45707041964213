import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Next from '../../assets/images/next.png'
// import Bookoing from '../../assets/images/Maskgroup1.png'
// import Address from '../../assets/images/Maskgroup4.png'
// import Allorder from '../../assets/images/Maskgroup2.png'
// import Help from '../../assets/images/Maskgroup7.png'
// import Track from '../../assets/images/Maskgroup6.png'
// import Wallet from '../../assets/images/Maskgroup5.png'
import usericon from '../../assets/images/icon1.svg'
import Bookoing from '../../assets/images/icon2.svg'
import Address from '../../assets/images/icon3.svg'
import Allorder from '../../assets/images/icon4.svg'
import Help from '../../assets/images/icon5.svg'
import Track from '../../assets/images/icon6.svg'
import Wallet from '../../assets/images/icon7.svg'
import axios from 'axios';
import { serverAPI } from '../..'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";

export default function ProfileMenu() {
    const navigate = useNavigate();
    const [loadLogout, setLoadLogOut] = useState(true);
    const location = useLocation(); // Get the current location
    // Define a function to check if a route is active
    const isRouteActive = (route) => location.pathname === route;
    const logOutRootUser = async () => {
        setLoadLogOut(false)
        try {
            const res = await axios.get(`${serverAPI}/auth/logout`, {
                withCredentials: true,
            });

            if (res.status === 200) {
                toast.success("Logout Succesfully 😃!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setTimeout(() => {
                    setLoadLogOut(true)
                    navigate('/')
                }, 2500);
            }
        } catch (err) {
        }
    };
    return (
        <>
            <div className='profile1-menu'>
                <div className='menu-div'>
                    <ul>
                        <Link to='/profile'><li className={isRouteActive('/profile') ? 'active' : ''}>
                            <img src={usericon} className='usericon' height='20px' width='20px' alt='' />
                            Profile
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />

                        </li></Link>
                        <Link to='/currentbooking'><li className={isRouteActive('/currentbooking') ? 'active' : ''}>
                            <img src={Bookoing} className='usericon' height='20px' width='20px' alt='' />
                            Current bookings
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />
                        </li></Link>
                        <Link to='/manageaddress'><li className={isRouteActive('/manageaddress') ? 'active' : ''}>
                            <img src={Address} className='usericon' height='20px' width='20px' alt='' />
                            Manage address
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />
                        </li></Link>
                        <Link to='/allorders'><li className={isRouteActive('/allorders') ? 'active' : ''}>
                            <img src={Allorder} className='usericon' height='20px' width='20px' alt='' />
                            All orders
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />
                        </li></Link>
                        <Link to='/gethelp'><li className={isRouteActive('/gethelp') ? 'active' : ''}>
                            <img src={Help} className='usericon' height='20px' width='20px' alt='' />
                            Get help
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />
                        </li></Link>
                        {/* <Link to='/currentbooking'><li className={isRouteActive('/currentbooking') ? 'active' : ''}>
                            <img src={Track} className='usericon' height='20px' width='20px' alt='' />
                            Track your booking
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />
                        </li></Link> */}
                        <Link to='/se_wallet'> <li className={isRouteActive('/se_wallet') ? 'active' : ''}>
                            <img src={Wallet} className='usericon' height='20px' width='20px' alt='' />
                            SalonEase Wallet
                            <img src={Next} className='nexticon' height='14px' width='14px' alt='' />
                        </li></Link>
                    </ul>

                    <div className='logout-btn'>
                        <button onClick={logOutRootUser}> {loadLogout ? <>Logout </> : <BeatLoader color="#ffffff" size={10} />}</button>
                        {/* <LoginModal isOpen={isPopupOpen} onClose={closePopup} /> */}
                    </div>

                </div>

            </div>

        </>
    )
}