import React, { useState } from 'react';
import axios from 'axios';
import { serverAPI } from '..';

const Phonepe = () => {
  const [loading2, setLoading2] = useState(false);

  const data = {
    name: 'Ashish Kumar',
    amount: 1,
    number: '6204477640',
    MUID: 'SEID' + Date.now(),
    transactionId: 'T' + Date.now(),
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setLoading2(true);
    axios.post(`${serverAPI}/online/payment`, { ...data }).then(res => {
        const redirectUrl = res.data.data.instrumentResponse.redirectInfo.url;


        setTimeout(() => {
            setLoading2(false);
            // Create a hidden anchor element
            var a = document.createElement('a');
            a.href = redirectUrl;
            a.target = '_blank';

            // Append the anchor element to the document and simulate a click
            document.body.appendChild(a);
            a.click();

            // Remove the anchor element from the document
            document.body.removeChild(a);
        }, 5000);
    })
        .catch(error => {
            setLoading2(false)
            console.error(error);
        });
}
  return (
    <>
      <div style={styles.main}>
        <div style={styles.center}>
          <img width={300} src='https://res.cloudinary.com/diyncva2v/image/upload/v1701726628/phonepe_qbyfsc.png' alt="" />
          <h2 style={styles.heading}>
            <span style={styles.textDanger}>LIVE</span> Payment Integration
          </h2>
        </div>
        <div style={styles.card}>
          <form onSubmit={handlePayment}>
            <div style={styles.column}>
              <p style={styles.label}>
                <strong>Name:</strong> {data.name}
              </p>
            </div>
            <div style={styles.column}>
              <p style={styles.label}>
                <strong>Number:</strong> {data.number}
              </p>
            </div>
            <div style={styles.column}>
              <p style={styles.label}>
                <strong>Amount:</strong> Rs. {data.amount}
              </p>
            </div>
            {!loading2 ? (
              <div style={styles.center}>
                <button style={styles.button} type="submit">
                  Pay Now
                </button>
              </div>
            ) : (
              <div style={styles.center}>
                <button style={styles.button} type="submit">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Wait...</span>
                  </div>
                </button>
              </div>
            )}
          </form>
        </div>
      </div>

    </>
  );
};

const styles = {
  main: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: '20px',
    fontSize: '20px',
    color: 'white',
    backgroundColor: '#0D94FB',
    border: 'none',
    padding: '8px 5px',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    textAlign: 'start',
  },
  column: {
    margin: '10px 0',
  },
  card: {
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    marginTop: '20px',
  },
  heading: {
    fontSize: '24px',
    marginTop: '10px',
  },
  textDanger: {
    color: 'red',
    fontWeight: 'bold',
  },
  modal: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '360px',
      padding: '0',
      maxHeight: '100vh',
      overflow: 'hidden',
    },
  },
  modalContent: {
    width: '100%',
    height: '100%',
  },
};

export default Phonepe;
