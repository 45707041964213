import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './allorders.css';
import orderimg from '../../assets/images/v682_250.png';
import Clock from '../../assets/images/clock.png';
import LoginModal from '../login/loginModal';
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..';
import ProfileHeder from './profileHeder';
import ProfileMenu from './ProfileMenu';
import CartAllComp from './cartAllComp';
import iconeorro from '../../assets/images/no_order.png';

export default function Allorders() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(true);

    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });

            if (res.status === 200) {
                const userData = res.data.user;
                setUserData(userData);
                setPageLoaded(false);

                const rres = await axios.get(`${serverAPI}/completed/user/${userData._id}`, {
                    withCredentials: true,
                });

                if (rres.status === 200) {
                    const order = rres.data;
                    setOrderData(order);
                    setDataLoaded(false);
                }
            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };

    // Call getRootUser only once on component mount
    useEffect(() => {
        getRootUser();
    }, []); // Empty dependency array ensures it runs once

    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };

    const closePopups = () => {
        navigate('/');
        setIsPopupOpens(false);
    };

    return (
        <>{
            pageLoaded ?
                <>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                        <HashLoader
                            color="#981313"
                            size={50}
                        />
                    </div>
                    <LoginModal isOpen={isPopupOpens} onClose={closePopups} />
                </>
                :
                <>
                    {
                        dataLoaded ?
                            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                                <HashLoader
                                    color="#981313"
                                    size={50}
                                />
                            </div>
                            :
                            <div className='profile-parent'>
                                <div className='container'>
                                    <div className='allorder-wrap'>
                                        <ProfileHeder name={userData?.name} phone={userData?.phone} address={getFormattedAddress()} />

                                        <div className='Profile-divider'>
                                            <ProfileMenu />
                                            <div className='order-content'>
                                                {orderData.length > 0 ?
                                                    <>
                                                        <h1>Recent orders</h1>
                                                        <div>
                                                            {orderData.map((appointment, index) => (
                                                                <CartAllComp appointment={appointment} index={index} key={index} />
                                                            ))}
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='bookingnon' >
                                                        <div className='booking_img' >
                                                            <img src={iconeorro} alt='' />
                                                        </div>
                                                        <h3>You haven’t booked any service yet</h3>
                                                        <p>Booking section is empty. After placing order, You can track them from here</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
        }
        </>
    );
}
