import React from 'react'
import Homeheader from '../layouts/home_header'
import Footer from '../layouts/footer'
import '../components/home/whychooseus.css'

export default function AboutUS() {
    return (
        <div>
            <Homeheader />
            <>

                <div className='why_choose_us_card'>
                    <div className="feat bg-gray pt-5 pb-5 card_pad">
                        <div className="container">
                            <div className="row about-body">
                                <div className="section-head col-sm-12">
                                    <h4><span>About</span> us?</h4>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>Welcome to a New Era of Personalized Salon Services</h5>
                                    <p>Salon Ease is more than a service; it's an experience designed to cater to your unique beauty needs. With a decade of excellence in the beauty industry, we've refined our craft to bring you a premium salon experience right to your doorstep.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>Our Commitment to Excellence</h5>
                                    <p>At Salon Ease, we've assembled a team of seasoned professionals who are not only licensed and skilled but also deeply passionate about making you look and feel your best. We believe in not just enhancing your beauty, but also in crafting an atmosphere of relaxation and rejuvenation.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>Why Salon Ease?</h5>
                                    <ul>
                                        <li>
                                            <p><span >Unmatched Convenience:</span> Skip the commute and long waits. Enjoy premium services in the comfort of your own space, curated just for you.
                                            </p>
                                        </li>
                                        <li>
                                            <p><span >Personalized Care:</span> We know one size doesn't fit all. Our experts take the time to understand your preferences and needs, ensuring a bespoke experience every time.
                                            </p>
                                        </li>
                                        <li>
                                            <p><span >Safety and Hygiene Priority:</span> With Salon Ease, you get your time back. Our team is punctual and focused on providing top-notch service within a reasonable timeframe.
                                            </p>
                                        </li>
                                        <li>
                                            <p><span>Efficient Time Management:</span> Skip the commute and long waits. Enjoy premium services in the comfort of your own space, curated just for you.
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col boxss col-sm-12">
                                    <h5>Our Vision</h5>
                                    <p>At Salon Ease, we're on a mission to redefine beauty care as an essential part of self-love and well-being, accessible to everyone. We believe that self-care shouldn't be a luxury; it should be a seamless part of your lifestyle.</p> <br/>
                                    <p>Join us on this journey towards a reimagined beauty experience.</p><br/>
                                    <p>Book your first Salon Ease session now and step into a world of indulgence, convenience, and beauty crafted just for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </>
            <Footer />
        </div>
    )
}
