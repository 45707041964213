import React, { useState, useEffect, useRef } from 'react';
import './services.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { serverAPI } from '../..';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './serviceDetailmodal.css';
import './addmypackmodal.css';
import BeatLoader from 'react-spinners/BeatLoader';
import ServiceCard from './servicecard';
import ServiceCardMobile from './serviecardMobile';
import { useSelector } from 'react-redux';
import { selectTotalCost, selectTotalTime } from '../../utils/cartSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Servicelist() {
  const navigate = useNavigate();

  const location = useLocation();
  // console.log(location, 'location');

  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [services, setService] = useState(null);
  const [servicesCat, setServiceCat] = useState(null);
  const [subID, setSubId] = useState(null);
  const [nameCat, setNameCat] = useState();
  const [categoryId, setCategoryId] = useState(null);
  const query = new URLSearchParams(location.search);
  const name = location?.state?.name;
  const service = query.get('service');
  const decodedName = decodeURIComponent(name);
  const decodedService = decodeURIComponent(service);
  const catid = location?.state?._id;
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [cat, setCat] = useState('');
  const totalCost = useSelector(selectTotalCost);
  const totalTime = useSelector(selectTotalTime);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  const [islodings, setIsLodings] = useState(true);
  const clicktonext = () => {
    setIsLodings(false);
    if (parseInt(totalCost) >= 899) {
      setTimeout(() => {
        navigate('/cart');
        setIsLodings(true);
      }, 1500);
    } else {
      toast.error(
        'Please add more items to checkout (minimum checkout amount is 899)',
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        }
      );
      setTimeout(() => {
        setIsLodings(true);
      }, 1500);
    }
  };

  const handleCategoryClick = async (categoryID, catName) => {
    setIsLoading(true);
    if (categoryID) {
      await axios
        .get(`${serverAPI}/subcategories/all/${categoryID}`)
        .then(response => {
          setIsLoading(false);
          setNameCat(response.data.services[0].subcategory);
          setService(response.data.services);
        })
        .catch(error => {
          console.error('Error fetching subcategories:', error);
        });
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const getSeoKeyword = pathParts[pathParts.length - 1]; // Get the last part of the path

    axios
      .get(`${serverAPI}/subcategories/seokeyword?seokeyword=${getSeoKeyword}`)
      .then(response => {
        setSubcategories(response.data);
        setItems(response.data);
      })
      .catch(error => {
        setServiceCat(null);
        console.error('Error fetching subcategories:', error);
      });
  }, [location]);

  // Inside your component
  const [activeSubcategory, setActiveSubcategory] = useState(''); // Initialize activeSubcategory state

  // Set the active subcategory when the component mounts
  useEffect(() => {   
    setActiveSubcategory(location?.state?.name);
    setCat(location?.state?.name);
    handleCategoryClick(location?.state?._id, location?.state?.name);
  }, []); // Trigger when subcategories change

  // Function to handle subcategory click
  const handleSubcategoryClick = name => {
    setActiveSubcategory(name);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isMobile = window.innerWidth < 768; // Adjust the threshold as needed for your definition of mobile size
      if (isMobile) {
        // scrollHandler(decodedService);
      }
    }, 1000); // Check every second (1000 milliseconds)

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [decodedService]); // Add any relevant dependencies

  return (
    <>
      {isLoading ? (
        <div className="card_services_up">
          <div class="cat_silders">
            <div class="cat_views">
              {Array.from({ length: 10 }).map((_, index) => (
                <div className="cat_itmes" key={index}>
                  <span style={{ marginTop: '0px' }}>
                    <SkeletonTheme
                      baseColor="#e6e6e654"
                      highlightColor="#E6E6E6"
                    >
                      <Skeleton circle={true} height={60} width={60} />
                      <Skeleton width={60} />
                    </SkeletonTheme>{' '}
                  </span>
                </div>
              ))}
            </div>
            <div className="subcat_silder">
              <div className="subcat_views">
                {Array.from({ length: 10 }).map((_, index) => (
                  <div className="subcat_button" key={index}>
                    <span>
                      {' '}
                      <SkeletonTheme
                        baseColor="#e6e6e654"
                        highlightColor="#E6E6E6"
                      >
                        <Skeleton width={100} />
                      </SkeletonTheme>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="card_services">
            <div className="card_services_serv">
              <div className="card_services_heading">
                <div className="section-heada">
                  <SkeletonTheme
                    baseColor="#e6e6e654"
                    highlightColor="#E6E6E6"
                    width={100}
                  >
                    <p>
                      <Skeleton width={300} height={50} />{' '}
                    </p>
                  </SkeletonTheme>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div
                    className="sidebar"
                    style={{
                      backgroundColor: '#ffffff00',
                      scrollbarColor: '#ffffff00',
                    }}
                  >
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                      style={{ overflowY: 'hidden' }}
                    >
                      {Array.from({ length: 10 }).map((_, index) => (
                        <span key={index}>
                          {' '}
                          <SkeletonTheme
                            baseColor="#e6e6e654"
                            highlightColor="#E6E6E6"
                          >
                            <Skeleton height={48} />
                          </SkeletonTheme>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-8 servicebar_scrol">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <span key={index}>
                      {' '}
                      <SkeletonTheme
                        baseColor="#e6e6e654"
                        highlightColor="#E6E6E6"
                      >
                        <Skeleton height={30} width={200} />
                        <Skeleton height={350} />
                      </SkeletonTheme>
                    </span>
                  ))}
                </div>
                <div
                  className="moblie_service"
                  style={{ paddingTop: '0px', top: '208px', position: 'fixed' }}
                >
                  {Array.from({ length: 10 }).map((_, index) => (
                    <span key={index}>
                      {' '}
                      <SkeletonTheme
                        baseColor="#e6e6e654"
                        highlightColor="#E6E6E6"
                      >
                        <Skeleton height={30} width={200} />
                        <Skeleton height={350} />
                      </SkeletonTheme>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card_services_up">
          <div class="cat_silders">
            <div class="cat_views" style={{ cursor: 'pointer' }}>
              {subcategories.map(categorie => {
                return (
                  <div
                    className={`cat_itmes ${
                      activeSubcategory === categorie.name ? 'cat_itmesAct' : ''
                    }`}
                    key={categorie._id}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleCategoryClick(categorie._id, categorie.category);
                        handleSubcategoryClick(categorie.name);
                      }}
                      src={categorie.image}
                      alt={`${categorie.name} services at home`}
                    />
                    <span style={{ cursor: 'pointer' }}>{categorie.name}</span>
                  </div>
                );
              })}
            </div>

            <div className="subcat_silder">
              <div className="subcat_views">
                {subcategories?.map((subcategorie, index) => (
                  <div
                    className={`subcat_button ${
                      subcategorie.name === activeSubcategory ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    key={subcategorie._id}
                    onClick={() => handleSubcategoryClick(subcategorie.name)}
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      className="textactive"
                    >
                      {subcategorie.name}{' '}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="card_services ">
            <div className="card_services_serv ">
              <div className="card_services_heading">
                <div className="section-heada">
                  <h4>{subcategories[0]?.category}</h4>
                </div>
              </div>
              <div className="row ">
                <div className="col-4">
                  <div className="sidebar">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <>                       
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`flush-heading1`}
                          >
                            <button
                              className={`button-accordion accordion-button collapsed `}                           
                              type="button"                              
                              aria-expanded={true}
                              aria-controls={`flush-collapse1`}
                            >
                              {subcategories[0]?.category}
                            </button>
                          </h2>
                          <div
                            id={`flush-collapse1`}
                            className={`accordion-collapse collapse show`}                          
                            aria-labelledby={`flush-heading1`}
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <div className="list-group">
                                {subcategories?.map(
                                  (subcategory, menuIndex) => {
                                    return (
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className={`menu-list-itme
                                           ${
                                             subcategory.name === cat
                                               ? 'mennlistactive'
                                               : ''
                                           }
                                        `}
                                        // onClick={() => {
                                        //   scrollHandlers(subcategory.name); // Scroll to the corresponding section
                                        // }}
                                        onClick={() => {
                                          setService(subcategory.services);
                                          setCat(subcategory.name);
                                        }}
                                        key={menuIndex}
                                      >
                                        <p style={{ cursor: 'pointer' }}>
                                          {' '}
                                          {subcategory.name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* //   );
                        // })} */}
                      </>
                    </div>
                  </div>
                </div>

                {/* Your map service */}
                <div className="col-8 servicebar_scrol">
                  {/* {services
                    ? Object.entries(services).map(
                        ([subcategoryName, services]) => (
                          <div
                            key={subcategoryName}
                            ref={ref => (sectionRefss[subcategoryName] = ref)}
                            data-section-name={subcategoryName}
                          >
                          */}
                  <h2> {cat}</h2>
                  {services?.map((service, index) => (
                    <ServiceCard service={service} key={index} />
                  ))}
                  {/* </div>
                        )
                      )
                    : null} */}
                </div>
                {/* mobile view  */}

                <div
                  className="moblie_service"
                  style={
                    Math.round(totalCost) === 0
                      ? { top: '131px' } //padding: '0px 0px 55px 0px', top: '180px'
                      : {
                          padding: '0px',
                          paddingBottom: '250px',
                          position: 'fixed ',
                          top: '131px',
                        }
                  }
                >
                  <h2
                    style={{ paddingTop: '10px' }}
                    // ref={ref =>
                    //   (nameCat = ref)
                    // }
                  >
                    {nameCat}
                  </h2>
                  {services?.map((service, index) => (
                    <ServiceCardMobile service={service} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {Math.round(totalCost) === 0 ? null : (
            <div className="cart_cost">
              <div className="cart_left">
                <span className="total_Cost">
                  Total Cost - ₹{parseInt(totalCost)}/-
                </span>
                <span className="total_time">Total Time - {totalTime}</span>
              </div>
              <div
                className="cart_right"
                style={{ cursor: 'pointer' }}
                onClick={clicktonext}
              >
                <span className="service_cartbtn">
                  {' '}
                  {islodings ? (
                    <>VIEW CART</>
                  ) : (
                    <BeatLoader color="#ffffff" size={10} />
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
