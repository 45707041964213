import React from 'react'
import { Link } from "react-router-dom";
import back from '../assets/images/arrow-left.png'
import './login_head.css'

export default function LoginHead() {
  return (
    <>
    
    <div className="mobile_active">
        <div className="mobile_nav">
          <div className="back-nav">
            <table>
                <tr>
                    <Link to='/'><th> <img src={back}  width="20px" /></th></Link>
                    <td>Login / Signup</td>
                </tr>
            </table>
          </div>
        
        </div>
      </div>
    
    </>
  )
}
