// orderSlice.js
import { createSlice } from "@reduxjs/toolkit";


const orderSlice = createSlice({
    name: "order",
    initialState: {
        address: {},
        cart: [],
        user: {},
        slot: {},
        subService: {},
        subNo: "",
        paymentDetails: {},
        razorpayDitail: {},
        appointmentStatus : [],
        userId: "",
        paymentMethod: "", // Initialize paymentMethod as an empty string
    },
    reducers: {
        setOrderAddress: (state, action) => {
            const { address } = action.payload;
            state.address = address;
            localStorage.setItem("address", JSON.stringify(state.address));
        },
        setOrderCart: (state, action) => {
            const { cart } = action.payload;
            state.cart = cart;
        },
        setOrderUser: (state, action) => {
            const { user } = action.payload;
            state.user = user;
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        setOrderPayment: (state, action) => {
            const { paymentMethod } = action.payload;
            state.paymentMethod = paymentMethod; // Set the payment metho
            localStorage.setItem("paymentMethod", JSON.stringify(state.paymentMethod));
        },
        setOrderUserId: (state, action) => {
            const { userId } = action.payload;
            state.userId = userId; // Set the payment metho
            localStorage.setItem("userId", JSON.stringify(state.userId));
        },
        setOrderSlot: (state, action) =>{
            const { slot } = action.payload;
            state.slot = slot;
            localStorage.setItem("slot", JSON.stringify(state.slot)) 
        },
        setOrderPaymentDetails: (state, action) =>{
            const { paymentDetails } = action.payload;
            state.paymentDetails = paymentDetails;
            localStorage.setItem("paymentDetails", JSON.stringify(state.paymentDetails)) 
        },
        setOrderRazorpayDitail: (state, action) =>{
            const { razorpayDitail } = action.payload;
            state.razorpayDitail = razorpayDitail;
            localStorage.setItem("razorpayDitail", JSON.stringify(state.razorpayDitail)) 
        },
        setAppointmentStatus: (state, action) =>{
            const { appointmentStatus } = action.payload;
            state.appointmentStatus = appointmentStatus;
            localStorage.setItem("appointmentStatus", JSON.stringify(state.appointmentStatus)) 
        },
        setsubService: (state, action) =>{
            const { ...subService } = action.payload;
            state.subService = subService.test;
            localStorage.setItem("subService", JSON.stringify(state.subService)) 
        },
        setsubNo: (state, action) =>{
            const subNo  = action.payload;
            state.subNo = subNo;
            localStorage.setItem("subNo", JSON.stringify(state.subNo)) 
        },
        emptyOrder: (state) => {
            state.address = {};
            state.cart = {};
            state.user = {};
            state.paymentMethod = ""; // Reset the payment method to an empty string
            localStorage.setItem("address", JSON.stringify(state.address));
            localStorage.setItem("paymentMethod", JSON.stringify(state.paymentMethod));
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload; // Set the payment method using the action payload
        },
       
    },
}

);

export const { 
    setOrderAddress, 
    setOrderCart, 
    setOrderSlot,
    setOrderPayment, 
    setOrderUser, 
    emptyOrder, 
    setPaymentMethod, 
    setOrderPaymentDetails , 
    setOrderUserId, 
    setOrderRazorpayDitail,
    setsubService,
    setsubNo,
    setAppointmentStatus} = orderSlice.actions;

export default orderSlice.reducer;
