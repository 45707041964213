import React from 'react'
import Cartaddress from '../components/cart/cartaddress'
import '../components/cart/cartaddress.css'
import Header from '../layouts/header'
import Homeheader from '../layouts/home_header'



 const Address = () => {
  
  return (
    <>
        <Homeheader />
        <Cartaddress />
    </>
  )
}
export default Address;