// WhatsAppButton.js
import React from 'react';
import './WhatsAppButton.css'; // Import the custom CSS for styling
import { useLocation } from 'react-router-dom';

const WhatsAppButton = () => {
  const location = useLocation();

  // Show the button only on the homepage ("/")
  // if (location.pathname !== '/') {
  //   return null;
  // }

  return (
    <a
      href="https://wa.me/9117770106" // Replace with your WhatsApp number in international format
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp icon URL
        alt="WhatsApp"
      />
    </a>
  );
};

export default WhatsAppButton;
