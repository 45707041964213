import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './trackorder.css';
import LoginModal from '../login/loginModal';
import HashLoader from 'react-spinners/HashLoader';
import axios from 'axios';
import { serverAPI } from '../..';
import ProfileHeder from './profileHeder';
import ProfileMenu from './ProfileMenu';
import TrackAllComp from './trackAllComp';
import OrderStauts from './OrderStauts';
import iconeorro from '../../assets/images/no_order.png';
import { useLocation } from 'react-router-dom';

export default function Trackorder() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpens, setIsPopupOpens] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [orderData, setOrderData] = useState([]);

  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
        setPageLoaded(false);

        if (id) {
          const rres = await axios.get(`${serverAPI}/appointments/${id}`, {
            withCredentials: true,
          });

          if (rres.status === 200) {
            const order = rres.data;
            setOrderData(order);
            setDataLoaded(false);
          }
        } else {
          setDataLoaded(false);
        }
      }
    } catch (err) {
      setIsPopupOpens(true);
    }
  };

  useEffect(() => {
    // Call the API immediately when the component mounts
    getRootUser();

    // Set up an interval to call the API every 4 seconds
    const intervalId = setInterval(() => {
      getRootUser();
    }, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the interval is set once

  const getFormattedAddress = () => {
    if (userData?.address.home && userData?.address.home.length > 0) {
      return userData?.address.home[0].address;
    } else if (userData?.address.other && userData?.address.other.length > 0) {
      return userData?.address.other[0].address;
    } else {
      return 'No address available';
    }
  };

  const closePopups = () => {
    navigate('/');
    setIsPopupOpens(false);
  };

  return (
    <>
      {pageLoaded ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '88vh',
            }}
          >
            <HashLoader color="#981313" size={50} />
          </div>
          <LoginModal isOpen={isPopupOpens} onClose={closePopups} />
        </>
      ) : (
        <>
          {dataLoaded ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '88vh',
              }}
            >
              <HashLoader color="#981313" size={50} />
            </div>
          ) : (
            <div className="profile-parent">
              <div className="container">
                <div className="trackorder-wrap">
                  <ProfileHeder
                    name={userData?.name}
                    phone={userData?.phone}
                    address={getFormattedAddress()}
                  />

                  <div className="Profile-divider">
                    <ProfileMenu />

                    <div className="track-content">
                      {id ? (
                        <>
                          <TrackAllComp appointment={orderData} index={0} />
                          <OrderStauts appointment={orderData} />
                        </>
                      ) : (
                        <div className="bookingnon">
                          <div className="booking_img">
                            <img src={iconeorro} alt="" />
                          </div>
                          <h3>You haven’t booked any service yet</h3>
                          <p>
                            Booking section is empty. After placing order, You
                            can track them from here
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
