import React from 'react'
import Allorders from '../components/profilepage/allorders'
import HomeHeader from '../layouts/home_header'
import Footer from '../layouts/footer'

export default function AllOders() {
    return (
        <>
            <HomeHeader />
            <Allorders />
            <Footer />

        </>
    )
}
