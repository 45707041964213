import React from 'react'

import HomeHeader from '../layouts/home_header'
import Footer from '../layouts/footer'
import Manageaddress from '../components/profilepage/manageaddress'

export default function CurrentBooking() {
    return (
        <>
            <HomeHeader />
            <Manageaddress />
            <Footer />
        </>
    )
}
