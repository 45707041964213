import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './gethelp.css'
import Next from '../../assets/images/next.png'
import question from '../../assets/images/question.png'
import ProfileHeder from './profileHeder'
import ProfileMenu from './ProfileMenu'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import LoginModal from '../login/loginModal'
export default function Gethelp() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });
            if (res.status === 200) {
                const userData = res.data.user;
                setUserData(userData);
                setPageLoaded(false);
            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };
    useEffect(() => {
        getRootUser();
    }, []);
    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };

    const closePopups = () => {
        navigate('/')
        setIsPopupOpens(false);
    };
    return (
        <>
            {
                pageLoaded ?
                    <>
                        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                            <HashLoader
                                color="#981313"
                                size={50}
                            />

                        </div>
                        <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

                    </>
                    :
                    <div className='profile-parent'>
                        <div className='container'>

                            <div className='trackorder-wrap'>
                                <ProfileHeder name={userData?.name} phone={userData?.phone} address={getFormattedAddress()} />


                                <div className='Profile-divider'>
                                    <ProfileMenu />

                                    <div className='gethelp-content'>

                                        <div className='gethelp-box'>
                                            <div className='help-text'>
                                                <h4>Help Center</h4>
                                                <p>Please get in touch and we will be
                                                    happy to help you
                                                </p>
                                            </div>
                                            <div className='help-img'>
                                                <img src={question} width='30px' />
                                            </div>
                                        </div>

                                        {/* <Link to='/trackorder'><div className='help-next'>
                                            <h3>Track My Booking</h3>
                                            <img src={Next} width="18px" />
                                        </div></Link> */}
                                        <Link to='/contactus'><div className='help-next'>
                                            <h3>Contact us</h3>
                                            <img src={Next} width="18px" />
                                        </div></Link>
                                        <Link to='/query'><div className='help-next'>
                                            <h3>Raise a query</h3>
                                            <img src={Next} width="18px" />
                                        </div></Link>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>
    )
}
