import React from 'react';
// import './style.css';

const NotFoundPage = () => {
    const base = {
        padding: 0,
        margin: 0,
        outline: 0,
        color: '#444',
        boxSizing: 'border-box',
        fontFamily: 'IBM Plex Sans, sans-serif',
    };

    const styles = {
        body: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            overflow: 'hidden',
        },
        h1: {
            fontSize: '50px',
            lineHeight: '60px',
        },
        span: {
            display: 'block',
            fontSize: '18px',
            lineHeight: '30px',
        },
        container: {
            width: '80%',
            maxWidth: '1600px',
            margin: 'auto',
        },
        gridRow: {
            display: 'grid',
            justifyContent: 'center',
            placeItems: 'center',
            gridGap: '50px',
        },
        column: {
            textAlign: 'center',
        },
        pxSpcB20: {
            paddingBottom: '20px',
            paddingTop: '10px',
            fontSize: '30px'
        },
        pxSpcB20s: {
            paddingBottom: '20px',
            
        },
        goHome: {
            padding: '10px 23px',
            background: '#971413',
            border: 'transparent',
            borderRadius: '7px',
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 2px',
            cursor: 'pointer',
            margin: '20px 0px',
            color: 'rgb(255, 255, 255)',
        },
        goHomeIcon: {
            color: '#fff',
        },
        image: {
            display: 'block',
            width: '100%',
        },
    };

    return (
        <div style={base}>
            <div style={styles.body}>
                <div className="wrapper">
                    <div style={styles.container}>
                        <div style={styles.gridRow}>
                            <div style={styles.column}>
                                <img
                                    src="https://res.cloudinary.com/diyncva2v/image/upload/v1698957288/image-left_ha4zpa.webp"
                                    alt="image-left"
                                    style={styles.image}
                                />
                                <h1 style={styles.pxSpcB20}>
                                    We can't find the page you are looking for.
                                </h1>
                                <div>
                                    <span style={styles.pxSpcB20s}>
                                        This page has been relocated or removed.
                                    </span>
                                </div>
                                <div>
                                    <a href="/">
                                        <button style={styles.goHome}>
                                            <i className="fa fa-home" style={styles.goHomeIcon}></i> Go Home
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default NotFoundPage;
