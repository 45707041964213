import React from 'react'
import Contactus from '../components/profilepage/contactus'
import Footer from '../layouts/footer'
import HomeHeader from '../layouts/home_header'

export default function Contact_Us() {
    return (
        <>

            <HomeHeader />
            <Contactus />
            <Footer />

        </>
    )
}
