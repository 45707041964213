import React, { Component, useState, useEffect, useRef , useMemo} from 'react';
import Slider from 'react-slick';
import './newhome.css';
import './Silders.css';

import SilderImage1 from '../../assets/BannerHomePageTop/bnr1.png';
import SilderImage2 from '../../assets/BannerHomePageTop/bnr2.png';
import SilderImage3 from '../../assets/BannerHomePageTop/bnr3.png';
import SilderImage4 from '../../assets/BannerHomePageTop/bnr4.png';
import SilderImage5 from '../../assets/BannerHomePageTop/bnr5.png';
import SilderImage6 from '../../assets/BannerHomePageTop/bnr6.png';
import SilderImage7 from '../../assets/BannerHomePageTop/bnr7.png';

import Location from '../../assets/images/loctn.png';
import Loc from '../../assets/images/location.png';
import Next from '../../assets/images/arrow-down.png';
import Precision from '../../assets/images/precision.png';
import Search from '../../assets/images/srch.png';
import Arrowup from '../../assets/images/arrow-up.png';
import LocationPopup from './locationpopup';
import axios from 'axios';
import { serverAPI } from '../..';
import { Link, useNavigate } from 'react-router-dom';

export default function SimpleSlider() {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const serverDropRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [addkey, setAddKey] = useState(null);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('addkey'));
    if (items) {
      setAddKey(items);
    }
  }, []);

  const [searchInput, setSearchInput] = useState('');
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const apiCalledRef = useRef(false);

  useEffect(() => {
    if (apiCalledRef.current) return; // If API has already been called, exit early
    apiCalledRef.current = true;
    if (window.innerWidth > 570) {
      axios
        .get(`${serverAPI}/services/service-names`)
        .then(response => {
          setServices(response.data);
        })
        .catch(error => {
          setServices([]);
          console.error('Error fetching services:', error);
        });
    }
  }, []);

  useEffect(() => {
    const filtered = services.filter(service =>
      service.service_name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredServices(filtered);
  }, [searchInput, services]);

  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }
  const handleServiceClick = service => {
    // Construct the URL with the required parameters
    // const url = `/services/?name=${encodeURIComponent(service.category)}&id=${service.categoryId}&service=${encodeURIComponent(service.subcategory)}`;
    // const url = `/services/${toKebabCase(service.category)}`,{state:{name:service.category, _id:service.categoryId}};
    const url = `/services/${toKebabCase(service.category)}`;
    const state = { name: service.subcategory, _id: service.subcategoryId };
    // Navigate to the specified URL
    navigate(url, { state: state });
  };

  const handleInputChange = e => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [searchInput]);
  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }
  const handleClick = (name, id) => {
    navigate(`/services/${toKebabCase(name)}`, {
      state: {
        name: name,
        _id: id,
      },
    });
  };
  const handleOutsideClick = e => {
    if (e.target.className !== 'search-input') {
      setSearchInput('');
      // Hide the server_drop div here
    }
  };

  // New code for animated search input placeholder
  const [placeholderText, setPlaceholderText] = useState("search for ''");

const placeholderTexts = useMemo(
  () => ['facial services', 'body polish services', 'body massage services', 'Rica waxing', 'Manicure', 'Pedicure', 'Makeup services'],
  []
);

const typingSpeed = 50; // Speed of typing in milliseconds
const erasingSpeed = 50; // Speed of erasing in milliseconds
const holdTime = 2000; // Time to hold the text after typing is complete

const currentIndex = useRef(0);
const charIndex = useRef(0);
const isTyping = useRef(true);

useEffect(() => {
  const typeEffect = () => {
    if (!placeholderTexts || currentIndex.current >= placeholderTexts.length) {
      return; // Exit if placeholderTexts is undefined or currentIndex is out of bounds
    }

    const currentText = placeholderTexts[currentIndex.current];

    if (isTyping.current) {
      if (charIndex.current < currentText.length) {
        // Typing phase
        setPlaceholderText(`search for '${currentText.substring(0, charIndex.current + 1)}'`);
        charIndex.current++;
        requestAnimationFrame(() => setTimeout(typeEffect, typingSpeed));
      } else {
        // Hold the completed text for 2 seconds
        setTimeout(() => {
          isTyping.current = false; // Switch to erasing phase
          typeEffect();
        }, holdTime);
      }
    } else {
      // Erasing phase
      if (charIndex.current > 0) {
        setPlaceholderText(`search for '${currentText.substring(0, charIndex.current - 1)}'`);
        charIndex.current--;
        requestAnimationFrame(() => setTimeout(typeEffect, erasingSpeed));
      } else {
        // Prepare for the next placeholder text
        isTyping.current = true;
        currentIndex.current = (currentIndex.current + 1) % placeholderTexts.length;
        charIndex.current = 0; // Reset charIndex for the new text
        typeEffect(); // Start typing the next text immediately
      }
    }
  };

  typeEffect(); // Call the typing effect to initiate the animation

}, [placeholderTexts]);
  return (
    <>
      <div className="banner">
        <Slider {...settings}>
          <div className="slid-img">
            {/* <Link
              to={{
                pathname: '/services/create-your-own-package',
                state: {
                  name: 'Create Your own Package',
                  _id: '651859f0909e90359c9a4864',
                }, // Replace with your actual state values
              }}
            >
              <img src={SilderImage2} alt="" />
            </Link> */}
            <img
              src={window.innerWidth > 570 ? SilderImage2 : ''}
              alt=""
              onClick={e => {
                e.preventDefault();
                handleClick(
                  'Create Your own Package', //categoryname
                  '651be764909e90359c9a5d4d' //subcategory id to be passed
                  // '651be5c2909e90359c9a5d11'  //subcategory id to be passed
                );
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slid-img">
            {/* <Link to="/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788&service=Salon%20Ease%20Exclusive%20Packages">
              <img src={SilderImage3} alt="" />
            </Link> */}
            <img
              // src={SilderImage3}
              src={window.innerWidth > 570 ? SilderImage3 : ''}
              alt=""
              onClick={e => {
                e.preventDefault();
                handleClick('Top Selling Packages', '651be945909e90359c9a5dcf');
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slid-img">
            {/* <Link to="/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788">
              <img src={SilderImage4} alt="" />
            </Link> */}
            <img
              // src={SilderImage4}
              src={window.innerWidth > 570 ? SilderImage4 : ''}
              alt=""
              onClick={e => {
                e.preventDefault();
                handleClick('Top Selling Packages', '651856e8909e90359c9a479b');
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slid-img">
            {/* <Link to="/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864&service=Any%205%20Service%20%401649">
              <img src={SilderImage5} alt="" />
            </Link> */}
            <img
              // src={SilderImage5}
              src={window.innerWidth > 570 ? SilderImage5 : ''}
              alt=""
              onClick={e => {
                e.preventDefault();
                handleClick(
                  'Create Your own Package',
                  '651be793909e90359c9a5d54'
                );
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slid-img">
            {/* <Link to="/service">
              <img src={SilderImage6} alt="" />
            </Link> */}
            <img
              // src={SilderImage6}
              src={window.innerWidth > 570 ? SilderImage6 : ''}
              alt=""
              onClick={e => {
                e.preventDefault();
                handleClick(
                  'Create Your own Package',
                  '651859f0909e90359c9a4864'
                );
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slid-img">
            {/* <Link to="/service?name=Waxing&id=651be151909e90359c9a5c8f&service=Rica%20Waxology">
              <img src={SilderImage7} alt="" />
            </Link> */}
            <img
              // src={SilderImage7}
              src={window.innerWidth > 570 ? SilderImage7 : ''}
              alt=""
              onClick={e => {
                e.preventDefault();
                handleClick('Waxing', '651be151909e90359c9a5c8f');
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
        </Slider>
        <div className="overlay">
          <div className="inerr">
            {/* <div className="location_dropdown"   >
                            <div className="loc_menu">
                                <img src={Loc} width='19px' height='19px' style={{ marginRight: '10px' }} alt="" />
                                {
                                    addkey ? <div className="loc_menul"><h3>{addkey}</h3> </div> : <h3>Location</h3>
                                }

                            </div>

                            <img className="arrow" src={Next} width='16px' height='16px' style={{ marginRight: '10px', marginLeft: '10px' }} alt="" />
                        </div> */}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="search-container">
                <input
                  type="text"
                  placeholder={placeholderText}
                  className="search-input"
                  value={searchInput}
                  onChange={handleInputChange}
                />

                <button className="search-button">
                  <i className="fas fa-search"></i>
                </button>
              </div>
              <div
                className="server_drop"
                style={{
                  display: searchInput === '' ? 'none' : 'block',
                  height: '350px',
                }}
              >
                {filteredServices.length === 0 ? (
                  <div className="service_btn">
                    {' '}
                    <div className="srvc_1">No results found </div>
                  </div>
                ) : (
                  <div className="service_btn" style={{ marginTop: '10px' }}>
                    {filteredServices.map((service, index) => (
                      <div
                        className="srvc_1"
                        key={index}
                        onClick={() => {
                          handleServiceClick(service);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <p
                          style={{
                            margin: '0',
                            padding: '0',
                            textAlign: 'left',
                          }}
                        >
                          {service.service_name}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <LocationPopup isOpen={isPopupOpen} onClose={closePopup} />
      </div>
    </>
  );
}
