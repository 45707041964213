import React, { useEffect, useState } from 'react'
import './modal.css'
import Back from '../../assets/images/arrow-left.png'
import Precision from '../../assets/images/gps.svg'
import axios from 'axios';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PropagateLoader from "react-spinners/PropagateLoader"
import BeatLoader from "react-spinners/BeatLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import back from '../../assets/images/arrow-left.png'
import Location from '../../assets/images/loctn.png'
import loctn from '../../assets/images/pin.png'
import { serverAPI } from '../..';

export default function Locationpage({ onClose }) {
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const [currentAddress, setCurrentAddress] = useState("");
    const [areaName, setAreaName] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [islodings, setIsLodings] = useState(true)
    const [newAdd, setNewAdd] = useState(false);
    const [isloding, setIsLoding] = useState(true)

    const handleSaveAdd = () => {
        setIsLodings(false)

        setTimeout(() => {
            setIsLodings(true);
            localStorage.setItem('addkey', JSON.stringify(currentAddress));
            navigate('/');
            window.location.reload();
        }, 1500);

    }
    const handleAddressChange = (address) => {
        setIsLodings(false)
        if (currentAddress) {
            setCurrentAddress("")
            setNewAdd(false);
        }
        const addressParts = address.split(', ');

        const addresslist = []
        for (let i = 0; i < addressParts.length; i++) {
            addresslist.push(addressParts[i])
        }
        setAreaName(addresslist[0])
        // If you want to join the parts back into a string with commas
        const joinedAddress = addresslist.join(', ');
        setCurrentAddress(joinedAddress);
        setNewAdd(true);

        setTimeout(() => {
            setIsLodings(true);
            localStorage.setItem('addkey', JSON.stringify(address))
            navigate('/');
            window.location.reload();
        }, 1500);
    };

    const handelCurrentLocation = () => {
        setShowModal(true);
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    axios.get(`${serverAPI}/geocode`, {
                        params: {
                            lat: latitude,
                            lng: longitude,
                        },
                    })
                        .then(response => {
                            const formattedAddress = response.data.results[0].formatted_address;
                            const addressParts = formattedAddress.split(', ');

                            const addressList = [];
                            for (let i = 1; i < addressParts.length; i++) {
                                addressList.push(addressParts[i]);
                            }

                            // Use the addressList as needed
                            const joinedAddress = addressList.join(', ');
                            setAreaName(addressList[0]); // Assuming addressList[0] should be set as area name
                            setCurrentAddress(joinedAddress);
                            setTimeout(() => {
                                setNewAdd(true);
                                setShowModal(false);
                            }, 1500);
                        })
                        .catch(error => {
                            setShowModal(false);
                        });
                },
                (error) => {
                    setShowModal(false);
                }
            );
        } else {
            setShowModal(false);
        }
    };

    const [inputValue, setInputValue] = useState('');
    const [results, setResults] = useState([]);

    const fetchGooglePlaces = async (input) => {
        try {
            const response = await axios.post(`${serverAPI}/getPlaces`, {
                input: input
            });
            setResults(response.data.predictions);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const handleInputChanges = (e) => {
        setInputValue(e.target.value);
        fetchGooglePlaces(e.target.value);
    };

    return (
        <>
            <div className="mobile_nav mobileact">
                <div className="back-nav" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <Link to="/">
                        <div style={{ display: "flex", alignItems: "center", height: "100%", width: '12%' }} >
                            <img src={back} width="20px" alt='' style={{ marginLeft: "16px" }} />
                        </div>
                    </Link>
                    <div>
                        <h1 style={{ fontSize: "16px", margin: "0px", paddingLeft: "15px" }}>Search for your Location</h1>                    </div>

                </div>
            </div>

            <div style={{ padding: "2.5rem 7px 0px", height: '95vh', backgroundColor: 'white' }}>


                {/* <form className='loc-input' style={{ marginTop: "20px" }}>
                    <div className='loc-inputs'>
                        <GooglePlacesAutocomplete
                            className="nosubmit"
                            apiKey={process.env.REACT_APP_API_KEY}
                            apiOptions={{ region: 'IN' }}
                            selectProps={{
                                styles: {
                                    control: (provided, state) => ({
                                        ...provided,
                                        borderColor: 'transparent', // Remove the border color
                                        '&:focus': {
                                            borderColor: '#981313', // Border color when focused
                                        },
                                        '&:hover': {
                                            borderColor: 'transparent', // Border color on hover
                                        },
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),

                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#93294B' : 'transparent', // Change background color on hover
                                        color: state.isFocused ? 'white' : 'black'
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                },
                                currentAddress, // Set the value to the currentAddress state
                                onChange: handleAddressChange, // Handle changes and update state
                                placeholder: 'Search for your location/society/appartment',

                            }} />
                    </div>

                </form> */}
                <form className='loc-input' >
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChanges}
                        placeholder="Search for your location/society/appartment"
                    />
                </form>


                <div className="lctns" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <h4> <img src={Location} width='16px' height='16px' style={{
                        marginRight: '5px'
                    }} alt='' />Enter Location</h4>
                    <div className="crntloc" onClick={handelCurrentLocation} style={{ cursor: "pointer", marginTop: '0' }}>
                        {showModal ?
                            <div style={{ padding: "5px 93px" }}>
                                <PropagateLoader color="#93294B" size={10} speedMultiplier={0.9} />
                            </div>
                            :
                            <h4> <img src={Precision} width="16px" style={{ marginRight: '5px' }} alt='' /> Use current location</h4>
                        }
                    </div>
                </div>
                <div className="divdr"></div>
                {newAdd ? <><div className='textarea' style={{ width: '90%',cursor: 'pointer' }} onClick={handleSaveAdd}>
                    <h5>{areaName}</h5>
                    <p style={{ textAlign: "left" }}>{currentAddress}</p>
                </div></> : null}

                <div className='address-option2' >
                    {results.map((result, index) => (
                        <div className='option2' key={index} onClick={() => { handleAddressChange(result.description) }}>
                            <img src={loctn} width='18px' height='17px' alt='' />
                            <div className='option-detl' >
                                <h3>{result.description.split(', ')[0]}</h3>
                                <p>{result.description}</p>
                            </div>
                        </div>

                    ))}
                </div>
                <div style={{
                    position: "fixed",
                    left: '0',
                    bottom: '0',
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",


                }}>
                    <button className="login-btn" style={{ width: "90% !important" }} onClick={handleSaveAdd}>{islodings ? <>CONTINUE </> : <BeatLoader color="#ffffff" size={10} />}</button>

                </div>

            </div>
        </>
    )
}
