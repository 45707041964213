import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './newhome.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import axios from 'axios';
import { serverAPI } from '../..';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Homeheader from '../../layouts/home_header'; // Import header
import Footer from '../../layouts/footer';
import SalonSubcategories from '../booking/subcat'; // Import header
// src\components\booking\subcat.js

const SubCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [review, setResview] = useState([]);
  const [categories, setCategories] = useState([]);
  const getSeoKeyword = () => {
    const pathParts = location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1]; // Get the last part of the path

    // Convert 'kebab-case' to 'Title Case'
    return lastPart
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const apiCalledRef = useRef(false);

  useEffect(() => {
    if (apiCalledRef.current) return; // Exit if API has already been called
    apiCalledRef.current = true;

    const fetchCategories = async url => {
      try {
        const response = await axios.get(url);
        setCategories(response.data);
      } catch (error) {
        setCategories([]);
        console.error('Error fetching categories:', error);
      }
    };

    const getSeoKeyword = () => {
      const pathParts = location.pathname.split('/');
      return pathParts[pathParts.length - 1];
    };

    if (location?.state?._id) {
      const subcategoryUrl = `${serverAPI}/subcategories/list/${location.state._id}`;
      fetchCategories(subcategoryUrl);
    } else {
      const seoKeyword = getSeoKeyword();
      const seoKeywordUrl = `${serverAPI}/subcategories/seokeyword?seokeyword=${seoKeyword}`;
      fetchCategories(seoKeywordUrl);
    }
  }, [location]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // loacation pop up

  const [isPopupLocOpen, setIsPopupLocOpen] = useState(false);

  const openLocPopup = () => {
    setIsPopupLocOpen(true);
  };

  const closeLocPopup = () => {
    setIsPopupLocOpen(false);
  };

  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }

  const handleServiceClick = item => {
    // // Construct the URL with the required parameters
    const url = `/services/${toKebabCase(item.category)}`;
    // // Navigate to the specified URL
    navigate(url, { state: item });
  };

  const [addkey, setAddKey] = useState(null);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('addkey'));
    if (items) {
      setAddKey(items);
    }
  }, []);

  const [pageLoaded, setPageLoaded] = useState(true);
  const [city, setCity] = useState(null); // Set default state to null

  // Function to extract the city from the URL
  const extractCityFromURL = () => {
    const pathname = location.pathname;
    const pathSegments = pathname.split('/'); // Split the path into segments

    // Check if there are at least two segments
    if (pathSegments.length > 1) {
      const cityMatch = pathSegments[1]; // Get the second segment as the city

      // Define a list of service identifiers to ignore
      const ignoredSegments = ['at-home-service']; // Add more if needed

      // Check if the cityMatch is not in the ignoredSegments
      if (cityMatch && !ignoredSegments.includes(cityMatch)) {
        // Capitalize the first letter of the city name
        return cityMatch.charAt(0).toUpperCase() + cityMatch.slice(1);
      }
    }
    return null; // Return null if no valid city found
  };

  useEffect(() => {
    setCity(null);
  }, []);
  useEffect(() => {
    const extractedCity = extractCityFromURL();
    if (city !== extractedCity) {
      setCity(extractedCity);
    }
  }, [location, city]);

  const seoKeyword1 = getSeoKeyword();
  const pathname2 = location.pathname;

  const metaDetails = useMemo(() => {
    if (!city) {
      // If city is blank, generate default metadata
      return {
        title: `${seoKeyword1}  | Best Salon Near Me | Salon Ease Premium At-Home Salon Services`,
        description: `Enjoy the best salon and beauty services at home. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
        keywords: `at-home salon services, beauty services at home, home grooming, salon services booking`,
        ogImage:
          'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
        canonical: `https://salonease.in`,
      };
    } else {
      // If city has a value, generate city-specific metadata
      return {
        title: `${seoKeyword1} Premium At-Home Salon Services in ${city} | Best Nearby Salon | Salon Ease`,
        description: `Enjoy the best salon and beauty services at home in ${city}. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
        keywords: `at-home salon services in ${city}, beauty services in ${city}, grooming services in ${city}`,
        ogImage:
          'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
        canonical: `https://salonease.in${pathname2}`,
      };
    }
  }, [city, seoKeyword1, pathname2]);

  useEffect(() => {
    const detectUserCity = () => {
      const extractedCity = extractCityFromURL(); // Function to extract city from URL
      if (city !== extractedCity) {
        setCity(extractedCity);
      }
    };

    detectUserCity();
    setPageLoaded(false); // Load the content when the city is detected
  }, [location, city]);
  return (
    <>
      <div>
        <Homeheader />
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description} />
        <meta property="og:title" content={metaDetails.ogTitle} />
        <link rel="canonical" href={metaDetails.canonical} />
        <meta property="og:image" content={metaDetails.ogImage} />
        {/* <meta property="og:url" content={metaDetails.ogUrl} /> */}
        <link rel="canonical" href={metaDetails.ogUrl} />
      </Helmet>
      <SalonSubcategories />
      <Footer />
    </>
  );
};

export default SubCategory;

// {/* <div className="frontpage">
// <div className="home-slider">
//   <div className="mobile-taglin dnon " style={{ height: '0' }}></div>

//   {/*onClick={openLocPopup}  <SearchLocation isOpen={isPopupLocOpen} onClose={closeLocPopup} /> */}

//   <div className="banner">
//     {window.innerWidth < 768 ? (
//       <div className="slide-img">
//         <img
//           src={location?.state?.bannerImage_mobile}
//           alt=""
//           style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
//         />
//       </div>
//     ) : (
//       <div className="slid-img">
//         {/* <Link to="/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788&service=Salon%20Ease%20Exclusive%20Packages">
//           <img src={SilderImage3} alt="" />
//         </Link> */}
//         <img
//           src={location?.state?.bannerImage_web}
//           alt=""
//           style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
//         />
//       </div>
//     )}
//   </div>
// </div>

// <div className="card-container custom-margin subcat">
//   <h3>
//     {categories !== null ? (
//       <>
//         {categories.length > 0 && (
//           <>
//             <span>{categories[0]?.category} services at home </span>
//           </>
//         )}
//       </>
//     ) : null}
//   </h3>
//   <div class="grid-container martop" style={{ marginBottom: '68px' }}>
//     {categories !== null ? (
//       <>
//         {categories?.map((item, index) => (
//           <div class="grid-item" key={index}>
//             <div
//               class="Service-boxx"
//               onClick={() => {
//                 handleServiceClick(item);
//               }}
//               style={{ cursor: 'pointer' }}
//             >
//               <div className="box-img">
//                 <img
//                   style={{ height: 'auto' }}
//                   src={item?.image}
//                   alt={`${item?.name} services at home`}
//                 />
//               </div>
//               <p>{item?.name}</p>
//             </div>
//           </div>
//         ))}
//       </>
//     ) : null}
//   </div>
// </div>
// </div> */}
