import React, { useState, Component } from 'react'
import Slider from "react-slick";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/images/Card1.png'
import Slid1 from '../../assets/images/Card1.png'
import Slid2 from '../../assets/images/Card2.png'
import Slid3 from '../../assets/images/Card3.png'
import Slid4 from '../../assets/images/Card4.png'
import Slid5 from '../../assets/images/Card5.png'
import Slid6 from '../../assets/images/Card6.png'



import './newhome.css'
import { Link } from 'react-router-dom';

export default class CardSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ]
        };

        return (
            <>
                <div className='selling-parent'>
                    <h2><span>Top selling</span>  packages </h2>

                    
                    <div style={{ margin: '0px 10px' }}>
                        <Slider {...settings}>
                            <div className='selling-card' >
                               
                                    <img src={Slid1} alt='Any 4 salon services at just 999' />
                                
                            </div>
                            <div className='selling-card' >
                                
                                    <img src={Slid2} alt='Any 4 premium salon services at just 1449' />
                              
                            </div>
                            <div className='selling-card' >
                               
                                    <img src={Slid3} alt='Any 5 premium salon services at just 1699' />
                          
                            </div>
                            <div className='selling-card' >
                               
                                    <img src={Slid4} alt='Any 7 premium salon services at just 2749' />
                             
                            </div>
                            <div className='selling-card' >
                               
                                    <img src={Slid5} alt='Tip to toe salon package at just 2649' />
                         
                            </div>
                            <div className='selling-card' >
                             
                                <img src={Slid6} alt='Monthly essentials package at just 2649' />
                               
                            </div>
                            
                        </Slider>
                    </div>


                </div>
            </>
        )
    }
}
