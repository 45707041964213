import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../assets/images/ftr-logo.png';
import Footer1 from '../assets/images/ftr1.png';
import Footer2 from '../assets/images/ftr2.png';
import Footer3 from '../assets/images/ftr3.png';
import Footer4 from '../assets/images/ftr4.png';
import Footer5 from '../assets/images/ftr5.png';
import Footer6 from '../assets/images/ftr6.png';
import Footer7 from '../assets/images/ftr7.png';
import Footer8 from '../assets/images/ftr8.png';
import './MobileFooter.css';

export default function MobileFooter() {
  const navigate = useNavigate();
  const cartItemsCount = useSelector(state => state.cart.products.length);
  const location = useLocation();

  const isRouteActive = route => location.pathname === route;

  function toKebabCase(str) {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  }

  const handleClick = (name, id) => {
    navigate(`/services/${toKebabCase(name)}`, {
      state: {
        name: name,
        _id: id,
      },
    });
  };

  return (
    <footer className="mobile-footer">
      <div className="mobile-footer__cities">
        {[
          'Amritsar',
          'Bhawaneshwar',
          'Dhanbad',
          'Gurugram',
          'Jamshedpur',
          'Jhansi',
          'Ludhiana',
          'Meerut',
          'Mohali',
          'Patna',
          'Prayagraj',
          'Raipur',
          'Ranchi',
          'Ujjain',
          'Varanasi',
        ].map((city, index, array) => (
          <span key={city} className="mobile-footer__city">
            {city}
            {index !== array.length - 1 && (
              <span className="mobile-footer__separator">|</span>
            )}
          </span>
        ))}
      </div>
      <div class="mobile-footer__divider"></div>
      <div className="mobile-footer__services">
        <img
          src={Footer1}
          alt="Service 1"
          className="mobile-footer__service-icon"
        />
        <img
          src={Footer2}
          alt="Service 2"
          className="mobile-footer__service-icon"
        />
        <img
          src={Footer5}
          alt="Service 3"
          className="mobile-footer__service-icon"
        />
        <img
          src={Footer4}
          alt="Service 4"
          className="mobile-footer__service-icon"
        />
        <img
          src={Footer3}
          alt="Service 5"
          className="mobile-footer__service-icon"
        />
      </div>

      <div className="mobile-footer__divider"></div>

      <div className="mobile-footer__links">
        <div className="mobile-footer__section">
          <h3 className="mobile-footer__title">Services</h3>
          <ul className="mobile-footer__list">
            <li>
              
                Salon at home
              
            </li>
            <li>
              
                Any 4 services in 999
             
            </li>
            <li>
             
                Keratin & hair Botox
             
            </li>
            <li>
              
                Pre Bridal Packages
             
            </li>
            <li>
              
                Makeup & hair styling
              
            </li>
            <li>
              
                Massage
             
            </li>
            <li>
             
                Body Polish
             
            </li>
          </ul>
        </div>

        <div className="mobile-footer__section">
          <h3 className="mobile-footer__title">Salon Ease</h3>
          <ul className="mobile-footer__list">
            <li>
              <Link to="/about-us" className="mobile-footer__link">
                About us
              </Link>
            </li>
            <li>
              <Link to="/terms-conditions" className="mobile-footer__link">
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="mobile-footer__link">
                Privacy policy
              </Link>
            </li>
            <li>
              <Link to="/refund-policy" className="mobile-footer__link">
                Refund policy
              </Link>
            </li>
          </ul>
        </div>

        <div className="mobile-footer__section">
          <h3 className="mobile-footer__title">Work with us</h3>
          <ul className="mobile-footer__list">
            <li>
              <Link to="/work-with-us" className="mobile-footer__link">
                Register as a professional
              </Link>
            </li>
          </ul>
        </div>

        <div className="mobile-footer__section">
          <h3 className="mobile-footer__title">Contact & Support</h3>
          <ul className="mobile-footer__list">
            <li>
              <a href="tel:+919117770106" className="mobile-footer__link">
                +91 91177 70106
              </a>
            </li>
            <li>
              <a
                href="mailto:hello@salonease.in"
                className="mobile-footer__link"
              >
                hello@salonease.in
              </a>
            </li>
            <li>
              <Link to="/gethelp" className="mobile-footer__link">
                Get help
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="mobile-footer__social">
        <a
          href="https://www.instagram.com/salonease.in/"
          className="mobile-footer__social-link"
        >
          <img
            src={Footer6}
            alt="Instagram"
            className="mobile-footer__social-icon"
          />
        </a>
        <a
          href="https://www.facebook.com/salonease.in/"
          className="mobile-footer__social-link"
        >
          <img
            src={Footer7}
            alt="Facebook"
            className="mobile-footer__social-icon"
          />
        </a>
        <a
          href="https://wa.me/9117770107?text=Hello,+I+am+interested+in+services/"
          className="mobile-footer__social-link"
        >
          <img
            src={Footer8}
            alt="WhatsApp"
            className="mobile-footer__social-icon"
          />
        </a>
      </div>

      <div className="mobile-footer__divider"></div>

      <div className="mobile-footer__company">
        <img src={Logo} alt="Salon Ease Logo" className="mobile-footer__logo" />
        <p className="mobile-footer__copyright">
          Salon Ease Beauty & Personal care © All rights reserved
        </p>
      </div>
    </footer>
  );
}
