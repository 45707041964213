import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import House from '../../assets/images/house.png'
import './gethelp.css'
import Whatsapp from '../../assets/images/whatsapp.svg'
import Call from '../../assets/images/call.svg'
import Email from '../../assets/images/mail.svg'
import ProfileHeder from './profileHeder'
import ProfileMenu from './ProfileMenu'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import LoginModal from '../login/loginModal'


export default function Contactus() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });
            if (res.status === 200) {
                const userData = res.data.user;
                setUserData(userData);
                setPageLoaded(false);
            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };
    useEffect(() => {
        getRootUser();
    }, []);
    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };

    const closePopups = () => {
        navigate('/')
        setIsPopupOpens(false);
    };
    return (
        <>{
            pageLoaded ?
                <>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                        <HashLoader
                            color="#981313"
                            size={50}
                        />

                    </div>
                    <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

                </>
                :
                <div className='profile-parent'>
                    <div className='container'>

                        <div className='trackorder-wrap'>
                            <ProfileHeder name={userData?.name} phone={userData?.phone} address={getFormattedAddress()} />


                            <div className='Profile-divider'>
                                <ProfileMenu />
                                <div className='gethelp-content'>

                                    <div className='gethelp-box'>
                                        <div className='help-text'>
                                            <h4>Contact us</h4>
                                            <p>Please get in touch and we will be
                                                happy to help you
                                            </p>
                                        </div>

                                    </div>

                                    <div className='contact'>

                                        <a href="https://wa.me/+919117770106">

                                            <div className='contact1'>
                                                <button> <img src={Whatsapp} width="25px" style={{ marginRight: '15px' }} /> Whats app us</button>
                                            </div></a>

                                        <a href="tel:+919117770107">
                                            <div className='contact1'>
                                                <button><img src={Call} width="25px" style={{ marginRight: '15px' }} />Call us now</button>
                                            </div></a>

                                        <a href="mailto:hello@saloease.in"><div className='contact1'>
                                            <button><img src={Email} width="25px" style={{ marginRight: '15px' }} />Email us</button>
                                        </div></a>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        }
        </>
    )
}
