// import { createSlice } from "@reduxjs/toolkit";

// const cartSlice = createSlice({
//   name: "cart",
//   initialState: {
//     products: [],
//   },
//   reducers: {
//     addToCart: (state, action) => {
//       const productToAdd = action.payload;
//       const existingProduct = state.products.find(
//         (product) => product._id === productToAdd._id
//       );


//       if (existingProduct) {
//         // If product is already in cart, increase quantity and update price
//         existingProduct.quantity += 1;
//         existingProduct.totalPrice = existingProduct.price * existingProduct.quantity;
//         existingProduct.totalDiscoutPrice = existingProduct.actual_price * existingProduct.quantity;
//       } else {
//         // If product is not in cart, add it with quantity 1
//         state.products.push({ ...productToAdd, quantity: 1, totalPrice:productToAdd.price, totalDiscoutPrice:productToAdd.actual_price });
//       }

//       localStorage.setItem("cart", JSON.stringify(state.products));
//     },
//     removeFromCart: (state, action) => {
//       const productId = action.payload;
//       state.products = state.products.filter(
//         (product) => product._id !== productId
//       );
//       localStorage.setItem("cart", JSON.stringify(state.products));
//     },
//     incrementQuantity: (state, action) => {
//       const productId = action.payload.productId;

//       const product = state.products.find((p) => p._id === productId);

//       if (product) {
//         product.quantity += 1;
//         product.totalPrice = product.price * product.quantity; // Update total price
//         product.totalDiscoutPrice = product.actual_price * product.quantity;
//         localStorage.setItem("cart", JSON.stringify(state.products));
//       }
//     },
//     decrementQuantity: (state, action) => {
//       const productId = action.payload.productId;
//       // console.log('Decrement: productId', productId);

//       const product = state.products.find((p) => p._id === productId);

//       if (product && product.quantity > 1) {
//         product.quantity -= 1;
//         product.totalPrice = product.price * product.quantity; // Update total price
//         product.totalDiscoutPrice = product.actual_price * product.quantity;
//         localStorage.setItem("cart", JSON.stringify(state.products));
//       } else if (product && product.quantity === 1) {
//         // Remove the product from the cart if quantity becomes zero
//         state.products = state.products.filter((p) => p._id !== productId);
//         localStorage.setItem("cart", JSON.stringify(state.products));
//       }
//     },

//     // ... other reducers
//   },
// });

// export const {
//   addToCart,
//   removeFromCart,
//   incrementQuantity,
//   decrementQuantity,
// } = cartSlice.actions;

// export default cartSlice.reducer;


// cartSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const productToAdd = action.payload;
      const totalCartTime = calculateTotalCartTime(state.products);

      // If the total time is less than 7 hours, allow adding to the cart
      if (totalCartTime.hours < 7 || (totalCartTime.hours === 7 && totalCartTime.minutes === 0)) {
        const existingProductIndex = state.products.findIndex(
          (product) => product._id === productToAdd._id
        );

        if (existingProductIndex !== -1) {
          // If product is already in cart,increase quantity and update price
          const existingProduct = state.products[existingProductIndex];
          existingProduct.quantity += 1;
          existingProduct.totalPrice = existingProduct.price * existingProduct.quantity;
          existingProduct.totalDiscountPrice = existingProduct.actual_price * existingProduct.quantity;
        } else {
          // If product is not in cart, add it with quantity 1
          state.products.push({
            ...productToAdd,
            quantity: 1,
            totalPrice: productToAdd.price,
            totalDiscountPrice: productToAdd.actual_price,
            totalTime: productToAdd.time,
          });
        }

        localStorage.setItem("cart", JSON.stringify(state.products));
      } else {
        toast.error("You have reached the time limit of 7 hours!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });

      }


    },
    decrementQuantity: (state, action) => {
      const productId = action.payload.productId;
      const product = state.products.find((p) => p._id === productId);

      if (product && product.quantity > 1) {
        product.quantity -= 1;
        product.totalPrice = product.price * product.quantity;
        product.totalDiscountPrice = product.actual_price * product.quantity;

        product.totalTime = subtractFromTime(product.time, product.quantity); // Subtract the product's time from itself, effectively removing it
        localStorage.setItem("cart", JSON.stringify(state.products));
      } else if (product && product.quantity === 1) {
        state.products = state.products.filter((p) => p._id !== productId);
        localStorage.setItem("cart", JSON.stringify(state.products));
      }
    },
    incrementQuantity: (state, action) => {
      const productId = action.payload.productId;
      const product = state.products.find((p) => p._id === productId);
      const totalCartTime = calculateTotalCartTime(state.products);
      if (totalCartTime.hours < 7 || (totalCartTime.hours === 7 && totalCartTime.minutes === 0)) {
        if (product && product.quantity < 3) {
          product.quantity += 1;
          product.totalPrice = product.price * product.quantity;
          product.totalDiscountPrice = product.actual_price * product.quantity;
          product.totalTime = addToTime(product.time, product.quantity); // Add the product's time to itself, effectively doubling it
          localStorage.setItem("cart", JSON.stringify(state.products));
        } else {
          toast.warn("You have reached the maximum quantity limit of this product!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          
        }
      } else {
        toast.error("You have reached the time limit of 7 hours.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });

      }


    },

    removeAllFromCart: (state) => {
      // Clear the products array to remove all items from the cart
      state.products = [];
      localStorage.removeItem("cart"); // Remove cart data from local storage
    },
    // Other reducers...

    // Add your other reducer logic here...
  },
});

// Function to calculate total cart time
function calculateTotalCartTime(products) {
  let totalHours = 0;
  let totalMinutes = 0;

  products.forEach((product) => {
    if (product.quantity) {
      const timeParts = product.time.split(" ");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[2]);

      totalHours += hours * product.quantity;
      totalMinutes += minutes * product.quantity;
    }
  });

  while (totalMinutes >= 60) {
    const extraHours = Math.floor(totalMinutes / 60);
    totalHours += extraHours;
    totalMinutes %= 60;
  }

  return { hours: totalHours, minutes: totalMinutes };
}

// Helper function to add times in "00 hr 10 min" format
function addToTime(existingTime, quantity) {
  const timeParts = existingTime.split(" ");

  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[2]);

  const totalHours = hours * quantity;
  const totalMinutes = minutes * quantity;

  if (totalMinutes >= 60) {
    const extraHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${totalHours + extraHours} hr ${remainingMinutes} min`;
  } else {
    return `${totalHours} hr ${totalMinutes} min`;
  }
}

// Helper function to subtract times in "00 hr 10 min" format
function subtractFromTime(existingTime, quantity) {
  const timeParts = existingTime.split(" ");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[2]);
  let totalHours = hours - hours * quantity;
  let totalMinutes = minutes - minutes * quantity;
  while (totalMinutes < 0) {
    totalHours -= 1;
    totalMinutes += 60;
  }
  if (totalHours < 0) {
    return '0 hr 0 min'; // To prevent negative time
  }

  return `${totalHours} hr ${totalMinutes} min`;
}

// Selector function to calculate the total cost
export const selectTotalCost = (state) => {
  return state.cart.products.reduce((total, product) => {
    return total + parseInt(product.totalPrice);
  }, 0);
};

// Selector function to calculate the total time
export const selectTotalTime = (state) => {
  let totalHours = 0;
  let totalMinutes = 0;

  state.cart.products.forEach((product) => {
    if (product.quantity) {
      const timeParts = product.time.split(" ");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[2]);

      totalHours += hours * product.quantity;
      totalMinutes += minutes * product.quantity;
    }
  });

  while (totalMinutes >= 60) {
    const extraHours = Math.floor(totalMinutes / 60);
    totalHours += extraHours;
    totalMinutes %= 60;
  }

  return `${totalHours} hr ${totalMinutes} min`;
};


// Export your reducers and actions as needed
export const {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeAllFromCart, // Add the new reducer here
} = cartSlice.actions;

export default cartSlice.reducer;

