import React, { useEffect, useState, lazy, Suspense, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { Helmet } from 'react-helmet';

// Lazy load components
const NewHome = lazy(() => import('../components/home/newHome'));
const Footer = lazy(() => import('../layouts/footer'));
const Homeheader = lazy(() => import('../layouts/home_header'));

export default function NewHomePage() {
  const [pageLoaded, setPageLoaded] = useState(true);
  const location = useLocation();
  const [city, setCity] = useState(''); // Updated default state to empty

  // Function to extract the city from the URL
  const extractCityFromURL = () => {
    const pathname = location.pathname;
    const cityMatch = pathname.split('/')[1]; // Get first segment after '/'
    let cityName = cityMatch && cityMatch.length > 0 ? cityMatch.split('-')[0] : '';
    return cityName.charAt(0).toUpperCase() + cityName.slice(1); // Capitalize first letter
  };

  // Generate SEO metadata based on city (memoized to prevent recalculation)
  // const metaDetails = useMemo(() => {
  //   if (!city) {
  //     return {
  //       title: `Luxury At-Home Salon Services| Best Salon Near Me | Salon Ease`,
  //       description: `Enjoy the best salon and beauty services at home. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
  //       keywords: `at-home salon services, beauty services at home, home grooming, salon services booking`,
  //       //ogUrl: `https://salonease.in`,
  //       ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
  //       canonical: `https://salonease.in`,
  //     };
  //   }
  //   return {
  //     title: `Luxury At-Home Salon Services in ${city} | Best Nearby Salon | Salon Ease`,
  //     description: `Enjoy the best salon and beauty services at home in ${city}. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
  //     keywords: `at-home salon services in ${city}, beauty services in ${city}, grooming services in ${city}`,
  //     //ogUrl: `https://salonease.in/${city}-at-home-services`,
  //     ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
  //     canonical: `https://salonease.in/${city}-at-home-services`,
  //   };
  // }, [city]);

  const metaDetails = useMemo(() => {
    if (!city) {
      return {
        title: `Luxury At-Home Salon Services | Best Salon Near Me | Salon Ease`,
        description: `Enjoy the best salon and beauty services at home. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
        keywords: `at-home salon services, beauty services at home, home grooming, salon services booking`,
        ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
        canonical: `https://salonease.in`,
      };
    }
  
    // Handling meta details for specific cities
    switch (city.toLowerCase()) {
      case 'ranchi':
        return {
          title: `Luxury At-Home Salon Services in Ranchi | Best salon near me | Salon Ease`,
          description: `Enjoy the best salon and beauty services at home in Ranchi. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,          
          ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
          canonical: `https://www.salonease.in/ranchi-salon-at-home-services`,
        };
      case 'ludhiana':
        return {
          title: `Luxury At-Home Salon Services in Delhi | Best Nearby Salon | Salon Ease`,
          description: `Enjoy the best salon and beauty services at home in Delhi. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
          ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
          canonical: `https://www.salonease.in/ludhiana-salon-at-home-services`,
        };
      case 'dhanbad':
        return {
          title: `Luxury At-Home Salon Services in Mumbai | Best Nearby Salon | Salon Ease`,
          description: `Enjoy the best salon and beauty services at home in Mumbai. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
          ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
          canonical: `https://salonease.in/mumbai-at-home-services`,
        };
      // Add more cases for other cities
      default:
        return {
          title: `Luxury At-Home Salon Services in ${city} | Best Nearby Salon | Salon Ease`,
          description: `Enjoy the best salon and beauty services at home in ${city}. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.`,
          ogImage: 'https://res.cloudinary.com/dy4hpcssz/image/upload/v1726816667/zpxhl6lqhf4xxphg1o4y.jpg',
          canonical: `https://salonease.in/${city}-at-home-services`,
        };
    }
  }, [city]);

  useEffect(() => {
    const detectUserCity = () => {
      let extractedCity = extractCityFromURL();
      if (city !== extractedCity) {
        setCity(extractedCity);
      }
    };

    detectUserCity();
    setPageLoaded(false); // Load the content when the city is detected
  }, [location, city]);

  return (
    <>
      {pageLoaded ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <HashLoader color="#981313" size={50} />
        </div>
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{metaDetails.title}</title>
            <meta name="description" content={metaDetails.description} />
            <meta property="og:title" content={metaDetails.ogTitle} />
            <link rel="canonical" href={metaDetails.canonical} />
            <meta property="og:image" content={metaDetails.ogImage} />
            {/* <meta property="og:url" content={metaDetails.ogUrl} /> */}
            <link rel="canonical" href={metaDetails.ogUrl} />
          </Helmet>

          <Suspense fallback={null}>
            <Homeheader />
            <NewHome />
            <Footer />
          </Suspense>
        </>
      )}
    </>
  );
}
