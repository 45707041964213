import React from 'react';
import { AiFillLinkedin, AiOutlineInstagram } from 'react-icons/ai';
import { FiFacebook } from 'react-icons/fi';
import Logo from '../assets/images/ftr-logo.png';
import Home from '../assets/images/footer1.svg';
import Profile from '../assets/images/footer3.svg';
import Booking from '../assets/images/footer2.svg';
import Cart from '../assets/images/footer4.svg';
import Home2 from '../assets/images/footer5.svg';
import Profile2 from '../assets/images/footer6.svg';
import Booking2 from '../assets/images/footer7.svg';
import Cart2 from '../assets/images/footer8.svg';
import './footer.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer1 from '../assets/images/ftr1.png';
import Footer2 from '../assets/images/ftr2.png';
import Footer3 from '../assets/images/ftr3.png';
import Footer4 from '../assets/images/ftr4.png';
import Footer5 from '../assets/images/ftr5.png';
import Footer6 from '../assets/images/ftr6.png';
import Footer7 from '../assets/images/ftr7.png';
import Footer8 from '../assets/images/ftr8.png';

export default function Footer() {
  const navigate = useNavigate();
  const cartItemsCount = useSelector(state => state.cart.products.length);
  const location = useLocation(); // Get the current location
  // Define a function to check if a route is active
  const isRouteActive = route => location.pathname === route;
  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }
  const handleClick = (name, id) => {
    navigate(`/services/${toKebabCase(name)}`, {
      state: {
        name: name,
        _id: id,
      },
    });
  };

  return (
    <>
      <div className="footer">
        <div class="city-container city-container--flex city-container--wrap city-container--gap mt-5">
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Amritsar</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Bhawaneshwar</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Dhanbad</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Gurugram</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Jamshedpur</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Jhansi</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Ludhiana</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Meerut</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Mohali</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Patna</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Prayagraj</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Raipur</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Ranchi</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Ujjain</span>
            <span class="separator mx-1">|</span>
          </div>
          <div class="city-item city-item--flex city-item--center text-xs font-normal">
            <span>Varanasi</span>
          </div>
        </div>

        <div className="futr-img">
          <div className="ftr1">
            <img src={Footer1} width="57px" height="57px" />
          </div>
          <div className="ftr1">
            <img src={Footer2} width="47px" height="52px" />
          </div>
          <div className="ftr1">
            <img src={Footer5} width="52px" height="58px" />
          </div>
          <div className="ftr1">
            <img src={Footer4} width="48px" height="60px" />
          </div>
          <div className="ftr1">
            <img src={Footer3} width="49px" height="51px" />
          </div>
        </div>
        <div className="divdr"></div>
        <div className="footer_inner">
          <div className="contener1">
            <ul className="uls">
              <li className="footer_titel">Services</li>
              <a href="/service">
                <li className="footer_itmes">Salon at home</li>
              </a>
              <a
                onClick={e => {
                  e.preventDefault();
                  handleClick(
                    'Create your own Package',
                    '651859f0909e90359c9a4864'
                  );
                }}
              >
                <li className="footer_itmes">Any 4 services in 999</li>
              </a>
              <a
                onClick={e => {
                  e.preventDefault();
                  handleClick(
                    'Keratin & hair Botox',
                    '656eb297fdb4b0f0f0b4d3d0'
                  );
                }}
              >
                <li className="footer_itmes">Keratin & hair Botox</li>
              </a>
              <a href="/service?name=Pre%20Bridal%20Packages&id=651be383909e90359c9a5cc7">
                <li className="footer_itmes">Pre Bridal Packages</li>
              </a>
              <a href="/service?name=Makeup%20%26%20hair%20styling&id=651be302909e90359c9a5caf">
                <li className="footer_itmes">Makeup & hair styling</li>
              </a>
              <a href="/service?name=Body%20Massage&id=651be31d909e90359c9a5cb5">
                <li className="footer_itmes">Massage</li>
              </a>
              <a href="/service?name=Body%20Polish&id=651be356909e90359c9a5cbb">
                <li className="footer_itmes">Body Polish</li>
              </a>
            </ul>
          </div>
          <div className="contener1">
            <ul className="uls">
              <li className="footer_titel">Salon Ease</li>
              <a href="/about-us" style={{ textDecoration: 'none' }}>
                {' '}
                <li className="footer_itmes">About us</li>{' '}
              </a>
              <a href="/terms-conditions" style={{ textDecoration: 'none' }}>
                {' '}
                <li className="footer_itmes">Terms & conditions</li>{' '}
              </a>
              <a href="/privacy-policy" style={{ textDecoration: 'none' }}>
                {' '}
                <li className="footer_itmes">Privacy policy</li>{' '}
              </a>
              <a href="/refund-policy" style={{ textDecoration: 'none' }}>
                {' '}
                <li className="footer_itmes">Refund policy</li>{' '}
              </a>
            </ul>
          </div>
          <div className="contener1">
            <ul className="uls">
              <li className="footer_titel">Work with us</li>
              <a href="/work-with-us">
                <li className="footer_itmes">Register as a professional</li>
              </a>
            </ul>
          </div>
          <div className="contener1">
            <ul className="uls">
              <li className="footer_titel">Contact & Support</li>
              <a style={{ textDecoration: 'none' }} href="tel:+919117770106">
                <li className="footer_itmes">+91 91177 70106</li>{' '}
              </a>
              <a
                style={{ textDecoration: 'none' }}
                href="mailto:hello@salonease.in"
              >
                <li className="footer_itmes">hello@salonease.in</li>
              </a>
              <a href="/gethelp" style={{ textDecoration: 'none' }}>
                {' '}
                <li className="footer_itmes">Get help</li>{' '}
              </a>
            </ul>
          </div>
        </div>
        <div className="logo_footer">
          <a
            href="https://www.instagram.com/salonease.in/"
            className="social-link"
          >
            <div className="footer_social_logo">
              <img src={Footer6} width="42px" height="43px" alt="" />
            </div>
          </a>
          <a
            href="https://www.facebook.com/salonease.in/"
            className="social-link"
          >
            <div className="footer_social_logo">
              <img src={Footer7} width="42px" height="43px" alt="" />
            </div>
          </a>
          <a
            href="https://wa.me/9117770107?text=Hello,+I+am+interested+in+services/"
            className="social-link"
          >
            <div className="footer_social_logo">
              <img src={Footer8} width="42px" height="43px" alt="" />
            </div>
          </a>
        </div>
        <div className="divdr"></div>
        <div className="footer_com">
          <img src={Logo} alt="logo" Width="300px" height="60px" />
          <p className="footer_comtext">
            Salon Ease Beauty & Personal care © All rights reserved
          </p>
        </div>
      </div>

      <div className="active_footer">
        <div className="mobile_footer">
          <div className="footer_menu">
            <Link to="/" className="addNew">
              {isRouteActive('/') ? (
                <>
                  <img src={Home2} className="mobile_logo_img1" alt="" />
                  <p className="footer_link act">Home</p>
                </>
              ) : (
                <>
                  <img src={Home} className="mobile_logo_img1" alt="" />
                  <p className="footer_link">Home</p>
                </>
              )}
            </Link>
          </div>
          <div className="footer_menu">
            <Link to="/currentbooking" className="addNew">
              {isRouteActive('/currentbooking') ? (
                <>
                  <img src={Profile2} className="mobile_logo_img1" alt="" />
                  <p className="footer_link act">Booking</p>
                </>
              ) : (
                <>
                  <img src={Booking} className="mobile_logo_img1" alt="" />
                  <p className="footer_link">Booking</p>
                </>
              )}
            </Link>
          </div>
          <div className="footer_menu">
            <Link to="/profilmenu" className="addNew">
              {isRouteActive('/profilmenu') ? (
                <>
                  <img src={Booking2} className="mobile_logo_img1" alt="" />
                  <p className="footer_link act">Account</p>
                </>
              ) : (
                <>
                  <img src={Profile} className="mobile_logo_img1" alt="" />
                  <p className="footer_link">Account</p>
                </>
              )}
            </Link>
          </div>
          <div className="footer_menu">
            <Link to="/cart" className="addNew">
              <div className="cart_icon_number_foot"> {cartItemsCount} </div>
              {isRouteActive('/cart') ? (
                <>
                  <img src={Cart2} className="mobile_logo_img1" alt="" />
                  <p className="footer_link act">Cart</p>
                </>
              ) : (
                <>
                  <img src={Cart} className="mobile_logo_img1" alt="" />
                  <p className="footer_link">Cart</p>
                </>
              )}
              {/* <img src={Cart} className="mobile_logo_img1" alt='' />
                            <img src={Cart2} className="mobile_logo_img1" alt='' />
                            <p className='footer_link'>Cart</p> */}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
