import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './profile.css'
import LoginModal from '../login/loginModal'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import ProfileHeder from './profileHeder'
import ProfileMenu from './ProfileMenu';

export default function Userprofile() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });
            if (res.status === 200) {
                setUserData(res.data.user);
                setPageLoaded(false);
            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };
   
    useEffect(() => {
        getRootUser();
    }, []);

    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };

    const closePopups = () => {
        navigate('/')
        setIsPopupOpens(false);
    };


    return (
        <>
            {
                pageLoaded ?
                    <>
                        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                            <HashLoader
                                color="#981313"
                                size={50}
                            />

                        </div>
                        <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

                    </>
                    :
                    <div className='profile-parent'>
                        <div className='container'>
                            <div className='profile-wrap'>
                                <ProfileHeder name={userData.name} phone={userData.phone} address={getFormattedAddress()} />

                                <div className='Profile-divider'>
                                    <ProfileMenu />

                                    <div className='profile-content'>

                                        <div className='profile-detail'>
                                            <h4>Profile details</h4>
                                            <table>
                                                <tr>
                                                    <th>Full name</th>
                                                    <td>- {userData?.name ? userData?.name : <>not added</>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Mobile number</th>
                                                    <td>- {userData?.phone ? userData?.phone : <>not added</>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>- {userData?.email ? userData?.email : <>not added</>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Gender</th>
                                                    <td>- {userData?.gender ? userData?.gender : <>not added</>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Birthday</th>
                                                    <td>- {userData?.birthday ? userData?.birthday : <>not added</>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Alternate number</th>
                                                    <td>- {userData?.alternateNumer ? userData?.alternateNumer : <>not added</>}</td>
                                                </tr>
                                            </table>

                                            <div className='prbtn-wrap'>
                                                <div className='profil-edit'>
                                                    <Link to='/profile_edit'><button>EDIT</button></Link>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
            }

        </>
    )
}
