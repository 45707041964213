import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import House from '../../assets/images/house.png'
import './gethelp.css'
import usericon from '../../assets/images/Maskgroup3.png'
import Next from '../../assets/images/next.png'
import Bookoing from '../../assets/images/Maskgroup1.png'
import Address from '../../assets/images/Maskgroup4.png'
import Allorder from '../../assets/images/Maskgroup2.png'
import Help from '../../assets/images/Maskgroup7.png'
import Track from '../../assets/images/Maskgroup6.png'
import Wallet from '../../assets/images/Maskgroup5.png'
import ProfileHeder from './profileHeder'
import ProfileMenu from './ProfileMenu'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import LoginModal from '../login/loginModal'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
function Query() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpens, setIsPopupOpens] = useState(false);
  const [isloding, setIsLoding] = useState(true)
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [formData, setFormData] = useState({
    queries: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      name: userData?.name,
      phone: userData?.phone,
    }));
  };
  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
        setPageLoaded(false);
      }
    } catch (err) {
      setIsPopupOpens(true);
    }
  };

  const sendQurey = async () => {
    setIsLoding(false)
    if (!formData.queries) {
      toast.warn("Please choose issue!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
    else if (!formData.message) {
      toast.warn("Please type your message!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      try {
        const res = await axios.post(`${serverAPI}/contact-us`, formData, {
          withCredentials: true,
        });
        if (res.status === 200) {
          toast.success("Thank you! we will get back to you soon", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          setTimeout(() => {
            setIsLoding(true);
            window.location.reload();
          }, 2500);
        }
      } catch (error) {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setIsLoding(true);
      }
    }

  }
  useEffect(() => {
    getRootUser();
  }, []);
  const getFormattedAddress = () => {
    if (userData?.address.home && userData?.address.home.length > 0) {
      return userData?.address.home[0].address;
    } else if (userData?.address.other && userData?.address.other.length > 0) {
      return userData?.address.other[0].address;
    } else {
      return 'No address available';
    }
  };
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpens(false);
  };

  const closePopups = () => {
    navigate('/')
    setIsPopupOpens(false);
  };
  return (
    <>
      {
        pageLoaded ?
          <>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
              <HashLoader
                color="#981313"
                size={50}
              />

            </div>
            <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

          </>
          :
          <div className='profile-parent'>
            <div className='container'>

              <div className='trackorder-wrap'>
                <ProfileHeder name={userData?.name} phone={userData?.phone} address={getFormattedAddress()} />


                <div className='Profile-divider'>
                  <ProfileMenu />
                  <div className='gethelp-content'>

                    <div className='gethelp-box'>
                      <div className='help-text'>
                        <h4>Raise Query</h4>
                        <p>Please get in touch and we will be
                          happy to help you
                        </p>
                      </div>

                    </div>

                    <div className='query'>
                      <h3>Choose issue </h3>
                      <div className='choose'>
                        {/* <h4>Choose issue </h4>
                       <img src={Next} width='20px' /> */}

                        <select
                          name='queries'
                          value={formData.queries}
                          onChange={handleInputChange}
                        >
                          <option value=''>Choose issue</option>
                          <option value='Booking related'>Booking related</option>
                          <option value='Service related issue'>Service related issue</option>
                          <option value='Billing related issue'>Billing related issue</option>
                          <option value='Reschedule booking'>Reschedule booking</option>
                          <option value='Get call back'>Get call back</option>
                        </select>
                      </div>
                      <h3>Message</h3>
                      <textarea
                        name='message'
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                      <button onClick={() => { sendQurey() }}>{isloding ? <>SAVE </> : <BeatLoader color="#ffffff" size={10} />}</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
      }
    </>
  )
}

export default Query;