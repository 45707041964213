import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  incrementQuantity,
  decrementQuantity,
} from '../../utils/cartSlice'; // Replace with the correct import path for your cart slice
import Time from '../../assets/images/timer.png';
// import { Link } from 'react-router-dom';
// import NewserviceModal from './newserviceModal';
import { toast } from 'react-toastify';
// import v682_250 from '../../assets/images/v682_250.png';
// import Star from '../../assets/images/Star2.png';
import { setsubService, setsubNo } from '../../utils/orderSlice'; // Replace with the correct import path for your cart slice
import { useNavigate } from 'react-router-dom';

const MobileServiceCard = ({ service, lastreach }) => {
  
  const dispatch = useDispatch();
  const cartProducts = useSelector(state => state.cart.products);
  const navigate = useNavigate();
  const isProductInCart = cartProducts.some(
    product => product._id === service._id
  );

  const [count, setCount] = useState(
    isProductInCart
      ? cartProducts.find(product => product._id === service._id).quantity
      : 1
  );

  const handleAddToCart = () => {
    dispatch(addToCart(service));
    setCount(1); // Reset the count to 1 when adding to cart
    toast.success('Successfully added in cart 🥳!', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    });
  };

  const handleIncrement = () => {
    dispatch(incrementQuantity({ productId: service._id }));
    setCount(count + 1);
  };

  const handleDecrement = () => {
    dispatch(decrementQuantity({ productId: service._id }));
    setCount(count - 1);
  };
  // new sevice modal

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // Split the description into individual services
  const services = service?.description
    .split(', ')
    .map(service => service.trim());

  // Define the template for each service item
  const serviceTemplate = service => <li key={service}>{service}</li>;

  // your existing code
  const [subServices, setSubServices] = useState(
    service.subService.map(sub => ({ name: sub, checked: false }))
  );

  const handleCheckboxChange = index => {
    const checkedItemsCount = subServices.filter(sub => sub.checked).length;

    const test = [];
    if (checkedItemsCount < service.subNo || subServices[index].checked) {
      const updatedSubService = subServices.map((sub, i) =>
        i === index ? { ...sub, checked: !sub.checked } : sub
      );
      setSubServices(updatedSubService);
      test.push(...updatedSubService);
    } else if (subServices[index].checked) {
      const updatedSubService = subServices.map((sub, i) =>
        i === index ? { ...sub, checked: false } : sub
      );
      setSubServices(updatedSubService);
      test.push(...updatedSubService);
    }

    if (
      checkedItemsCount === service.subNo - 1 &&
      !subServices[index].checked
    ) {
      dispatch(setsubService({ test }));
      dispatch(setsubNo(service.subNo));
    }
  };

  return (
    <div
      className="Mobil-cart"
      style={
        lastreach === true
          ? { margin: '10px 0px', marginBottom: '11.5rem' }
          : { margin: '10px 0px' }
      }
    >
      <div className="cart-box">
        <div className="cart-box1">
          <img
            src={service.image}
            alt={`${service.service_name} services at home`}
          />
        </div>
        <div className="cart-box2">
          <h3>
            <del className="mx-1" style={{ color: '#7D7C7C' }}>
              ₹{parseInt(service.actual_price)}/-
            </del>
            <span className="success mx-1">{service.discount}% off</span>₹
            {parseInt(service.price)}
          </h3>
          <h4>
            <img
              src={Time}
              width="12px"
              height="12px"
              style={{ marginTop: '-2px' }}
              alt={`${service.service_name} services at home`}
            />{' '}
            {service.time}
          </h4>
          <div className="cart-box2-btn">
            <p>
              {' '}
              <span style={{ paddingRight: '3px', color: 'black' }}>
                {' '}
              </span>{' '}
            </p>
            <div className="cart-addbtn">
              {isProductInCart ? (
                <div className="increment-decrement-mobile">
                  <span onClick={handleDecrement}>-</span>
                  <span className="counter">{count}</span>
                  <span onClick={handleIncrement}>+</span>
                </div>
              ) : (
                <>
                  {service.subNo > 0 ? (
                    <>
                      <button
                        onClick={() => {
                          const checkedItemsCount = subServices.filter(
                            sub => sub.checked
                          ).length;
                          if (checkedItemsCount >= service.subNo) {
                            handleAddToCart();
                          } else {
                            toast.warning(
                              'Please select all services to add to cart.',
                              {
                                position: 'top-center',
                                autoClose: 2500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'light',
                              }
                            );
                          }
                        }}
                      >
                        ADD TO CART
                      </button>
                    </>
                  ) : (
                    <button onClick={handleAddToCart}>ADD TO CART</button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="cart-details">
        <h3>{service.service_name}</h3>
        {service.description === 'na' ? null : (
          <ul style={{ paddingTop: '10px' }}>
            {services.map(serviceTemplate)}
          </ul>
        )}
        {service.subNo > 0 ? (
          <>
            {subServices.map((sub, index) => (
              <div className="package-list" key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={sub.checked}
                    onChange={() => {
                      handleCheckboxChange(index);
                    }}
                  />

                  <p>{sub.name}</p>
                </label>
              </div>
            ))}
          </>
        ) : null}
      </div>

      <div className="cart-btn">
        <div className="btn-box1">
          {service.subNo > 0 ? null : (
            <button
              onClick={() => {
                navigate(`/servicepage/?id=${service._id}`);
              }}
            >
              VIEW DETAILS
            </button>
          )}
          {/* onClick={openPopup}<NewserviceModal  href=`/servicepage/?id=${service._id}` isOpen={isPopupOpen} onClose={closePopup} /> */}
        </div>
        <div className="btn-div">
          <div className="btn-box2">
            {service.tags.length > 0 ? (
              <>
                {service?.tags.map((tag, index) => (
                  <span key={index} style={{ marginLeft: '10px' }}>
                    {' '}
                    {tag}
                  </span>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileServiceCard;
