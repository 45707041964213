import React from 'react'
import LoginPage from '../components/login/loginpage'
import LoginHead from '../layouts/login_head'
import OTPPage from '../components/login/optpage'

export default function MobileOTP() {
  return (
    <>
       
       <OTPPage />
    </>
  )
}