import React from 'react'
import HomeHeader from '../layouts/home_header'
import Footer from '../layouts/footer'
import Edit from '../components/profilepage/profil_Edit'

export default function ProfileEdit() {
    return (
        <>
            <HomeHeader />
            <Edit />
            <Footer />

        </>
    )
}
