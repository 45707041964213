import React from 'react'
import House from '../../assets/images/house.png'

export default function profileHeder({name, phone, address}) {
    return (
        <div>
            <div className='profile-head'>
                <div ><img src={House} width="18px" height='18px' style={{ marginRight: '10px', marginTop: '-5px', marginLeft: '15px' }} alt=''/></div>
                <div style={{ width: '900px' }}><p> {address? <>{address}</> : <>not added</>}, {phone? <>{phone}</>: <>not added</>}</p></div>
                <div className='username'><p> {name ? <>Hey, {name}</> : <>not added</>}</p></div>
            </div>
        </div>
    )
}
