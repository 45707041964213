import React from 'react'
import Locationpage from '../components/home/locationpage'

export default function Homelocation() {
  return (
  <>
    <Locationpage />
  </>
  )
}
