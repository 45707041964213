import React from 'react'
import { Link } from 'react-router-dom'
import House from '../../assets/images/house.png'
import './gethelp.css'
import usericon from '../../assets/images/Maskgroup3.png'
import Next from '../../assets/images/next.png'
import Bookoing from '../../assets/images/Maskgroup1.png'
import Address from '../../assets/images/Maskgroup4.png'
import Allorder from '../../assets/images/Maskgroup2.png'
import Help from '../../assets/images/Maskgroup7.png'
import Track from '../../assets/images/Maskgroup6.png'
import Wallet from '../../assets/images/Maskgroup5.png'
import Whatsapp from '../../assets/images/whatsapp.svg'
import Call from '../../assets/images/call.svg'
import Email from '../../assets/images/mail.svg'

export default function Contactus() {
    return (
        <>
            <div className='profile-parent'>
                <div className='container'>
                    <div className='trackorder-wrap'>
                        <div className='Profile-divider' style={{ justifyContent:"center"}}>
                            <div className='gethelp-contents' >
                                <div className='gethelp-boxs'>
                                    <div className='help-texts'>
                                        <h4>Contact us</h4>
                                        <p>Please get in touch and we will be 
                                            happy to help you
                                        </p>
                                    </div>

                                </div>

                                <div className='contacts'>

                                   <a href="https://wa.me/+919117770107">
                                    
                                    <div className='contact1'>
                                        <button> <img src={Whatsapp} width="25px" style={{marginRight:'15px'}} alt='' /> Whats app us</button>
                                    </div></a> 
                                    
                                    <a href="tel:+919117770107">
                                    <div className='contact1'>
                                    <button><img src={Call}  width="25px" style={{marginRight:'15px'}}  alt=''/>Call us now</button>
                                    </div></a>
                                    
                                    <a href="mailto:hello@saloease.in"><div className='contact1'>
                                    <button><img src={Email}  width="25px" style={{marginRight:'15px'}} alt=''/>Email us</button>
                                    </div></a>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
