import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  incrementQuantity,
  decrementQuantity,
} from '../../utils/cartSlice'; // Replace with the correct import path for your cart slice
import { setsubService, setsubNo } from '../../utils/orderSlice'; // Replace with the correct import path for your cart slice

import Time from '../../assets/images/timer.png';
import NewserviceModal from './newserviceModal';
import { toast } from 'react-toastify';
import { selectTotalCost, selectTotalTime } from '../../utils/cartSlice';
const ServiceCard = ({ service }) => {
  const dispatch = useDispatch();
  const totalTime = useSelector(selectTotalTime);
  const splitTime = totalTime.split(' ')[0];

  const cartProducts = useSelector(state => state.cart.products);
  const isProductInCart = cartProducts.some(
    product => product._id === service._id
  );
  const [count, setCount] = useState(
    isProductInCart
      ? cartProducts.find(product => product._id === service._id).quantity
      : 1
  );

  const handleAddToCart = e => {
    if (splitTime <= 6) {
      dispatch(addToCart(service));
      setCount(1); // Reset the count to 1 when adding to cart
      toast.success('Successfully added in cart 🥳!', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
    } else {
      toast.error('You have reached the time limit of 7 hours!', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      return;
    }
  };

  const handleIncrement = () => {
    dispatch(incrementQuantity({ productId: service._id }));
    if (count !== 3) {
      setCount(count + 1);
    }
  };

  const handleDecrement = () => {
    dispatch(decrementQuantity({ productId: service._id }));
    setCount(count - 1);
  };

  // new sevice modal

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [servData, setServData] = useState([]);

  const openPopup = service => {
    setIsPopupOpen(true);
    setServData(service);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setServData([]);
  };

  // Split the description into individual services
  const services = service?.description
    .split(', ')
    .map(service => service.trim());

  // Define the template for each service item
  const serviceTemplate = service => <li key={service}>{service}</li>;

  // your existing code
  const [subServices, setSubServices] = useState(
    service.subService.map(sub => ({ name: sub, checked: false }))
  );

  const handleCheckboxChange = index => {
    const checkedItemsCount = subServices.filter(sub => sub.checked).length;

    const test = [];
    if (checkedItemsCount < service.subNo || subServices[index].checked) {
      const updatedSubService = subServices.map((sub, i) =>
        i === index ? { ...sub, checked: !sub.checked } : sub
      );
      setSubServices(updatedSubService);
      test.push(...updatedSubService);
    } else if (subServices[index].checked) {
      const updatedSubService = subServices.map((sub, i) =>
        i === index ? { ...sub, checked: false } : sub
      );
      setSubServices(updatedSubService);
      test.push(...updatedSubService);
    }

    if (
      checkedItemsCount === service.subNo - 1 &&
      !subServices[index].checked
    ) {
      dispatch(setsubService({ test }));
      dispatch(setsubNo(service.subNo));
    }
  };

  return (
    <div className="service-card" id="SalonEase" style={{ margin: '8px 0px' }}>
      <div className="service-box1">
        <div className="card-details1">
          <h3>{service.service_name}</h3>
          {service.description === 'na' ? null : (
            <ul style={{ paddingTop: '10px' }}>
              {services.map(serviceTemplate)}
              <span
                style={{ color: '#981313', cursor: 'pointer' }}
                onClick={() => {
                  openPopup(service);
                }}
              >
                <u>more details</u>
              </span>

              <NewserviceModal
                isOpen={isPopupOpen}
                onClose={closePopup}
                data={servData}
              />
            </ul>
          )}

          {service.subNo > 0 ? (
            <>
              {subServices.map((sub, index) => (
                <div className="package-list" key={index}>
                  <label>
                    <input
                      type="checkbox"
                      checked={sub.checked}
                      onChange={() => {
                        handleCheckboxChange(index);
                      }}
                    />

                    <p>{sub.name}</p>
                  </label>
                </div>
              ))}
            </>
          ) : null}
        </div>
        <div
          className="card-details2"
          style={{ backgroundImage: `url(${service.image})` }}
        >
          <div className="img-lable">
            <img
              src={Time}
              width="14px"
              height="14px"
              alt={`${service.service_name} services at home`}
            />
            <p>{service.time}</p>
          </div>
        </div>
      </div>
      <div className="service-box2">
        <div className="card-details3">
          {service.tags.length > 0 ? (
            <div className="btn-grp">
              {service?.tags.map((tag, index) => (
                <span key={index}> {tag}</span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="card-details4">
          <p>
            {' '}
            <del className="mx-1" style={{ color: '#989494' }}>
              ₹{parseInt(service.actual_price)}/-{' '}
            </del>
            <span className="success mx-1"> ({service.discount}% off) </span> ₹
            {parseInt(service.price)}
          </p>
        </div>
        <div className="card-details5">
          <div className="add-btn">
            {isProductInCart ? (
              <div className="increment-decrement-buttons">
                <span onClick={handleDecrement}>-</span>
                <span className="counter">{count}</span>
                <span onClick={handleIncrement}>+</span>
              </div>
            ) : (
              <>
                {service.subNo > 0 ? (
                  <>
                    <button
                      onClick={() => {
                        const checkedItemsCount = subServices.filter(
                          sub => sub.checked
                        ).length;
                        if (checkedItemsCount >= service.subNo) {
                          handleAddToCart();
                        } else {
                          toast.warning(
                            'Please select all services to add to cart.',
                            {
                              position: 'top-center',
                              autoClose: 2500,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              theme: 'light',
                            }
                          );
                        }
                      }}
                    >
                      ADD TO CART
                    </button>
                  </>
                ) : (
                  <button onClick={handleAddToCart}>ADD TO CART</button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
