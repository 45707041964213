import React, { useState, useEffect } from 'react';
import './loginpage.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";


export default function LoginPage() {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [isloding, setIsLoding] = useState(true)
    // console.log(phone);

    const handleFindUser = () => {
        setIsLoding(false)
        // Add "+91" to the beginning of the phone number
        const formattedPhoneNumber = "+91" + phone;
        axios
            .post(`${serverAPI}/auth/find-user`, { phone: formattedPhoneNumber }) // Use the formatted phone number in the request
            .then((response) => {
                if (response.status === 200) {
                    // User exists, run the login API
                    axios.post(`${serverAPI}/auth/login`, { phone: formattedPhoneNumber }); // Use the formatted phone number in the request

                    toast.success("OTP Sent Succesfully 😃!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setIsLoding(true)
                        // Now navigate to the login-otp page with the formatted phone number in state
                        navigate('/login-otp', { state: { phone: formattedPhoneNumber, login: true } });
                    }, 2500);
                } else if (response.status === 201) {
                    toast.success("OTP Sent Succesfully 😃!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setIsLoding(true)
                        // Now navigate to the login-otp page with the formatted phone number in state
                        navigate('/login-otp', { state: { phone: formattedPhoneNumber, login: false } });
                    }, 2500);
                }
            })
            .catch((error) => {
                toast.error("Somthing went worng 😢!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            });
    };

    return (
        <>
            <div className='container'>
                <div className="mobilelogin">
                    <div className="LOGIN-body">
                        <h3>Login / Signup to continue</h3>
                        <form >
                            <div className="phone-input">
                                <span className="prefix">+91</span>
                                <input
                                    type="number"
                                    id="phoneNumber"
                                    placeholder='Enter your phone number'
                                    maxlength="10"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </form>
                        <label>
                            <input type='checkbox' />
                            <span>Get order updates and offers on whatsapp</span>
                        </label>

                        <h4>By continiuing, I agree to the <span>Term of Use</span> & <span> Privacy Policy </span></h4>
                        <Link ><button onClick={handleFindUser} className="login-btn">{isloding? <>CONTINUE </> : <BeatLoader color="#ffffff" size={10}/>}</button></Link>
                        <h5>Having trouble logging in?  <span> Gett help</span> </h5>
                    </div>
                </div>
            </div>
        </>
    )
}
