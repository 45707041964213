import React, { useState, useEffect } from 'react'
import orderimg from '../../assets/images/v682_250.png'
import Clock from '../../assets/images/clock.png'
import './currentbooking.css'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";

import Swal from 'sweetalert2';

export default function CartAllComp({ appointment, index }) {
    const [isConfirm, setIsConfrim] = useState(true)
    const navigate = useNavigate();

    const updateCartAndNavigate = async (cart) => {
        setIsConfrim(false);

        const result = await Swal.fire({
            title: 'Confirm Booking',
            text: 'Are you sure want to Book again this appointment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#981313',
            cancelButtonColor: '#38AE36',
            confirmButtonText: 'Yes, Book agin!',
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        if (result.isConfirmed) {
            // Remove the cart time from local storage
            localStorage.removeItem('cart');

            // Set the cart with the provided variable
            localStorage.setItem('cart', JSON.stringify(cart));
            // Perform navigation to '/cart' after successfully setting the cart
            navigate(`/cart`);
            setIsConfrim(true);
            window.location.reload();
        }else{
            setIsConfrim(true);
        }
    }
    // Function to convert time in the format "hh h mm min" to minutes
    const convertTimeToMinutes = (time) => {
        const [hours, minutes] = time.split("h");
        return parseInt(hours) * 60 + parseInt(minutes);
    };

    let totalMinutes = 0;

    // Iterate through each order and each item in the cart and add up the times

    appointment.cart.forEach((item) => {
        const time = item.time;
        totalMinutes += convertTimeToMinutes(time);
    });


    // Convert the total minutes back to "h hours m minutes" format
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const totalTime = hours + " h " + minutes + " min";
    return (
        <>
            <div className='recentorder' key={index} >
                <div className='card-head'>
                    <p className='parah1'>Booking no. - {appointment.orderID}</p>
                    <p className='parah2'>{appointment.appointmentCencel ? <> Booking Cancelled </> : <>Slot - {appointment.date + ", " + appointment.time}</>}</p>
                </div>
                {appointment.cart.map((service, serviceIndex) => (
                    <div className='current-body' key={serviceIndex}>
                        <div className='ordr-dtl'>
                            <div className="odr-img">
                                <img src={service.image} alt={`Service ${serviceIndex + 1}`} />
                            </div>
                            <div className='ord-text'>
                                <div className='ord_details '>
                                    <div className='ord_box1'>
                                        <h4>{service.service_name}</h4>
                                        {
                                            service?.subNo > 0 ?
                                                <>
                                                    <ul>
                                                        {appointment?.subService
                                                            ?.filter(item => item.checked === true)
                                                            .map((item, index) => (
                                                                <li key={index}>{item.name}</li>
                                                            ))}
                                                    </ul>

                                                </> : null
                                        }
                                    </div>
                                    <div className='odr-multiple'>
                                        <h6 > × {service.quantity}</h6>
                                    </div>
                                </div>
                                <div className='ord-rate'>
                                    <div className='rate1'>
                                        <h5> ₹ {parseInt(service.price)}/-</h5>
                                    </div>
                                    <div className='rate2'>
                                        <h4><img src={Clock} height='15px' width='15px' style={{ marginTop: '-3px', marginRight: '5px' }} alt='' />{service.time}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className='order-btn'>
                    <h3>Total service time : {totalTime}</h3>
                    <h4>Total Ammount : ₹ {parseFloat(appointment?.paymentDetails.payableAmount).toFixed(2)}</h4>
                    <button onClick={()=>{updateCartAndNavigate(appointment.cart)}}> {isConfirm ? <>Book again</> : <BeatLoader color="#ffffff" size={10} />}</button>
                    {/* <button>Details</button> */}
                </div>
            </div>

        </>
    )
}
