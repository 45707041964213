import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './wallet.css'
import Next from '../../assets/images/next.png'
import Salonwallet from '../../assets/images/wallet.png'
import ProfileHeder from './profileHeder'
import ProfileMenu from './ProfileMenu'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import LoginModal from '../login/loginModal'
import Wallet from '../../assets/images/wallet2.png'
export default function SalonWallet() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const [walletHistory, setWalletHistory] = useState([])
    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });
            if (res.status === 200) {
                const userData = res.data.user;
                setUserData(userData);
                setWalletHistory(userData.walletHistory)
                setPageLoaded(false);
            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };
    useEffect(() => {
        getRootUser();
    }, []);
    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };


    const closePopups = () => {
        navigate('/')
        setIsPopupOpens(false);
    };
    return (
        <>{
            pageLoaded ?
                <>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                        <HashLoader
                            color="#93294B"
                            size={50}
                        />

                    </div>
                    <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

                </>
                :
                <div className='profile-parent'>
                    <div className='container'>

                        <div className='trackorder-wrap'>
                            <ProfileHeder name={userData?.name} phone={userData?.phone} address={getFormattedAddress()} />


                            <div className='Profile-divider'>
                                <ProfileMenu />

                                <div className='wallet-content'>

                                    <div className='wallet-box'>
                                        <div className='wallet-text' style={{ borderBottom: '2px solid #e0e0e0' }}>
                                            <div className='wallet-box2'>
                                                <div className='wallet-inner'>
                                                    <img src={Salonwallet} />
                                                    <h4>SalonEase <br /> Wallet</h4>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='wallet-text'>
                                            <div className='wallet-box1'>
                                                <h3>Your Wallet Balance</h3>
                                                <h5>₹ {parseFloat(userData?.Wallet).toFixed(2)}</h5>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div className='history1' style={{ marginTop: '30px' }}>
                                        <div className='help-next'>
                                            <h3>Credit History</h3>
                                            <img src={Next} width="20px" />
                                        </div>
                                    </div>

                                    <div className='history1'>
                                        <div className='help-next'>
                                            <h3>Transaction History</h3>
                                            <img src={Next} width="20px" />
                                        </div>
                                    </div> */}

                                    <div className='history'>
                                        <h3>
                                            <img src={Wallet} width='20px' style={{ marginRight: '10px' }} />
                                            SE wallet transaction history
                                        </h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date & Time</th>
                                                    <th>Description</th>
                                                    <th>Debit/Credit</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {walletHistory.slice().reverse().map((transaction, index) => (
                                                    <tr
                                                        key={index}
                                                        className={index % 2 === 0 ? 'gray' : ''}
                                                    >
                                                        <td>{transaction.dateTime}</td>
                                                        <td>{transaction.description}</td>
                                                        <td
                                                            className={transaction.typeOfTransaction === 'Debit' ? 'danger' : 'success'}
                                                        >
                                                            {transaction.typeOfTransaction === 'Debit' ? `- ₹ ${transaction.amount}` : `+ ₹ ${transaction.amount}`}
                                                        </td>
                                                        <td>
                                                            <span>₹ {transaction.balance}</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='wallet-note'>
                                        <div className='note'>
                                            <h3>Note :</h3>
                                            <ul>
                                                <li>
                                                    Credit can't be cancelled or transferred to another account.
                                                </li>
                                                <li>Credits have an expiry.</li>
                                                <li>It can't be withdrawn in the form of cash or transferred to any bank account.</li>
                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        }
        </>
    )
}
