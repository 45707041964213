import React from 'react'
import Footer from '../layouts/footer'
import Query from '../components/profilepage/query'
import Homeheader from '../layouts/home_header'

export default function RaiseQuery() {
    return (
        <>
            <Homeheader />
            <Query />
            <Footer />
        </>
    )
}
