import React from 'react'
import Servicepage from '../components/booking/servicepage'

export default function Cardservice() {
  return (
   <>
      <Servicepage />
   </>
  )
}
