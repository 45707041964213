import React from 'react'
import Header from '../layouts/header'
import CartPayment from '../components/cart/cartpayment'
import Homeheader from '../layouts/home_header'

export default function Payment() {
  return (
    <>
       <Homeheader />
       <CartPayment />
    </> 
 )
}
