import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './review_card.css'
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const Testimonial = ({ reviews }) => {
    const getStarColor = (index) => {
        const rating = reviews[index].rating;
        if (rating >= 1) {
            return "rgb(255, 215, 0)"; // Yellow color for rating 4 or higher
        } else {
            return "rgb(192, 192, 192)"; // Gray color for rating less than 4
        }
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
       <>
            <Slider {...sliderSettings}>
                {reviews.map((review, index) => (
                    <div key={index}>
                        <div className='review_card_box'>
                            <div className='review_card_profile'>
                                <div className='review_card_img'>
                                    <img src={review.profile_photo_url} alt="Profile" />
                                </div>
                                <div className='review_card_auth'>
                                    <h5><a target='blank' href={review.author_url}> {review.author_name}</a></h5>
                                    <p>{review.relative_time_description}</p>
                                </div>
                            </div>
                            <div className='str'>
                                {[...Array(5)].map((item, starIndex) => (
                                    <span className="rating_" key={starIndex}>
                                        {review.rating >= starIndex + 1 ? (
                                            <FaStar color={getStarColor(index)} />
                                        ) : review.rating >= starIndex + 0.5 ? (
                                            <FaStarHalfAlt color={getStarColor(index)} />
                                        ) : (
                                            <FaStar color="rgb(192, 192, 192)" />
                                        )}
                                    </span>
                                ))}
                            </div>
                            <div className='review_card_review'>
                                <p>{review.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
       </>
    );
};

export default Testimonial;

