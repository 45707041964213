// UserContext.js
import React, { createContext, useContext, useState, useEffect , useRef} from 'react';
import axios from 'axios';
import { serverAPI } from '..';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isFirstCallCheckUser = useRef(true);

useEffect(() => {
  // Function to check user data
  const checkLoggedInUser = async () => {
    try {
      const response = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        const userData = response.data.user;
        setUserData(userData);
        setIsLoading(false);
      } else {
        // If the user is not logged in, set userData to null
        setUserData(null);
      }
    } catch (error) {
      // Handle errors here
      localStorage.removeItem('user');
      localStorage.removeItem('address');
      localStorage.removeItem('jwt');
    }
  };

  if (isFirstCallCheckUser.current) {
    isFirstCallCheckUser.current = false; // Set the flag to false after the first call
    checkLoggedInUser(); // Initial call to checkLoggedInUser
  }
}, []);

  return (
    <UserContext.Provider value={{ userData, isLoading }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
