import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ServiceHead from '../layouts/serviceHead';
import Servicelist from '../components/booking/servicelist';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { serverAPI } from '..';

export default function Booking() {
  const location = useLocation();

  // Extract the part of the URL after "/services/"
  const urlPath = location.pathname.split('/')[2]; // This will extract "create-your-own-package" from "/services/create-your-own-package"

  // Capitalize the first letter of each word in the URL path
  const formattedName = urlPath
    ? urlPath
        .replace(/-/g, ' ') // Replace hyphens with spaces
        .split(' ') // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' ') // Join the words back into a string
    : '';

  const queryParams = new URLSearchParams(location.search);
  const serviceName = queryParams.get('name');
  const catId = queryParams.get('id');
  const [categories, setCategories] = useState([]);
  const [city, setCity] = useState('');

  const getCategories = () => {
    axios
      .get(`${serverAPI}/categories`)
      .then(response => {
        const filteredCategories = response.data.filter(
          category => category._id === location?.state?._id
        );
        setCategories(filteredCategories.length > 0 ? filteredCategories : []);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  };

  useEffect(() => {
    getCategories();
  }, [location]);

  return (
    <>
      <Helmet>
        {/* Dynamically set the title based on the URL */}
        <title>{formattedName} Services at Home | Salon Ease</title>
        {categories.length > 0 && (
          <meta
            name="keywords"
            content={`Salon Ease salon services at home, ${categories[0].name}`}
          />
        )}
      </Helmet>

      <ServiceHead />
      <Servicelist />
    </>
  );
}
