import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './manageaddress.css'
import AddressMap from './AddressMap'
import LoginModal from '../login/loginModal'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import ProfileHeder from './profileHeder'
import ProfileMenu from './ProfileMenu'
import Noaddress from '../../assets/images/no_address.png'

export default function Manageaddress() {
    // const { userData, isLoading } = useUser();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });
            if (res.status === 200) {
                const userData = res.data.user;
                setUserData(userData);
                setPageLoaded(false);
            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };
    useEffect(() => {
        getRootUser();
    }, []);
    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };

    const closePopups = () => {
        navigate('/')
        setIsPopupOpens(false);
    };

    return (
        <> {
            pageLoaded ?
                <>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                        <HashLoader
                            color="#981313"
                            size={50}
                        />

                    </div>
                    <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

                </>
                :
                <div className='profile-parent'>
                    <div className='container'>

                        <div className='currentbooking-wrap'>
                            <ProfileHeder name={userData?.name} phone={userData?.phone} address={getFormattedAddress()} />


                            <div className='Profile-divider'>
                                <ProfileMenu />
                                <div className='current-content'>
                                    {
                                        userData?.address?.home?.length > 0 || userData?.address?.other?.length > 0 ? <div className='address-top'>
                                            <p>Saved Addresses</p>
                                            <Link to='/newaddress'>+ Add new address</Link>
                                        </div> : null
                                    }

                                    {/* <h3>Home ADDRESS</h3>
                                <div className='add1'>
                                    <div className='add-heading'>
                                        <p>Gourav raj</p>
                                        <div className='lable'>HOME</div>
                                    </div>
                                    <div className='add-list'>
                                        <ul>
                                            <li>Jane Doe, 4a bella kasa, church road</li>
                                            <li>Hatia (Ranchi)</li>
                                            <li>Ranchi - 834003</li>
                                            <li>Jharkhand</li>
                                        </ul>
                                        <p>Mobile: 8603662315</p>
                                    </div>
                                    <div className='add1-btn'>
                                        <button>EDIT</button>
                                        <button>REMOVE</button>
                                    </div>
                                </div>

                                <h3>Other ADDRESS</h3>
                                <div className='add1'>
                                    <div className='add-heading'>
                                        <p>Gourav raj</p>
                                        <div className='lable'>OTHER</div>
                                    </div>
                                    <div className='add-list'>
                                        <ul>
                                            <li>Jane Doe, 4a bella kasa, church road</li>
                                            <li>Hatia (Ranchi)</li>
                                            <li>Ranchi - 834003</li>
                                            <li>Jharkhand</li>
                                        </ul>
                                        <p>Mobile: 8603662315</p>
                                    </div>
                                    <div className='add1-btn'>
                                        <button>EDIT</button>
                                        <button>REMOVE</button>
                                    </div>
                                </div> */}
                                    {
                                        userData?.address?.home?.length > 0 || userData?.address?.other?.length > 0 ?

                                            <AddressMap homeAddresses={userData?.address.home} otherAddresses={userData?.address.other} />
                                            :
                                            <div className='noaddress'>
                                                <div className='address_img' >
                                                    <img src={Noaddress} alt='' />
                                                </div>
                                                <h3>SAVE YOUR ADDRESSES NOW</h3>
                                                <p>Add your home and office addresses and enjoy faster service</p>
                                                <div className='order-btn' style={{ height: 0, marginBottom: 0, marginTop: 0, display: "flex", justifyContent:"center"}}>
                                                <Link to='/newaddress'>  <button
                                                    style={{float: 'none', marginTop: "10px", marginRight: '0px', backgroundColor: 'white', color: '#981313', border: '1.5px solid'
                                                        , width: "200px", fontSize: "16px"
                                                    }}
                                                    
                                                    >+ Add new address</button> </Link>
                                                </div>
                                            </div>
                                    }
                                </div>

                                {/* address black */}

                                {/*  */}

                            </div>
                        </div>

                    </div>
                </div>
        }
        </>
    )
}
