import React, { useEffect, useState, useRef } from 'react';
import cancel from '../../assets/images/cancel.png';
import './logcom.css';
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
import BeatLoader from 'react-spinners/BeatLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import back from '../../assets/images/arrow-left.png';
import Pen from '../../assets/images/pen.png';

export default function OTPmodal({ isOpen, onClose, number, whatsappupdate }) {
  const popupClass = isOpen ? 'popup open' : 'optpopup';
  const location = useLocation();
  const { pathname } = useLocation();
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();
  const [isloding, setIsLoding] = useState(true);
  const [islodings, setIsLodings] = useState(true);

  const phoneNumber = number; // Get the phone number from the state
  const loginState = location.state?.login || '';
  // Create refs for the input fields
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  // Initialize state for the OTP value
  const [otpValue, setOtpValue] = useState(['', '', '', '']);
  useEffect(() => {
    // Calculate the OTP string
    const otp = otpValue.join('');

    // Check if the OTP length is 4
    if (otp.length === 4) {
      handleLogin();
    }
  }, [otpValue]);
  // Function to focus on the next input field
  const focusNextInput = index => {
    if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  // Function to focus on the previous input field
  const focusPreviousInput = index => {
    if (index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  // Function to handle input change
  const handleInputChange = (e, index) => {
    const value = e.target.value;

    // Use a regular expression to ensure only one digit
    const oneDigitValue = value.replace(/[^0-9]/g, '');

    // Limit the total length to 4 characters
    if (oneDigitValue.length <= 1) {
      // Set the input value in the state
      const updatedOtpValue = [...otpValue];
      updatedOtpValue[index] = oneDigitValue;
      setOtpValue(updatedOtpValue);

      // Add or remove the 'otpactive' class based on the input value
      const updatedInputRefs = [...inputRefs];
      if (oneDigitValue.length > 0) {
        updatedInputRefs[index].current.classList.add('otpactive');
      } else {
        updatedInputRefs[index].current.classList.remove('otpactive');
      }

      // Focus on the next input
      focusNextInput(index);
    }
  };

  // Function to handle keydown event
  const handleKeyDown = (e, index) => {
    // Check if the backspace key is pressed and the input is empty
    if (e.keyCode === 8 && otpValue[index] === '') {
      // Clear the previous input and focus on it
      const previousIndex = index - 1;
      if (previousIndex >= 0) {
        const updatedOtpValue = [...otpValue];
        updatedOtpValue[previousIndex] = '';
        setOtpValue(updatedOtpValue);
        inputRefs[previousIndex].current.focus();
      }
    }
  };
  // Function to handle paste event
  const handlePaste = e => {
    // Prevent default paste behavior
    e.preventDefault();

    // Get the pasted text
    const pastedText = e.clipboardData.getData('text/plain');

    // Extract digits from the pasted text
    const digits = pastedText.match(/\d/g);

    // Check if there are exactly 4 digits
    if (digits && digits.length === 4) {
      // Use setTimeout to allow the paste event to complete
      setTimeout(() => {
        // Clear all input fields first
        const updatedOtpValue = ['', '', '', ''];

        // Distribute the digits into the input fields
        for (let i = 0; i < inputRefs.length && i < digits.length; i++) {
          const inputRef = inputRefs[i];
          const digit = digits[i];
          inputRef.current.value = digit;
          updatedOtpValue[i] = digit; // Update the state
        }

        // Update the state with the new OTP value
        setOtpValue(updatedOtpValue);
      }, 0);
    }
  };

  const [counter, setCounter] = useState(1 * 60 ); // Initial value in seconds (4 minutes and 59 seconds)

  useEffect(() => {
    if (isOpen) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

      return () => clearInterval(timer);
    }

    const loginget = JSON.parse(localStorage.getItem('login'));
    if (loginget) {
      // Assuming you have a setLogin state variable
      setLogin(true);
    }
  }, [counter, isOpen]);

  const handleLogin = () => {
    setIsLoding(false);
    const otp = otpValue.join('');

    axios
      .post(
        `${serverAPI}/auth/verify-otp`,
        { phone: phoneNumber, otp: otp, whatsapp: whatsappupdate },
        {
          withCredentials: true, // Include credentials (cookies)
        }
      ) // Use the formatted phone number in the request
      .then(response => {
        if (response.status === 200) {
          // User exists, run the login API
          // let token = res.data.access;
          localStorage.setItem('jwt', JSON.stringify(response.data.token));
          toast.success('Login Succesfully 😃!', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          });

          const getLogin = JSON.parse(localStorage.getItem('login'));
          if (getLogin === true) {
            setIsLoding(true);
            navigate('/profile_edit');
            localStorage.removeItem('login');
          } else {
            setIsLoding(true);
            navigate('/');
            if (pathname === '/profile') {
              window.location.reload();
            }
            window.location.reload();
          }
        } else {
          toast.error('Invalid otp or expired try again 😢!', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          });
          setIsLoding(true);
        }
      })
      .catch(error => {
        setIsLoding(true);
        toast.error('Invalid otp or expired try again 😢!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });
      });
  };

  const resendOTP = () => {
    setOtpValue(['', '', '', '']);
    setCounter(1 * 60 + 59);
    axios
      .post(`${serverAPI}/auth/resend-otp`, { phone: phoneNumber }) // Use the formatted phone number in the request
      .then(response => {
        if (response.status === 200) {
          // User exists, run the login API
          toast.success('OTP Sent Succesfully 😃!', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          });
          setIsLoding(true);
        } else {
          toast.success('OTP Sent Fail, please try again 😢!', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          });
          setIsLoding(true);
        }
      })
      .catch(error => {
        setIsLoding(true);
        console.error('Error finding or logging in user:', error);
      });
  };

  const editPhone = () => {
    setIsLodings(false);

    axios
      .delete(`${serverAPI}/auth/user/findfirst/${phoneNumber}`, {
        withCredentials: true, // Include credentials (cookies)
      }) // Use the formatted phone number in the request
      .then(response => {
        if (response.status === 200) {
          // User exists, run the login API
          setTimeout(() => {
            setIsLodings(true);
            onClose();
          }, 1000);
        } else if (response.status === 201) {
          // User exists, run the login API
          setTimeout(() => {
            setIsLodings(true);
            onClose();
          }, 1000);
        } else {
          setIsLodings(true);
          onClose();
        }
      });
  };

  return (
    <>
      <div className={popupClass}>
        <div className="popup-content">
          <div className="cancel-btn">
            <img src={cancel} onClick={onClose} alt="" />
          </div>
          <div className="popup-body">
            <div className="mobile_nav mobileact">
              <div
                className="back-nav"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '12%',
                  }}
                  onClick={() => {
                    if (pathname === '/') {
                      onClose(); // Run closePopup function
                    } else {
                    
                      navigate(-1); // Navigate back
                    }
                  }}
                >
                  <img
                    src={back}
                    width="20px"
                    alt=""
                    style={{ marginLeft: '16px' }}
                  />
                </div>
                <div>
                  <h1
                    style={{ fontSize: '20px', margin: '0px', padding: '0px' }}
                  >
                    OTP
                  </h1>
                </div>
              </div>
            </div>
            <div className="sapcse">
              <h3>Enter verification code</h3>

              <p>
                We have send you a 4 digit otp <br /> on {`+91 ${number}`}{' '}
                {islodings ? (
                  <img
                    src={Pen}
                    width="14px"
                    style={{ marginLeft: '10px' }}
                    alt=""
                    onClick={() => {
                      editPhone();
                    }}
                  />
                ) : (
                  <BeatLoader
                    color="#981313"
                    size={10}
                    style={{ marginLeft: '10px' }}
                  />
                )}{' '}
              </p>

              <div className="otp-inputs">
                <input
                  type="text"
                  className={`otp-input ${otpValue[0] ? 'otpactive' : ''}`}
                  maxLength="1"
                  ref={inputRefs[0]}
                  value={otpValue[0]}
                  onChange={e => handleInputChange(e, 0)}
                  onKeyDown={e => handleKeyDown(e, 0)}
                  onPaste={handlePaste} // Handle paste event
                  inputMode="numeric"
                />
                <input
                  type="text"
                  className={`otp-input ${otpValue[1] ? 'otpactive' : ''}`}
                  maxLength="1"
                  ref={inputRefs[1]}
                  value={otpValue[1]}
                  onChange={e => handleInputChange(e, 1)}
                  onKeyDown={e => handleKeyDown(e, 1)}
                  inputMode="numeric"
                />
                <input
                  type="text"
                  className={`otp-input ${otpValue[2] ? 'otpactive' : ''}`}
                  maxLength="1"
                  ref={inputRefs[2]}
                  value={otpValue[2]}
                  onChange={e => handleInputChange(e, 2)}
                  onKeyDown={e => handleKeyDown(e, 2)}
                  inputMode="numeric"
                />
                <input
                  type="text"
                  className={`otp-input ${otpValue[3] ? 'otpactive' : ''}`}
                  maxLength="1"
                  ref={inputRefs[3]}
                  value={otpValue[3]}
                  onChange={e => handleInputChange(e, 3)}
                  onKeyDown={e => handleKeyDown(e, 3)}
                  inputMode="numeric"
                />
              </div>

              {counter === 0 ? (
                <>
                  {' '}
                  <div
                    onClick={resendOTP}
                    className="resend"
                    style={{ cursor: 'pointer' }}
                  >
                    <h6 style={{ color: '#981313' }}> Resend OTP</h6>
                  </div>{' '}
                </>
              ) : (
                <>
                  {' '}
                  <div className="resend">
                    <h6 style={{ color: '#981313' }}>
                      Resend OTP in {Math.floor(counter / 60)}:
                      {(counter % 60).toString().padStart(2, '0')}
                    </h6>
                  </div>
                </>
              )}

              <button
                id="login-button"
                onClick={handleLogin}
                className="login-btn"
              >
                {' '}
                {isloding ? (
                  <>LOGIN </>
                ) : (
                  <BeatLoader color="#ffffff" size={10} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
