import React from 'react'
import LoginPage from '../components/login/loginpage'
import LoginHead from '../layouts/login_head'

export default function MobileLogin() {
  return (
    <>
       <LoginHead />
       <LoginPage />
    </>
  )
}
