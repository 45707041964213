import React from 'react'
import Footer from '../layouts/footer'
import SalonWallet from '../components/profilepage/wallet'
import Homeheader from '../layouts/home_header'

export default function UserWallet() {
    return (
        <>
            <Homeheader />
            <SalonWallet />
            <Footer />
        </>
    )
}
