import React from 'react';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';
import icon5 from '../../assets/images/icon5.png';
import icon6 from '../../assets/images/icon6.png';
import icon7 from '../../assets/images/icon7.png';
import icon8 from '../../assets/images/icon8.png';
import icon9 from '../../assets/images/icon9.png';
import Check from '../../assets/images/check.png';

export default function OrderStatus({appointment}) {
    // const orderStatuses = [
    //     {
    //         status: "Order placed",
    //         description: "Order Placed on 14 Aug"
    //     },
    //     {
    //         status: "Order Processing",
    //         description: "We are processing your order"
    //     },
    //     {
    //         status: "Service Scheduled",
    //         description: "Scheduled for chosen time 17th Aug 12:00 PM"
    //     },
    //     {
    //         status: "Beautician Assigned",
    //         description: "Shiwani singh assigned for your booking"
    //     },
    //     {
    //         status: "Beautician is on the way",
    //         description: "Estimated arrival of beautician 11:43 AM"
    //     },
    //     {
    //         status: "Service in Progress",
    //         description: "Service is started at 17th Aug 12:00 PM"
    //     },
    //     {
    //         status: "Service completed",
    //         description: "Service completed at 02:43 PM"
    //     },
    //     {
    //         status: "Payment and Billing",
    //         description: "Payment due of 2350"
    //     },
    //     {
    //         status: "Order Completed",
    //         description: "Order Completed on 14 Aug"
    //     },
    // ];
    const orderStatuses = appointment.appointmentStatus;
    const isOrderplaced = orderStatuses.some((statusObj) => statusObj.status === "Order placed");
    const isOrderProcessing = orderStatuses.some((statusObj) => statusObj.status === "Order Processing");
    const isServiceScheduled = orderStatuses.some((statusObj) => statusObj.status === "Service Scheduled");
    const isBeauticianAssigned = orderStatuses.some((statusObj) => statusObj.status === "Beautician Assigned");
    const isBeauticianisontheway = orderStatuses.some((statusObj) => statusObj.status === "Beautician is on the way");
    const isServiceinProgress = orderStatuses.some((statusObj) => statusObj.status === "Service in Progress");
    const isServicecompleted = orderStatuses.some((statusObj) => statusObj.status === "Service completed");
    const isPaymentandBilling = orderStatuses.some((statusObj) => statusObj.status === "Payment and Billing");
    const isOrderCompleted = orderStatuses.some((statusObj) => statusObj.status === "Order Completed");

    return (
        <div>
            <div className='track-menu'>
                <div className='track1'>
                    <img src={icon1} alt='' />
                    <div className='track1-indicator' id={`${isOrderplaced ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Order placed <br />
                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Order placed")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Order placed")?.description: <br/>}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isOrderplaced ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon2} alt='' />
                    <div className='track1-indicator ' id={`${isOrderProcessing ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Order Processing <br />
                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Order Processing")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Order Processing")?.description : <br/> }</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isOrderProcessing ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon6} alt='' />
                    <div className='track1-indicator' style={{height:'21px !importent', width:'21px !importent'}} id={`${isServiceScheduled ? 'track_active' : ''}`} >
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Service Scheduled <br />
                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Service Scheduled")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Service Scheduled")?.description : <br/>}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isServiceScheduled ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon5} alt='' />
                    <div className='track1-indicator' id={`${isBeauticianAssigned ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Beautician Assigned <br />
                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Beautician Assigned")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Beautician Assigned")?.description : <br />}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isBeauticianAssigned ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon4} alt='' />
                    <div className='track1-indicator' id={`${isBeauticianisontheway ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Beautician is on the way <br />

                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Beautician is on the way")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Beautician is on the way")?.description : <br />}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isBeauticianisontheway ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon3} alt='' />
                    <div className='track1-indicator' id={`${isServiceinProgress ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>Service in Progress <br />
                    <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Service in Progress")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Service in Progress")?.description : <br/>}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isServiceinProgress ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon7} alt='' />
                    <div className='track1-indicator' id={`${isServicecompleted ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Service completed <br />
                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Service completed")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Service completed")?.description : <br/>}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isServicecompleted ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon8} alt='' />
                    <div className='track1-indicator' id={`${isPaymentandBilling ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>
                        Payment and Billing <br />
                        <p className='spans'>{orderStatuses.find((statusObj) => statusObj.status === "Payment and Billing")?.description ? orderStatuses.find((statusObj) => statusObj.status === "Payment and Billing")?.description : <br/>}</p>
                    </p>
                </div>

                <div className='track-line1' id={`${isPaymentandBilling ? 'track_active' : ''}`}></div>
                <div className='track1'>
                    <img src={icon9} alt='' />
                    <div className='track1-indicator' id={`${isOrderCompleted ? 'track_active' : ''}`}>
                        <img src={Check} alt='' />
                    </div>
                    <p>Order Completed </p>
                </div>
            </div>
        </div>
    );
}
