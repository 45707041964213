import React from 'react'
import Homeheader from '../layouts/home_header'
import Footer from '../layouts/footer'
import '../components/home/whychooseus.css'

export default function TremsOfUse() {
    return (
        <div>
            <Homeheader />
            <>

                <div className='why_choose_us_card'>
                    <div className="feat bg-gray pt-5 pb-5 card_pad">
                        <div className="container">
                            <div className="row">
                                <div className="section-head col-sm-12">
                                    <h4><span>Terms of Use</span> Salon Ease</h4>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>Effective Date: 17 Aug 2023</h5>
                                    <p>Welcome to Salon Ease! These Terms of Use ("Terms") establish the guidelines for your use of our application and services. By utilizing our services, you agree to adhere to these Terms. Please review them carefully.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>1. Eligibility</h5>
                                    <p>To use our services, you must be at least 18 years old. By accessing Salon Ease, you confirm that you are of legal age and have the capacity to enter into a binding contract.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>2. Service Description</h5>
                                    <p>Salon Ease offers a seamless platform for users to book premium salon services, expertly delivered in the comfort of your chosen location.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>3. User Accounts</h5>
                                    <p>Certain features may necessitate creating an account. You are responsible for safeguarding your account information and must promptly report any unauthorized access.</p>
                                </div>

                                <div className="col boxss col-sm-12">
                                    <h5>4. Booking and Payments</h5>
                                    <p>By making a booking through Salon Ease, you agree to comply with our booking and payment policies detailed on our platform.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>5. User Conduct</h5>
                                    <p>You commit not to:</p>
                                    <ul>
                                        <li>Utilize our services for unlawful or unauthorized purposes.</li>
                                        <li>Violate any applicable laws or regulations.</li>
                                        <li> Engage in harassment, intimidation, or harm towards other users.</li>
                                        <li>Attempt to circumvent or manipulate our platform's functionality.</li>
                                    </ul>
                                </div>
                                {/* <div className="col boxss col-sm-12">
                            <h5>Why Salon Ease?</h5>
                            <ul>
                                <li>
                                    <p><span style={{
                                        fontWeight: 900,
                                        color: 'black',
                                        fontSize: '20px'
                                    }}>Unmatched Convenience:</span> Skip the commute and long waits. Enjoy premium services in the comfort of your own space, curated just for you.
                                    </p>
                                </li>
                                <li>
                                    <p><span style={{
                                        fontWeight: 900,
                                        color: 'black',
                                        fontSize: '20px'
                                    }}>Personalized Care:</span> We know one size doesn't fit all. Our experts take the time to understand your preferences and needs, ensuring a bespoke experience every time.
                                    </p>
                                </li>
                                <li>
                                    <p><span style={{
                                        fontWeight: 900,
                                        color: 'black',
                                        fontSize: '20px'
                                    }}>Safety and Hygiene Priority:</span> With Salon Ease, you get your time back. Our team is punctual and focused on providing top-notch service within a reasonable timeframe.
                                    </p>
                                </li>
                                <li>
                                    <p><span style={{
                                        fontWeight: 900,
                                        color: 'black',
                                        fontSize: '20px'
                                    }}>Efficient Time Management:</span> Skip the commute and long waits. Enjoy premium services in the comfort of your own space, curated just for you.
                                    </p>
                                </li>
                            </ul>
                        </div> */}

                                <div className="col boxss col-sm-12">
                                    <h5>6. Intellectual Property</h5>
                                    <p>All content on Salon Ease, including logos, text, images, and software, is protected by copyright, trademark, and other laws. You may not use, reproduce, or distribute it without our written permission.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>7. Third-Party Links</h5>
                                    <p>Salon Ease may contain links to third-party websites. We do not endorse or assume responsibility for any content or services provided by third parties.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>8. Disclaimer of Warranties</h5>
                                    <p>Salon Ease provides services on an "as-is" basis. We make no warranties, express or implied, regarding the accuracy, reliability, or suitability of our services.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>9. Limitation of Liability</h5>
                                    <p>Salon Ease, its affiliates, and partners shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of our services.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>10. Indemnification</h5>
                                    <p>You agree to indemnify and hold Salon Ease, its affiliates, and partners harmless from any claims, losses, or damages arising from your use of our services.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>11. Changes to Terms</h5>
                                    <p>We may update these Terms periodically. Any changes will be posted on our platform.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>12. Termination</h5>
                                    <p>We reserve the right to suspend or terminate your account for any reason, including violation of these Terms.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>13. Governing Law</h5>
                                    <p>These Terms are governed by the laws of [Jurisdiction]. Any disputes shall be resolved in the courts of [Jurisdiction].</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>14. Contact Us</h5>
                                    <p>If you have any questions or concerns regarding our refund policy, please write us at:<br />hello@salonease.in</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>





            </>
            <Footer />
        </div>
    )
}
