import React from 'react'
import HomeHeader from '../layouts/home_header'
import Footer from '../layouts/footer'
import Gethelp from '../components/profilepage/gethelp'

export default function GetHelp() {
    return (
        <>
            <HomeHeader />
            <Gethelp />
            <Footer />
        </>
    )
}
