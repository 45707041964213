import React from 'react'
import Userprofile from '../components/profilepage/userprofile'
import HomeHeader from '../layouts/home_header'
import Footer from '../layouts/footer'

function Profile() {
  return (
  <>
      <HomeHeader />
      <Userprofile />
      <Footer />
  </>
  )
}

export default Profile