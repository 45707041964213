import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'

export default function AddressMap({ homeAddresses, otherAddresses }) {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function

  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    getRootUser();
  }, []);


  const deleteHome = async () => {
    try {
      const result = await Swal.fire({
        title: 'Confirm Deletion',
        text: 'Are you sure want to delete Home Address?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#38AE36',
        cancelButtonColor: '#981313',
        confirmButtonText: 'Yes, delete it!',
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      if (result.isConfirmed) {
        const res = await axios.delete(`${serverAPI}/auth/${userData?._id}/address/home`, {
          withCredentials: true,
        });
        if (res.status === 200) {
          Swal.fire('Deleted!', 'The home address has been deleted.', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 1990);
        } else {
          Swal.fire('Error', 'Failed to delete the home address.', 'error');
        }
      }
      Swal.hideLoading();
    } catch (error) {
      console.error('Error deleting the home address:', error);
      Swal.fire('Error', 'Failed to delete the home address.', 'error');
    }
  };

  const deleteOther = async () => {
    try {
      const result = await Swal.fire({
        title: 'Confirm Deletion',
        text: 'Are you sure want to delete Other Address?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#38AE36',
        cancelButtonColor: '#981313',
        confirmButtonText: 'Yes, delete it!',
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      if (result.isConfirmed) {
        const res = await axios.delete(`${serverAPI}/auth/${userData?._id}/address/other`, {
          withCredentials: true,
        });
        if (res.status === 200) {
          Swal.fire('Deleted!', 'The other address has been deleted.', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 1990);
        } else {
          Swal.fire('Error', 'Failed to delete the other address.', 'error');
        }
      }
      Swal.hideLoading();
    } catch (error) {
      console.error('Error deleting the other address:', error);
      Swal.fire('Error', 'Failed to delete the other address.', 'error');
    }
  };

  return (
    <div>
      {
        homeAddresses?.length > 0 ? <> <h3>Home ADDRESS</h3>
          {homeAddresses.map((address, index) => (
            <div key={index} className='add1'>
              <div className='add-heading'>
                <p>{address.name}</p>
                <div className='lable'>HOME</div>
              </div>
              <div className='add-list'>
                <div className='cat'>
                  {address?.houseFlatNumber} <br />
                  {address.address.split(',').map((part, i) => (
                    <>

                      <span key={i}>
                        {part}
                        {i === 2 ? <br /> : ','} {/* Add a line break after the third comma */}
                      </span>

                    </>

                  ))}
                  {address?.city + ', ' + address.states}
                </div>
                {
                  address.alternateNumber ? <p>Mobile: {address.alternateNumber}</p> : null
                }

              </div>
              <div className='add1-btn'>
                <button onClick={() => { navigate('/newaddress') }} style={{ cursor: "pointer" }}>EDIT</button>
                <button onClick={() => { deleteHome() }} style={{ cursor: "pointer" }}>REMOVE</button>
              </div>
            </div>
          ))}</> : null
      }


      {
        otherAddresses?.length > 0 ? <> <h3>Other ADDRESS</h3>
          {otherAddresses?.map((address, index) => (
            <div key={index} className='add1'>
              <div className='add-heading'>
                <p>{address.name}</p>
                <div className='lable'>OTHER</div>
              </div>
              <div className='add-list'>
                <div className='cat'>
                  {address?.houseFlatNumber} <br />
                  {address.address.split(',').map((part, i) => (
                    <span key={i}>
                      {part}
                      {i === 2 ? <br /> : ','} + {address.city} {/* Add a line break after the third comma */}
                    </span>
                  ))}
                  {address?.city + ', ' + address.states}

                </div>

                {
                  address.alternateNumber ? <p>Mobile: {address.alternateNumber}</p> : null
                }
                {/* <p>Mobile: {userData.phone}</p> */}

              </div>
              <div className='add1-btn'>
                <button onClick={() => { navigate('/newaddress') }} style={{ cursor: "pointer" }}>EDIT</button>
                <button onClick={() => { deleteOther() }} style={{ cursor: "pointer" }}>REMOVE</button>
              </div>
            </div>
          ))}</> : null
      }

    </div>
  )
}
