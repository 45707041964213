import React, { useEffect, useState } from 'react'
import cancel from '../../assets/images/cancel.png';
import './logcom.css'
import OTPmodal from './otpModal';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
import back from '../../assets/images/arrow-left.png'
import Search from '../../assets/images/srch.png'

export default function LoginModal({ isOpen, onClose }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [phone, setPhone] = useState('');
    const [isloding, setIsLoding] = useState(true)
    const [isChecked, setIsChecked] = useState(true); // Add state for the checkbox

    const handleFindUser = () => {
        if (phone.length !== 10) {
            toast.error("Please enter a 10-digit phone number.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            return;
        }

        setIsLoding(false)
        // Add "+91" to the beginning of the phone number
        const formattedPhoneNumber = "+91" + phone;
        axios
            .post(`${serverAPI}/auth/find-user`, { phone: phone }) // Use the formatted phone number in the request
            .then((response) => {
                if (response.status === 200) {
                    // User exists, run the login API
                    axios.post(`${serverAPI}/auth/login`, { phone: phone }); // Use the formatted phone number in the request

                    toast.success("OTP Sent Succesfully 😃!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setIsLoding(true);
                        setIsPopupOpen(true);
                    }, 2500);
                } else if (response.status === 201) {
                    toast.success("OTP Sent Succesfully 😃!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setIsLoding(true);
                        setIsPopupOpen(true);
                        localStorage.setItem("login", true);
                    }, 2500);
                }
            })
            .catch((error) => {
                toast.error("Somthing went worng 😢!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);

            });
    };
    const popupClass = isOpen ? 'popup open' : 'popup';

    useEffect(() => {
        if (isOpen) {
            // Disable scrolling on the body element when the pop-up is open
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable scrolling when the pop-up is closed
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    // otp modal
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };
    const closePopup = () => {
        setIsPopupOpen(false);
    };


    return (
        <>
            <div className={popupClass}>
                <div className='mobileact'>
                    <div className="popup-content ">
                        <div className='cancel-btn'><img src={cancel} onClick={onClose} alt='' /></div>
                        <div className="popup-body">
                            <div className="mobile_active" >
                                <div className="mobile_nav">
                                    <div className="back-nav" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                        <div style={{ display: "flex", alignItems: "center", height: "100%", width: '12%' }} onClick={() => {
                                            if (pathname === '/') {
                                                onClose() // Run closePopup function
                                            } else {
                                                navigate(-1); // Navigate back
                                            }
                                        }}>
                                            <img src={back} width="20px" alt='' style={{ marginLeft: "16px" }} />
                                        </div>
                                        <div>
                                            <h1 style={{ fontSize: "20px", margin: "0px", padding: "0px" }}>Login / Signup</h1>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ padding: "6.5rem 16px 16px 16px" }}>
                                <h3>Login / Signup to continue</h3>
                                <form htmlFor="phoneNumber">
                                    <div className="phone-input">
                                        <span className="prefix">+91</span>
                                        <input
                                            type="tel" // Use type="tel" to trigger the numeric keyboard on mobile devices
                                            id="phoneNumber"
                                            placeholder="Enter your phone number"
                                            maxLength={10}
                                            value={phone}
                                            onChange={(e) => {
                                                const inputPhone = e.target.value;
                                                if (inputPhone.length <= 10) {
                                                    setPhone(inputPhone);
                                                }
                                            }}
                                            pattern="[0-9]*" // Use pattern attribute to enforce the numeric keyboard on mobile devices
                                        />
                                    </div>
                                </form>
                                <label>
                                    <input
                                        type='checkbox'
                                        checked={isChecked} // Use the state value for the checkbox
                                        onChange={(e) => setIsChecked(e.target.checked)} // Update the state on checkbox change
                                    />                                    
                                    <span>Get order updates and offers on whatsapp</span>
                                </label>
                                <h4>By continiuing, I agree to the <span onClick={() => { navigate('/terms-conditions') }}  >Term of Use</span> & <span onClick={() => { navigate('/privacy-policy') }} style={{ cursor: "pointer" }}> Privacy Policy </span></h4>
                                <button className="login-btn" onClick={handleFindUser}>{isloding ? <>CONTINUE </> : <BeatLoader color="#ffffff" size={10} />}</button>
                            </div>


                            <OTPmodal isOpen={isPopupOpen} onClose={closePopup} number={phone} whatsappupdate={isChecked} />
                        </div>
                    </div>
                </div>

                <div className='desktop'>
                    <div className="popup-content">
                        <div className='cancel-btn'><img src={cancel} onClick={onClose} alt='' /></div>
                        <div className="popup-body">
                            <div style={{ padding: "16px 16px 16px 16px" }}>
                                <h3>Login / Signup to continue</h3>
                                <form htmlFor="phoneNumber">
                                    <div className="phone-input">
                                        <span className="prefix">+91</span>
                                        <input
                                            type="tel"
                                            id="phoneNumber"
                                            placeholder='Enter your phone number'
                                            maxLength={10}
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            pattern="[0-9]*"
                                        />
                                    </div>
                                </form>
                                <label>
                                    <input
                                        type='checkbox'
                                        checked={isChecked} // Use the state value for the checkbox
                                        onChange={(e) => setIsChecked(e.target.checked)} // Update the state on checkbox change
                                    />                                    
                                    <span>Get order updates and offers on whatsapp</span>
                                </label>
                                <h4>By continiuing, I agree to the <span onClick={() => { navigate('/terms-conditions') }} style={{ cursor: "pointer" }}>Term of Use</span> & <span onClick={() => { navigate('/privacy-policy') }} style={{ cursor: "pointer" }}> Privacy Policy </span></h4>
                                <button className="login-btn" onClick={handleFindUser}>{isloding ? <>CONTINUE </> : <BeatLoader color="#ffffff" size={10} />}</button>
                            </div>


                            <OTPmodal isOpen={isPopupOpen} onClose={closePopup} number={phone} whatsappupdate={isChecked} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
