import React from 'react'
import Searchpage from '../components/home/search_page'

export default function SearchPage() {
  return (
    <>
       <Searchpage />
    </>
  )
}
