import React from 'react'
import Homeheader from '../layouts/home_header'
import Footer from '../layouts/footer'
import '../components/home/whychooseus.css'
export default function PrivacyPolciy() {
    return (
        <div>
            <Homeheader />
            <>

                <div className='why_choose_us_card'>
                    <div className="feat bg-gray pt-5 pb-5 card_pad">
                        <div className="container">
                            <div className="row">
                                <div className="section-head col-sm-12">
                                    <h4><span>Our Privacy</span> Policy</h4>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>Effective Date: 17 Aug 2023</h5>
                                    <p>Salon Ease is committed to safeguarding the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our application to book premium salon services at home. By using our services, you consent to the practices described in this policy.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>1. Information We Collect</h5>
                                    <p>We may collect the following types of information:</p>
                                    <ul>
                                        <li>Personal Information: This includes your name, address, phone number, email address, and other contact information provided during the booking process.</li>
                                        <li>Appointment Details: Information related to your booked services, including the type of services, date, time, and location.</li>
                                        <li>Payment Information: Credit/debit card details, billing address, and payment history for processing payments.</li>
                                        <li>Device Information: Information about your device (e.g., smartphone, tablet, computer), including device type, operating system, and browser.</li>
                                    </ul>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>2. How We Use Your Information</h5>
                                    <p>We use your information for the following purposes:</p>
                                    <ul>
                                        <li>Service Delivery: To schedule and deliver salon services at your specified location.</li>
                                        <li>Appointment Management: To send appointment confirmations, reminders, and updates.</li>
                                        <li>Billing and Payments: To process payments, send invoices, and maintain payment records.</li>
                                        <li>Personalization: To customize our salon services based on your preferences and history.</li>
                                        <li>Communication: To provide customer support, respond to your inquiries, and address your requests.</li>
                                    </ul>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>3. Information Sharing</h5>
                                    <p>We may share your information with:</p>
                                    <ul>
                                        <li>Service Providers: Licensed salon professionals who will provide the requested services.</li>
                                        <li>Payment Processors: Trusted partners who facilitate payment processing.</li>
                                        <li>Legal Compliance: When required by law or to protect our rights.</li>
                                    </ul>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>4. Security Measures</h5>
                                    <p>We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>5. Your Choices</h5>
                                    <p>You have the right to:</p>
                                    <ul>
                                        <li>Access, correct, or delete your personal information.</li>
                                        <li>Opt out of marketing communications.</li>
                                        <li>Withdraw consent for data processing.</li>
                                    </ul>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>6. Cookies and Tracking</h5>
                                    <p>We may use cookies and similar technologies to enhance your user experience on our website and mobile app.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>7. Children's Privacy</h5>
                                    <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>8. Changes to This Policy</h5>
                                    <p>We may update this Privacy Policy periodically. Any changes will be posted on our platform.</p>
                                </div>
                                <div className="col boxss col-sm-12">
                                    <h5>9. Contact Us</h5>
                                    <p>If you have any questions or concerns regarding our refund policy, please write us at:<br/>hello@salonease.in</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>





            </>
            <Footer />
        </div>
    )
}
