import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { serverAPI } from '.';

// Import all your pages
import Home from './pages/home';
import Booking from './pages/booking';
import LoginOtp from './pages/login-top';
import Login from './pages/login';
import Account from './pages/account';
import Cart from './pages/cart';
import PayUmoney from './pages/pay';
import Address from './pages/address';
import Timeslot from './pages/timeSlot';
import Payment from './pages/payment';
import Profile from './pages/profile';
import ProfileEdit from './pages/profile-edit';
import CurrentBooking from './pages/currentBooking';
import ManageAddress from './pages/manageAddress';
import NewAddress from './pages/newAddress';
import AllOders from './pages/allOders';
import TrackOrder from './pages/trackOrder';
import GetHelp from './pages/getHelp';
import Contact_Us from './pages/contactUs';
import RaiseQuery from './pages/raiseQuery';
import UserWallet from './pages/userWallet';
import MobileLogin from './pages/mobileLogin';
import MobileOTP from './pages/mobileOtp';
import NewHomePage from './pages/newhomepage';
import AboutUS from './pages/about';
import TremsOfUse from './pages/termsUse';
import RefundPolisy from './pages/refund';
import PrivacyPolciy from './pages/privacy';
import Homelocation from './pages/homelocation';
import SearchPage from './pages/searchPage';
import Cardservice from './pages/cardservice';
import Help from './pages/contactUsc';
import WorkWithUs from './pages/workwithus';
import PhonePay from './pages/phonepay';
import NotFoundPage from './pages/404';
import SubCategory from './components/home/SubCategory';

function App() {
  const isFirstCallRootUser = useRef(true);

  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        localStorage.setItem('uuid', JSON.stringify(res.data.user._id));
      }
    } catch (err) {
      localStorage.removeItem('uuid');
      localStorage.removeItem('cart');
    }
  };

  useEffect(() => {
    if (isFirstCallRootUser.current) {
      isFirstCallRootUser.current = false; // Set the flag to false after the first call
      getRootUser();
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Specific routes for city services */}
          <Route path="/ranchi-salon-at-home-services" element={<Home />} />
          <Route path="/patna-salon-at-home-services" element={<Home />} />
          <Route path="/jamshedpur-salon-at-home-services" element={<Home />} />
          <Route path="/raipur-salon-at-home-services" element={<Home />} />
          <Route
            path="/bhubaneshwar-salon-at-home-services"
            element={<Home />}
          />
          <Route path="/varanasi-salon-at-home-services" element={<Home />} />
          <Route path="/prayagraj-salon-at-home-services" element={<Home />} />
          <Route path="/ujjain-salon-at-home-services" element={<Home />} />
          <Route path="/meerut-salon-at-home-services" element={<Home />} />
          <Route path="/jhansi-salon-at-home-services" element={<Home />} />
          <Route path="/gurugram-salon-at-home-services" element={<Home />} />
          <Route path="/ludhiana-salon-at-home-services" element={<Home />} />
          <Route path="/chandigarh-salon-at-home-services" element={<Home />} />
          <Route path="/amritsar-salon-at-home-services" element={<Home />} />
          <Route path="/mohali-salon-at-home-services" element={<Home />} />
          <Route path="/salon-services-at-home" element={<Home />} />

          {/* Dynamic routes for cities and categories */}
          <Route path="/:city/:category_name" element={<SubCategory />} />
          <Route
            path="/at-home-services/:category_name"
            element={<SubCategory />}
          />

          {/* Main routes */}
          <Route path="/" element={<Home />} />
          <Route path="/services/:service_name" element={<Booking />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/address" element={<Address />} />
          <Route path="/timeslot" element={<Timeslot />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/profile_edit" element={<ProfileEdit />} />
          <Route path="/currentbooking" element={<CurrentBooking />} />
          <Route path="/manageaddress" element={<ManageAddress />} />
          <Route path="/newaddress" element={<NewAddress />} />
          <Route path="/allorders" element={<AllOders />} />
          <Route path="/trackorder" element={<TrackOrder />} />
          <Route path="/gethelp" element={<GetHelp />} />
          <Route path="/contactus" element={<Contact_Us />} />
          <Route path="/query" element={<RaiseQuery />} />
          <Route path="/se_wallet" element={<UserWallet />} />
          <Route path="/login" element={<MobileLogin />} />
          <Route path="/logfin-otp" element={<MobileOTP />} />
          <Route path="/newhome" element={<NewHomePage />} />
          <Route path="/about-us" element={<AboutUS />} />
          <Route path="/terms-conditions" element={<TremsOfUse />} />
          <Route path="/refund-policy" element={<RefundPolisy />} />
          <Route path="/privacy-policy" element={<PrivacyPolciy />} />
          <Route path="/homelocation" element={<Homelocation />} />
          <Route path="/searchpage" element={<SearchPage />} />
          <Route path="/servicepage" element={<Cardservice />} />
          <Route path="/profilmenu" element={<Account />} />
          <Route path="/help" element={<Help />} />
          <Route path="/work-with-us" element={<WorkWithUs />} />
          {/* <Route path="/test-payment" element={<PhonePay />} /> */}

          {/* Fallback route for undefined cities */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
