import React from 'react'
import Currentbooking from '../components/profilepage/currentbooking'
import HomeHeader from '../layouts/home_header'
import Footer from '../layouts/footer'

export default function CurrentBooking() {
    return (
        <>
            <HomeHeader />
            <Currentbooking />
            <Footer />
        </>
    )
}
