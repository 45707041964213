import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import House from '../../assets/images/house.png'
import './gethelp.css'
import usericon from '../../assets/images/Maskgroup3.png'
import Next from '../../assets/images/next.png'
import Bookoing from '../../assets/images/Maskgroup1.png'
import Address from '../../assets/images/Maskgroup4.png'
import Allorder from '../../assets/images/Maskgroup2.png'
import Help from '../../assets/images/Maskgroup7.png'
import Track from '../../assets/images/Maskgroup6.png'
import Wallet from '../../assets/images/Maskgroup5.png'
import Whatsapp from '../../assets/images/whatsapp.svg'
import Call from '../../assets/images/call.svg'
import Email from '../../assets/images/mail.svg'
import Swal from 'sweetalert2';
import axios from 'axios';
import { serverAPIe } from '../..'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";

export default function RegAsWork() {
    const [isloding, setIsLoding] = useState(true)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        yearofexprine: '',
        address: '',
        states: '',
        city: ''
    });

    const showSuccessPopup = async () => {
        const results = await Swal.fire({
            title: "Submitted Successfully!",
            text: `Thank you for showing interest, We will connect you shortly`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#981313",
            confirmButtonText: "Ok",
        });
        if (results.isConfirmed) {
            navigate('/');
        }
        
    };

    
    const handleSaveDetails = async () => {
        setIsLoding(false);

        // Check if any of the form data fields are empty

            if (!formData.name) {
                toast.warn("Please enter your name", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);
                return;
            }
            if (!formData.number) {
                toast.warn("Please enter your number", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);
                return;
            }
            if (!formData.yearofexprine) {
                toast.warn("Please enter your year of experince", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);
                return;
            }
            if (!formData.address) {
                toast.warn("Please enter your address", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);
                return;
            }
            if (!formData.states) {
                toast.warn("Please enter your state", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);
                return;
            }
            if (!formData.city) {
                toast.warn("Please enter your city", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setIsLoding(true);
                return;
            }
          
     
    

        // Alternate number validation
        const alternateNumberRegex = /^\d{10}$/;
        if (!alternateNumberRegex.test(formData.number)) {
            toast.error("Number should be 10 digits", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setIsLoding(true);
            return;
        }
        try {
            // Make an Axios PUT request to save user details
            const response = await axios.post(`${serverAPIe}/send-email`, formData);

            if (response.status === 200) {
                showSuccessPopup();
            } else {
                // Handle save failure
                console.error('Error saving user details:', response.status);
            }
        } catch (error) {
            toast.error("Something went wrong 😃!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setIsLoding(true);
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    return (
        <>
            <div className='profile-parent'>
                <div className='container'>
                    <div className='trackorder-wrap'>
                        <div className='Profile-divider' style={{ justifyContent: "center" }}>

                            <div className='container address-container'>

                                <div className='head-address'>
                                    <h3>Register as a professional</h3>
                                </div>
                                <div className='form-container' style={{ paddingTop: '1rem' }}>
                                    <div className='cartadd-form' >
                                        <h3 style={{ paddingLeft: "23px", fontSize: "18px", textAlign: 'center' }}>Fill your details we will get back to you.</h3>
                                        {/* <form className='loc-input'>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChanges}
                placeholder="Search for your society/apartment/location"
                style={{
                    marginTop: 0,

                }}
            />

            {
                results.length > 0 ?
                    <div className='address-option2' >
                        {results.map((result, index) => (
                            <div className='option2' key={index} onClick={() => { handleAddressChange(result) }}>
                                <img src={loctn} width='18px' height='17px' alt='' />
                                <div className='option-detl' >
                                    <h3 style={{ padding: "0px" }}>{result.description.split(', ')[0]}</h3>
                                    <p>{result.description}</p>
                                </div>
                            </div>

                        ))}
                    </div>
                    : null
            }
        </form> */}


                                        {/* <div className='anchor' style={{ marginTop: "20px" }}>
            {showModal ?
                <div style={{ display: "flex", alignContent: "center", justifyContent: "center", padding: "5px 0px" }}>
                    <PropagateLoader color="#981313" size={10} speedMultiplier={0.9} />
                </div>
                :
                <p style={{ margin: '0px', padding: "0px" }} onClick={handelCurrentLocation} ><img src={Precision} width="16px" style={{ marginRight: '5px' }} alt='' />Use currect location</p>
            }

            <Mapmodal isOpen={isPopupOpen} onClose={closePopup} />
            {showModal ? <>

                <div className="modal" >
                    <div className="popup-content" >
                        <div className='cancel-btn' style={{ display: 'block' }}><img src={cancel} onClick={handleCloseModal} alt='' /></div>
                        <div className='content'>
                            <div style={{ height: '100vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyAQC_edJGacx2iCQrWroNNeVLiYFVnAgwM" }}
                                    defaultCenter={currentLocation}
                                    defaultZoom={19}
                                >

                                </GoogleMapReact>
                            </div>
                            <div className='map-ftr'>
                                <div className='ftr-btn' onClick={handleCloseModal}>
                                    <button>Confirm Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></> : null}

        </div>
        {newAdd ? <><div className='textarea'>
            <h5>{areaName}</h5>
            <p>{currentAddress}</p>
        </div></> : null} */}


                                        <form>
                                            <div class="inputbox">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span>Enter your name</span>
                                            </div>
                                            <div class="inputbox">
                                                <input
                                                    type="number"
                                                    name="number"
                                                    value={formData.number}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span>phone number</span>
                                            </div>
                                            <div class="inputbox">
                                                <input
                                                    type="text"
                                                    name="yearofexprine"
                                                    value={formData.yearofexprine}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span>Year of experince</span>
                                            </div>
                                            <div class="inputbox">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span>Enter your Address</span>
                                            </div>
                                            <div class="inputbox">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span>Enter your City</span>
                                            </div>
                                            <div class="inputbox">
                                                <input
                                                    type="text"
                                                    name="states"
                                                    value={formData.states}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span>Enter your State</span>
                                            </div>

                                        </form>



                                        <div style={{ width: "92%" }} className='newadd-btn'>
                                            <button style={{ marginTop: "10px" }} className='btn-1' onClick={handleSaveDetails}> {isloding ? <>SUBMIT </> : <BeatLoader color="#ffffff" size={10} />}</button>
                                        </div>


                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
