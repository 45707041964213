
import React, { useEffect, useState } from 'react'
import cancel from '../../assets/images/cancel.png';
import './modal.css'
import Precision from '../../assets/images/gps.svg'
import Search from '../../assets/images/search.png'
import Arrowup from '../../assets/images/arrow-up.png'
import Location from '../../assets/images/loctn.png'
import axios from 'axios';
import PropagateLoader from "react-spinners/PropagateLoader"
import BeatLoader from "react-spinners/BeatLoader";
import loctn from '../../assets/images/pin.png'
import { serverAPI } from '../..';
export default function LocationPopup({ isOpen, onClose }) {
    const [addkey, setAddKey] = useState(null);
    const popupClass = isOpen ? 'popup open' : 'popup';

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('addkey'));
        if (items) {
            setAddKey(items);
        }
        if (isOpen) {
            // Disable scrolling on the body element when the pop-up is open
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable scrolling when the pop- is closed
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const [currentAddress, setCurrentAddress] = useState("");
    const [areaName, setAreaName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [islodings, setIsLodings] = useState(true)
    const [newAdd, setNewAdd] = useState(false);
    const [isloding, setIsLoding] = useState(true);

    const handleSaveAdd = () => {
        setIsLodings(false)

        setTimeout(() => {
            setIsLodings(true);
            localStorage.setItem('addkey', JSON.stringify(currentAddress))
            onClose();
            window.location.reload();
        }, 1500);

    }
    const handleAddressChange = (address) => {
        setIsLodings(false)
        if (currentAddress) {
            setCurrentAddress("")
            setNewAdd(false);
        }
        const addressParts = address.split(', ');

        const addresslist = []
        for (let i = 0; i < addressParts.length; i++) {
            addresslist.push(addressParts[i])
        }
        setAreaName(addresslist[0])
        // If you want to join the parts back into a string with commas
        const joinedAddress = addresslist.join(', ');
        setCurrentAddress(joinedAddress);
        setNewAdd(true);

        setTimeout(() => {
            setIsLodings(true);
            localStorage.setItem('addkey', JSON.stringify(address))
            onClose();
            window.location.reload();
        }, 1500);
    };

    const handelCurrentLocation = () => {
        setShowModal(true);
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    axios.get(`${serverAPI}/geocode`, {
                        params: {
                            lat: latitude,
                            lng: longitude,
                        },
                    })
                        .then(response => {
                            const formattedAddress = response.data.results[0].formatted_address;
                            const addressParts = formattedAddress.split(', ');

                            const addressList = [];
                            for (let i = 1; i < addressParts.length; i++) {
                                addressList.push(addressParts[i]);
                            }

                            // Use the addressList as needed
                            const joinedAddress = addressList.join(', ');
                            setAreaName(addressList[0]); // Assuming addressList[0] should be set as area name
                            setCurrentAddress(joinedAddress);
                            setTimeout(() => {
                                setNewAdd(true);
                                setShowModal(false);
                            }, 1500);
                        })
                        .catch(error => {
                            setShowModal(false);
                        });
                },
                (error) => {
                    setShowModal(false);
                }
            );
        } else {
            setShowModal(false);
        }
    };


    const [inputValue, setInputValue] = useState('');
    const [results, setResults] = useState([]);

    const fetchGooglePlaces = async (input) => {
        try {
            const response = await axios.post(`${serverAPI}/getPlaces`, {
                input: input
            });
            setResults(response.data.predictions);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const handleInputChanges = (e) => {
        setInputValue(e.target.value);
        fetchGooglePlaces(e.target.value);
    };
    return (
        <>
            <div className={popupClass}>

                <div className="popup-content">
                    <div className='cancel-btn'><img src={cancel} onClick={onClose} alt='' /></div>

                    <div className="location-body">

                        <div className="lctn">
                            <h4> <img src={Location} width='16px' height='16px' style={{ marginRight: '5px' }} alt='' />Enter Location</h4>
                            <div className="crntloc" onClick={handelCurrentLocation} style={{ cursor: "pointer" }}>


                                {/* */}

                                {showModal ?
                                    <div style={{ padding: "5px 93px" }}>
                                        <PropagateLoader color="#93294B" size={10} speedMultiplier={0.9} />
                                    </div>
                                    :
                                    <h4> <img src={Precision} width="16px" style={{ marginRight: '5px' }} alt='' /> Use current location</h4>
                                }
                            </div>
                        </div>
                        <div className="divdr"></div>
                        {newAdd ? <><div className='textarea' style={{ width: '90%' }}>
                            <h5>{areaName}</h5>
                            <p>{currentAddress}</p>
                        </div></> : null}
                        <form className='loc-input' >
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChanges}
                                placeholder="Search for your location/society/appartment"
                            />
                        </form>

                        <div className='address-option2' >
                            {results.map((result, index) => (
                                <div className='option2' key={index} onClick={() => { handleAddressChange(result.description) }}>
                                    <img src={loctn} width='18px' height='17px' alt='' />
                                    <div className='option-detl' >
                                        <h3>{result.description.split(', ')[0]}</h3>
                                        <p>{result.description}</p>
                                    </div>
                                </div>

                            ))}
                        </div>

                        <button className="login-btn" style={{ marginTop: "15px" }} onClick={handleSaveAdd}>{islodings ? <>CONTINUE </> : <BeatLoader color="#ffffff" size={10} />}</button>

                    </div>


                </div>

            </div>
        </>
    )
}
