import React from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import { Provider } from "react-redux";
import store from "./utils/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'
import { UserProvider } from './context/UserContext';

//export const serverAPI = "http://localhost:5905/api/v0.1";
export const serverAPIe = "https://salonease.in";
// export const serverAPIe = "http://localhost:2564";

//export const serverAPI = "https://salonease.onrender.com/api/v0.1"
export const serverAPI = "https://salonease.in/api/v0.1"
// export const serverAPI = "http://localhost:2564/api/v0.1"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </UserProvider>
  </React.StrictMode>
);