import React, { useEffect, useState } from 'react'
import cancel from '../../assets/images/cancel.png';
import './serviceDetailmodal.css'
import Time from '../../assets/images/timer.png'
import Star from '../../assets/images/Star2.png'
import image7 from '../../assets/images/v682_250.png'
import Back from '../../assets/images/arrow-left.png'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, incrementQuantity, decrementQuantity } from '../../utils/cartSlice';
import { toast } from "react-toastify";

import { selectTotalCost, selectTotalTime } from "../../utils/cartSlice";
export default function NewserviceModal({ isOpen, onClose, data }) {
    const popupClass = isOpen ? 'popup open' : 'popup';
    const [service, setService] = useState(data)
    useEffect(() => {
        if (isOpen) {
            // Disable scrolling on the body element when the pop-up is open
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable scrolling when the pop-up is closed
            document.body.style.overflow = 'auto';
        }
        setService(data)
    }, [isOpen]);

    // add to cart button with increment and decrement
    const dispatch = useDispatch();
    const cartProducts = useSelector((state) => state.cart.products);
    const isProductInCart = cartProducts.some((product) => product._id === service._id);
    const [count, setCount] = useState(isProductInCart ? cartProducts.find((product) => product._id === service._id).quantity : 1);
    const totalCost = useSelector(selectTotalCost);
    const totalTime = useSelector(selectTotalTime);
    const splitTime = totalTime.split(' ')[0];

    const handleAddToCart = (e) => {
        if (splitTime <= 6) {
            dispatch(addToCart(service));
            setCount(1); // Reset the count to 1 when adding to cart
            toast.success("Successfully added in cart 🥳!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
        }
        else {

            toast.error("You have reached the time limit of 7 hours!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            return;
        }

    };

    const handleIncrement = () => {
        dispatch(incrementQuantity({ productId: service._id }));
        if (count !== 3) {
            setCount(count + 1);
        }
    };

    const handleDecrement = () => {
        dispatch(decrementQuantity({ productId: service._id }));
        setCount(count - 1);
    };

    // Split the description into individual services
    const services = data?.description?.split(', ').map(service => service.trim());
    // Define the template for each service item
    const serviceTemplate = service => <li key={service}><span>{service}</span></li>;

    // Split the description into individual services
    const recommended = data?.recommended_for?.split(', ').map(recommended => recommended.trim());
    // Define the template for each service item
    const recommendedTemplate = recommended => <li key={recommended}><span>{recommended}</span></li>;

    // Split the description into individual services
    const whatwebring = data?.what_we_will_bring?.split(', ').map(whatwebring => whatwebring.trim());
    // Define the template for each service item
    const whatwebringTemplate = whatwebring => <li key={whatwebring}><span>{whatwebring}</span></li>;

    // Split the description into individual services
    const prerequriment = data?.pre_requirements?.split(', ').map(prerequriment => prerequriment.trim());
    // Define the template for each service item
    const prerequrimentTemplate = prerequriment => <li key={prerequriment}><span>{prerequriment}</span></li>;

    // Split the description into individual services
    const aftersevrice = data?.after_service_instructions?.split(', ').map(aftersevrice => aftersevrice.trim());
    // Define the template for each service item
    const aftersevriceTemplate = aftersevrice => <li key={aftersevrice}><span>{aftersevrice}</span></li>;

    return (
        <>
            <div className={popupClass}>

                <div className="popup-content">
                    <div className='cancel-btn'><img src={cancel} onClick={onClose} alt='' /></div>

                    <div className="ser-body">
                        <div className='ser-scroll'>

                            {/* <div className='navbar Navbar'>
                                <table>
                                    <tr>
                                        <th><img src={Back} width='19px' onClick={onClose} /></th>
                                        <td>Salon Ease Exclusive Facial</td>
                                    </tr>
                                </table>
                                
                                
                            </div> */}
                            <div className="product-detl d-flex  " style={{ justifyContent: "space-between" }}>
                                <div className="service_text">
                                    <h6>{data.service_name}</h6>
                                    <p style={{ marginTop: '20px' }}> <img src={Time} width="14px" height='14px' alt='' style={{ marginRight: '10px' }} />{data.time}</p>
                                    <p ><s>₹{parseInt(data.actual_price)}</s> <span className="mx-2 text-success">{parseFloat(data.discount).toFixed(2)}% off</span> ₹{parseInt(data.price)}/-</p>
                                    <div className="rating">
                                        {/* <img src={Star} width="11px" height="11px" alt=''/>
                                        <p> 4.8 (<span>623 Rating</span>)
                                        </p> */}
                                    </div>
                                </div>
                                <div className="Service-img" style={{ width: "170px" }}>
                                    <img src={data.image} width="210px" height="180px" alt='' />
                                </div>
                            </div>

                            {

                                data?.description === 'na' ? null :
                                    <div className="details2">
                                        <h5>Description</h5>

                                        <ul>
                                            {services?.map(serviceTemplate)}

                                        </ul>
                                    </div>

                            }
                            {/* <ul>
                                    <li><span>A basic facial that cleanses, exfoliates, and nourishes the skin, leaving
                                        it refreshes and rejuvenated.</span></li>
                                    <li><span>A basic facial that cleanses, exfoliates, and nourishes the skin, leaving</span></li>
                                    <li><span>A basic facial that cleanses, exfoliates, and nourishes the skin, leaving</span></li>
                                </ul> */}

                            {
                                data?.recommended_for === 'na' ?
                                    null
                                    :
                                    <>
                                        <div className="details2">
                                            <h5>Recommended for</h5>
                                            <div className="list1">
                                                <ul>
                                                    {recommended?.map(recommendedTemplate)}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                            }



                            {/* <div className="details2">
                                <h5>About product</h5>
                                <p >A basic facial that cleanses, exfoliates, and
                                    nourishes the skin, leaving
                                    it refreshes and rejuvenated.A basic facial that cleanses, exfoliates, and nourishes the skin, leaving
                                    it refreshes and rejuvenated.</p>
                            </div> */}
                            {
                                data.after_service_instructions === 'na' ?
                                    null
                                    :
                                    <>
                                        <div className="details2">
                                            <h5>After service instructions</h5>
                                            <div className="list1">
                                                <ul>
                                                    {aftersevrice?.map(aftersevriceTemplate)}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                            }

                            {
                                data.pre_requirements === 'na' ?
                                    null
                                    :
                                    <>
                                        <div className="details2">
                                            <h5>Pre requirements</h5>
                                            <div className="list1">
                                                <ul>
                                                    {prerequriment?.map(prerequrimentTemplate)}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                            }


                            {
                                data.what_we_will_bring === 'na' ?
                                    null
                                    :
                                    <>
                                        <div className="details2">
                                            <h5>What we will bring</h5>
                                            <div className="list1">
                                                <ul>
                                                    {whatwebring?.map(whatwebringTemplate)}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                            }
                            {/* <div className="details3">
                                <h5>Customers reviews</h5>
                                <div className="rating" style={{ marginLeft: '20px'}} >
                                    <img src={Star} width="11px" height="11px" />
                                    <p > 4.8 (<span>623 Rating</span>)
                                    </p>
                                </div>
                                <div className="rating1 ">
                                    <p>Kriti panday</p>
                                    <img src={Star} width="11px" height="11px" />
                                    <h6 >5</h6>
                                </div>
                                <p >Best service, thankyou salonease for making
                                    beauty needs so affordable and easy</p>
                                <div className="rating1 ">
                                    <p>Kriti panday</p>
                                    <img src={Star} width="11px" height="11px" />
                                    <h6 >5</h6>
                                </div>
                                <p >Best service, thankyou salonease for making
                                    beauty needs so affordable and easy</p>
                                <div className="rating1">
                                    <p>Kriti panday</p>
                                    <img src={Star} width="11px" height="11px" />
                                    <h6 >5</h6>
                                </div>
                                <p>Best service, thankyou salonease for making
                                    beauty needs so affordable and easy</p>
                                <div className="rating1 ">
                                    <p>Kriti panday</p>
                                    <img src={Star} width="11px" height="11px" />
                                    <h6 >5</h6>
                                </div>
                                <p >Best service, thankyou salonease for making
                                    beauty needs so affordable and easy</p>
                            </div> */}

                            {/* footer */}

                            <div class="cart_footer" style={{position: 'sticky'}}>
                                <div class="cart_left">
                                    <span class="total_Cost">Total Cost- ₹{parseInt(data.price)}/-</span>
                                    <span class="total_time">Total Time- {data.time}</span>
                                </div>
                                <div class="cart_right">
                                    {isProductInCart ? (
                                        <div className="service_cartbtn">
                                            <span onClick={handleDecrement}>-</span>
                                            <span className="counter">{count}</span>
                                            <span onClick={handleIncrement}>+</span>
                                        </div>
                                    ) : (
                                        <button onClick={handleAddToCart}>ADD TO CART</button>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
