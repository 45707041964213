import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "../utils/cartSlice";
import orderReducer from "../utils/orderSlice";

const storedCart = localStorage.getItem("cart");
const storedAddres = localStorage.getItem("address");
const storeduser = localStorage.getItem("user");
const storedPayment = localStorage.getItem("paymentMethod");
const storeSlot = localStorage.getItem("slot");
const storePaymentDetails = localStorage.getItem("paymentDetails");
const storeRazorpayDitail = localStorage.getItem("razorpayDitail");
const storeUserId = localStorage.getItem("userId");
const StoreAppointmentStatus = localStorage.getItem("appointmentStatus");
const StoreSubService = localStorage.getItem("subService");
const StoreSubNo = localStorage.getItem("subNo");



const initialState = {
  cart: {
    products: storedCart ? JSON.parse(storedCart) : [],
  },
  order: {
    address: storedAddres ? JSON.parse(storedAddres) : {},
    cart: storedCart ? JSON.parse(storedCart) : [],
    subService: StoreSubService ? JSON.parse(StoreSubService) : {},
    subNo: StoreSubNo ? JSON.parse(StoreSubNo) : "",
    user: storeduser ? JSON.parse(storeduser) : {},
    slot: storeSlot ? JSON.parse(storeSlot) : {},
    paymentDetails: storePaymentDetails ? JSON.parse(storePaymentDetails) : {},
    razorpayDitail: storeRazorpayDitail ? JSON.parse(storeRazorpayDitail) : {},
    paymentMethod: storedPayment ? JSON.parse(storedPayment) : "",
    userId: storeUserId ? JSON.parse(storeUserId) : "",
    appointmentStatus: StoreAppointmentStatus ? JSON.parse(StoreAppointmentStatus) : []
  },
};

const store = configureStore({
  reducer: {
    cart: cartReducer,
    order: orderReducer, 
  },
  preloadedState: initialState,
});
export default store;
