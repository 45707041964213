import React from 'react'
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import CartTimeslote from '../components/cart/cartTimeslot';
import Homeheader from '../layouts/home_header';

export default function TimeSlot() {
  return (
    <>
        <Homeheader />
        <CartTimeslote />
    </>
  )
}
