import React, { Component, useState, useEffect } from 'react';
import Slider from 'react-slick';
import mobile1 from '../../assets/BannerHomePageTop/re1.png';
import mobile2 from '../../assets/BannerHomePageTop/re2.png';
import mobile3 from '../../assets/BannerHomePageTop/re3.png';
import mobile5 from '../../assets/BannerHomePageTop/re5.png';
import mobile6 from '../../assets/BannerHomePageTop/re6.png';
import mobile7 from '../../assets/BannerHomePageTop/re7.png';
import { serverAPI } from '../..';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Mobilebanner() {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const [addkey, setAddKey] = useState(null);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('addkey'));
    if (items) {
      setAddKey(items);
    }
  }, []);

  const [searchInput, setSearchInput] = useState('');
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }
  const handleClick = (name, id) => {
    navigate(`/services/${toKebabCase(name)}`, {
      state: {
        name: name,
        _id: id,
      },
    });
  };

  useEffect(() => {
    const filtered = services.filter(service =>
      service.service_name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredServices(filtered);
  }, [searchInput, services]);

  return (
    <>
      <div className="mobile-banner">
        <Slider {...settings}>
          <div className="slide-img">
            <img
              src={window.innerWidth < 570 ? mobile1 : ''}
              alt="Book luxury salon services at home"
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slide-img">
            <img
              src={window.innerWidth < 570 ? mobile6 : ''}
              alt="Festive special offer on salon services"
              onClick={e => {
                e.preventDefault();
                handleClick('Top selling Packages', '651be5c2909e90359c9a5d11');
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slide-img">
            <img
              src={window.innerWidth < 570 ? mobile2 : ''}
              alt="Any 4 salon services at just 999"
              onClick={e => {
                e.preventDefault();
                handleClick(
                  'Create your own package',
                  '651be764909e90359c9a5d4d'
                );
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slide-img">
            <img
              src={window.innerWidth < 570 ? mobile3 : ''}
              alt="Anti ageing package at just 2799"
              onClick={e => {
                e.preventDefault();
                handleClick('Top selling Packages', '651be945909e90359c9a5dcf');
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slide-img">
            <img
              src={window.innerWidth < 570 ? mobile5 : ''}
              alt="Any 5 salon services at just 1649"
              onClick={e => {
                e.preventDefault();
                handleClick(
                  'Create Your own Package',
                  '651be793909e90359c9a5d54'
                );
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
          <div className="slide-img">
            <img
              src={window.innerWidth < 570 ? mobile7 : ''}
              alt="Complete Rica waxing at home at just 999"
              onClick={e => {
                e.preventDefault();
                handleClick('Waxing', '651bebbe909e90359c9a5e16'); //subcategory id
              }}
              style={{ cursor: 'pointer' }} // Optional: change cursor to pointer
            />
          </div>
        </Slider>
      </div>
    </>
  );
}
