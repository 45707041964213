import React, { useState, useEffect, useRef } from 'react';
// import cartimg from '../../assets/images/v682_250.png';

import timer from '../../assets/images/clock.png';
// import v682_250 from "../../assets/images/v682_250.png";
import '../modal/couponmodal.css';
import { useSelector, useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity } from '../../utils/cartSlice';
import cartImg from '../../assets/images/cart_empty.svg';
import { Link, useNavigate } from 'react-router-dom';
import Checkoutmodal from './chechoutmodal';
import BeatLoader from 'react-spinners/BeatLoader';
import { selectTotalCost, selectTotalTime } from '../../utils/cartSlice';
import { setOrderPaymentDetails, setOrderCart } from '../../utils/orderSlice';
import LoginModal from '../login/loginModal';
import percentage from '../../assets/images/percentage.png';
import cancel from '../../assets/images/cancel.png';
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import Confetti from './conffites';

export default function CartPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate function
  const products = useSelector(state => state.cart.products);
  const [islodings, setIsLodings] = useState(true);
  const totalCost = useSelector(selectTotalCost);
  const totalTime = useSelector(selectTotalTime);
  const [coupons, setCoupons] = useState([]);
  const [discount, setDiscount] = useState();
  const [subTotal, setSubTotal] = useState();
  const [disTotal, setDisTotal] = useState();
  const [isPopupOpenss, setIsPopupOpenss] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isLogin, setLogin] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  const hasFetchedUser = useRef(false); // Ref to track if the fetch has already been done

  const getRootUser = async () => {
    if (hasFetchedUser.current) return; // Exit if already fetched
    hasFetchedUser.current = true; // Set to true after the first fetch

    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        setUserData(res.data.user);
        localStorage.setItem('uuid', JSON.stringify(res.data.user._id));
        setPageLoaded(false);
        setLogin(true);
      }
    } catch (err) {
      localStorage.removeItem('uuid');
    }
  };

  useEffect(() => {
    getRootUser(); // Call the function to fetch user data
  }, []);
  const closePopupss = () => {
    navigate('/cart');
    setIsPopupOpenss(false);
  };

  useEffect(() => {
    setDiscount(disTotal - subTotal);
  }, [disTotal]);
  // // Define a state variable to hold the paymentDetails object
  const [paymentDetails, setPaymentDetails] = useState();

  const handleIncrement = product => {
    dispatch(incrementQuantity({ productId: product._id }));
  };

  const handleDecrement = product => {
    dispatch(decrementQuantity({ productId: product._id }));
  };

  let box = document.querySelector('.product-container');
  const btnpressprev = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
    // console.log(width)
  };
  const btnpressnext = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
    // console.log(width)
  };
  
  const hasFetched = useRef(false); // Ref to track if the fetch has already been done
  useEffect(() => {
    const fetchCoupons = async () => {
      if (hasFetched.current) return; // Exit if already fetched
      hasFetched.current = true; // Set to true after the first fetch
      try {
        const response = await axios.get(`${serverAPI}/coupons`);
        setCoupons(response.data);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };

    fetchCoupons(); // Call the function to fetch coupons
  }, []); 

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    if (parseInt(totalCost) >= 749) {
      const data = [];
      if (paymentDetails) {
        if (userData?.Wallet >= 100) {
          data.push({
            discount: discount,
            itemTotal: disTotal,
            payableAmount: subTotal - 100,
            couponDiscount: paymentDetails.couponDiscount,
            SEWallet: 100,
          });
        }
        data.push({
          discount: discount,
          itemTotal: disTotal,
          payableAmount: subTotal,
          couponDiscount: paymentDetails.couponDiscount,
        });
      } else {
        if (userData?.Wallet >= 100) {
          data.push({
            discount: discount,
            itemTotal: disTotal,
            payableAmount: subTotal - 100,
            SEWallet: 100,
          });
        }
        data.push({
          discount: discount,
          itemTotal: disTotal,
          payableAmount: subTotal,
        });
      }
      setIsLodings(false);
      const convertedData = data.reduce((result, item, index) => {
        result[index] = item;
        return result;
      }, {});
      const finalData = convertedData[0];
      setTimeout(() => {
        const userUui = JSON.parse(localStorage.getItem('uuid'));

        dispatch(setOrderCart({ cart: products }));
        dispatch(setOrderPaymentDetails({ paymentDetails: finalData }));
        if (userData.address?.home || userData.address?.other) {
          setIsPopupOpen(true);
        } else if (!userUui) {
          setIsPopupOpenss(true);
        } else {
          navigate('/address');
        }
        setIsLodings(true);
      }, 1500);
    } else {
      toast.error(
        'Please add more items to checkout (minimum checkout amount is 749)',
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        }
      );
      setTimeout(() => {
        setIsLodings(true);
      }, 1500);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // ----------------------------------------

  const [count, setCount] = useState(1);
  const [isAdding, setIsAdding] = useState(true);

  const handleToggle = () => {
    setIsAdding(!isAdding);
    if (!isAdding) {
      if (count > 1) {
        setCount(count - 1);
      } else {
        setIsAdding(true);
        setCount(1);
      }
    }
  };

  const addhandleIncrement = () => {
    setCount(count + 1);
  };

  const addhandleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    } else {
      setIsAdding(true);
      setCount(1);
    }
  };

  //-----------------------------------------------------
  //-----------------------------------------------------
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [finalTotal, setFinalTotal] = useState();
  const [applycoupon, setApplyCoupon] = useState();
  const [discountPrice, setDiscoutPrice] = useState();
  const calculateSubtotal = () => {
    let subtotal = 0.0;
    products.forEach(product => {
      const price = parseFloat(product.totalPrice);
      subtotal += price;
    });
    setSubTotal(subtotal);

    return subtotal;
  };

  const calculateSubtotalDis = () => {
    let distotal = 0.0;
    products.forEach(product => {
      const price = parseFloat(product.totalDiscountPrice);
      distotal += price;
    });
    setDisTotal(distotal);

    return distotal;
  };

  const calculateDis = () => {
    if (userData?.Wallet >= 100) {
      setSubTotal(subTotal - 100);
    }
  };

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const filteredCoupons = coupons.filter(coupon =>
    coupon.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    calculateSubtotal();
    calculateSubtotalDis();
  }, [products]);

  //  Coupon pop up -------------------
  const [islodin, setIsLodi] = useState(true);
  const [isPopupOpens, setIsPopupOpens] = useState(false);

  const openPopups = () => {
    if (!isChecked) {
      setIsLodi(false);
      setTimeout(() => {
        setIsPopupOpens(true);
        setIsLodi(true);
      }, 1500);
    } else {
      toast.error('Wallet balance used , can not apply coupon', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
    }
  };

  const closePopups = () => {
    setIsPopupOpens(false);
  };

  const [islodinApply, setIsLodinApply] = useState(true);
  const resmoveCupon = () => {
    setIsLodinApply(false);
    setTimeout(() => {
      calculateSubtotal();
      setDiscoutPrice(null);
      setApplyCoupon(null);
      setIsLodinApply(true);
    }, 1500);
  };
  const popupClass = isPopupOpens ? 'popup open' : 'popup';

  useEffect(() => {
    if (isPopupOpens) {
      // Disable scrolling on the body element when the pop-up is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling when the pop-up is closed
      document.body.style.overflow = 'auto';
    }
  }, [isPopupOpens]);

  const onapply = data => {
    if (
      Number(data.restriction) <= subTotal &&
      Number(data.discountPrice) <= subTotal
    ) {
      setApplyCoupon(data);
      // Your apply logic here
      setIsConfettiActive(true);
      const presentDiscountPrice = (subTotal * data.discountPrice) / 100;
      setDiscoutPrice(data.discountPrice);
      const calculateDis = subTotal - data.discountPrice;
      setPaymentDetails({ couponDiscount: data.discountPrice });
      setSubTotal(calculateDis);
      setApplyCoupon(data);
      // reset the confetti after a certain duration if needed
      setTimeout(() => {
        setIsConfettiActive(false);
        closePopups();
      }, 2000); // Adjust the duration as needed
    } else {
      toast.error('Can not apply this coupon.', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = e => {
    setIsChecked(e.target.checked);
  };

  // ----------------------------------------
  const [couponName, setCouponName] = useState('');
  const [couponData, setCouponData] = useState(null);
  const [couponLoad, setCouponLoad] = useState(true);
  const handleInputChange = e => {
    setCouponName(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setCouponLoad(false);
    // Your apply logic here
    try {
      const response = await axios.get(
        `${serverAPI}/coupons/find/${couponName}`
      );
      if (response.status === 200) {
        setCouponLoad(false);

        setApplyCoupon(response.data);
        const presentDiscountPrice =
          (subTotal * response.data.discountPrice) / 100;
        setDiscoutPrice(response.data.discountPrice);
        const calculateDis = subTotal - response.data.discountPrice;
        setPaymentDetails({ couponDiscount: response.data.discountPrice });
        setSubTotal(calculateDis);
        setApplyCoupon(response.data);

        // reset the confetti after a certain duration if needed
        setTimeout(() => {
          setIsConfettiActive(false);
          closePopups();
          setCouponLoad(true);
        }, 2000); // Adjust the duration as needed
      } else {
        toast.error('Invalid Coupon Code!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });
      }
    } catch (error) {
      setCouponData(null);
      setCouponLoad(true);
      toast.error('Invalid Coupon Code!', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
    }
  };

  return (
    <>
      <LoginModal isOpen={isPopupOpenss} onClose={closePopupss} />
      {products.length > 0 ? (
        <>
          {/* <Header /> */}
          <div className="cart-parent">
            <div className="container">
              <div className="Container-wrap" style={{ paddingBottom: '30px' }}>
                <div className="head">
                  <h3>Summary</h3>
                </div>
                <div className="product-details">
                  <div className="box-left">
                    {/* Summary Card */}
                    <div
                      className="summary-cards"
                      style={{ paddingBottom: '30px' }}
                    >
                      <div className="card-wraper">
                        {products?.map(product => {
                          const subService = JSON.parse(
                            localStorage.getItem('subService')
                          );
                          const services = product.description
                            ?.split(', ')
                            .map(service => service.trim());
                          const serviceTemplate = service => {
                            if (service === 'na') {
                              return null; // Skip the element
                            }
                            return <li key={service}>{service}</li>;
                          };

                          return (
                            <div
                              className="card-details details"
                              key={product._id}
                            >
                              <div className="card-det1">
                                <div className="card-img">
                                  <img src={product.image} alt="" />
                                </div>

                                <div className="card-heading">
                                  <h5>{product.service_name}</h5>
                                  <ul>
                                    {services && services.map(serviceTemplate)}
                                    {product?.subNo > 0 ? (
                                      <>
                                        {subService
                                          ?.filter(
                                            item => item.checked === true
                                          )
                                          ?.map((item, index) => (
                                            <li key={index}>{item.name}</li>
                                          ))}
                                      </>
                                    ) : null}
                                  </ul>
                                  <div className="lable">
                                    <img
                                      src={timer}
                                      width="17px"
                                      height="17px"
                                      alt=""
                                      style={{ marginRight: '5px' }}
                                    />{' '}
                                    {product.time}
                                  </div>
                                </div>
                              </div>
                              <div className="divider my-1"></div>
                              <div className="card-prize ">
                                <p className="parah2">
                                  <s style={{ color: '#989494' }}>
                                    ₹{parseInt(product.actual_price)}
                                  </s>
                                  <span>{product.discount}% off</span>₹
                                  {parseInt(product.price)}/-
                                </p>
                                <button type="button">
                                  <span
                                    className="plus"
                                    onClick={() => {
                                      handleDecrement(product);
                                    }}
                                  >
                                    -
                                  </span>
                                  <span className="Value">
                                    {product.quantity}
                                  </span>
                                  <span
                                    className="minus"
                                    onClick={() => {
                                      handleIncrement(product);
                                    }}
                                  >
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* Card slider */}
                    {/* 
                                    <div className='container'>

                                        <div className='mycard'>
                                            <h2>Frequently added together</h2>
                                            <button className="pre-btn" onClick={btnpressprev}><p>&lt;</p></button>
                                            <button className="next-btn" onClick={btnpressnext}><p>&gt;</p></button>
                                            <div className="product-container">
                                                <div className='card-box'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>

                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='card-box box1'>
                                                    <img src={v682_250} alt='' />
                                                    <h6>Manicure pedicure combo</h6>
                                                    <p >₹349</p>
                                                    {isAdding ? (
                                                        <button onClick={handleToggle}>ADD</button>
                                                    ) : (
                                                        <div className="service_cartbtn">
                                                            <span onClick={addhandleDecrement}>-</span>
                                                            <span className="counter">{count}</span>
                                                            <span onClick={addhandleIncrement}>+</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  </div>
                  <div className="box-right">
                    {/* Payment */}

                    <>
                      <div className="payment">
                        <div className="payment-details">
                          <h5>Payment summary</h5>
                          <table className="pay">
                            <tr>
                              <td>Item Total</td>
                              <th>₹ {parseFloat(disTotal).toFixed(2)}</th>
                            </tr>
                            <tr>
                              <td>Discount</td>
                              <th>₹ {parseFloat(discount).toFixed(2)}</th>
                            </tr>
                            {userData?.Wallet >= 100 ? (
                              <tr>
                                <td>
                                <label className="checkmark1">
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheck}
                                    className="checkmark-checkbox"
                                  />
                                  SE Wallet: {userData?.Wallet ?? 'Not available'}
                                  </label>
                                </td>
                                <th
                                  className={`danger ${
                                    isChecked ? '' : 'disabled'
                                  }`}
                                >
                                  {isChecked ? '- ₹ 100.00' : '- ₹ 0.00'}
                                </th>
                              </tr>
                            ) : null}
                            {discountPrice ? (
                              <tr>
                                <td>
                                  <p style={{ margin: '0', padding: '0' }}>
                                    {' '}
                                    Coupon Discount{' '}
                                  </p>
                                </td>
                                <th className="danger">
                                  - ₹ {parseFloat(discountPrice).toFixed(2)}
                                </th>
                              </tr>
                            ) : null}
                          </table>
                        </div>
                        <div className="total">
                          <table>
                            {userData?.Wallet >= 100 ? (
                              <tr>
                                <td>Payable amount</td>
                                <th className="success">
                                  ₹{' '}
                                  {isChecked
                                    ? parseFloat(subTotal - 100).toFixed(2)
                                    : parseFloat(subTotal).toFixed(2)}
                                  {/* ₹ {parseFloat(subTotal - 100).toFixed(2)} */}
                                </th>
                              </tr>
                            ) : (
                              <tr>
                                <td>Payable amount</td>
                                <th className="success">
                                  ₹ {parseFloat(subTotal).toFixed(2)}
                                </th>
                              </tr>
                            )}
                            {/* <tr>
                                                        <td>Payable amount</td>
                                                        <th className='success'>₹ {parseFloat(subTotal).toFixed(2)}</th>
                                                    </tr> */}
                          </table>
                        </div>
                        <div className="divider-line"></div>
                        <div className="coupon">
                          <table>
                            {discountPrice ? (
                              <tr>
                                <th>
                                  <img
                                    src={percentage}
                                    width="20px"
                                    height="20px"
                                    style={{ marginRight: '10px' }}
                                  />{' '}
                                  {applycoupon.name} <br />
                                  <span style={{ paddingLeft: '35px' }}>
                                    {applycoupon.description}
                                  </span>
                                </th>
                                <td
                                  style={{
                                    paddingRight: '13px',
                                    textDecoration: 'none',
                                    color: '#212529',
                                  }}
                                >
                                  <a
                                    href="#"
                                    style={{
                                      textDecoration: 'none',
                                      color: '#212529',
                                    }}
                                    onClick={resmoveCupon}
                                  >
                                    {islodinApply ? (
                                      <>Remove</>
                                    ) : (
                                      <BeatLoader color="#93294B" size={10} />
                                    )}
                                  </a>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <th>
                                  <img
                                    src={percentage}
                                    width="20px"
                                    height="20px"
                                    style={{ marginRight: '10px' }}
                                    alt=""
                                  />{' '}
                                  Apply coupon
                                </th>
                                <td>
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={openPopups}
                                  >
                                    {islodin ? (
                                      <>Apply</>
                                    ) : (
                                      <BeatLoader color="#93294B" size={10} />
                                    )}
                                  </a>
                                </td>
                              </tr>
                            )}
                            <>
                              {isPopupOpens ? (
                                <div className={popupClass}>
                                  {isConfettiActive && <Confetti />}

                                  <div className="popup-content">
                                    <div
                                      className="cancel-btn"
                                      style={{ display: 'block' }}
                                    >
                                      <img
                                        src={cancel}
                                        onClick={closePopups}
                                        alt=""
                                      />
                                    </div>
                                    <div className="cart_coupon-body">
                                      <div className="Coupon-heading">
                                        <h3>
                                          {' '}
                                          <img
                                            src={percentage}
                                            width="20px"
                                            height="20px"
                                            style={{
                                              marginRight: '15px',
                                              fontSize: '20px',
                                              fontWeight: '600',
                                            }}
                                            alt=""
                                          />{' '}
                                          Apply Coupons
                                        </h3>
                                        <div className="couponbox">
                                          <input
                                            type="text"
                                            value={couponName}
                                            onChange={handleInputChange}
                                          />
                                          <button onClick={handleSubmit}>
                                            {' '}
                                            {couponLoad ? (
                                              <>Apply</>
                                            ) : (
                                              <BeatLoader
                                                color="#ffffff"
                                                size={10}
                                              />
                                            )}
                                          </button>
                                        </div>
                                      </div>

                                      <div className="cupon-scroll">
                                        {filteredCoupons.map(coupon => (
                                          <div
                                            className="Couponcontent-box1"
                                            key={coupon._id}
                                          >
                                            <div
                                              className="offer"
                                              key={coupon._id}
                                            >
                                              <h4>{coupon.name}</h4>
                                              <h6>{coupon.description}</h6>
                                            </div>
                                            <p>
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  onapply(coupon);
                                                }}
                                              >
                                                Apply
                                              </a>
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          </table>
                        </div>
                      </div>
                    </>
                    {/* Image slider */}

                    {/* <div className='container img-container'>
                                        <h2>Offers and Discounts</h2>
                                        <div id="carouselExampleInterval " className="carousel slide " data-bs-ride="carousel" style={{ border: '1px solid #ebe8e8', marginTop: '20px' }}>
                                            <div className="carousel-inner">
                                                <div className="carousel-item active" data-bs-interval={1000}>
                                                    <img src={aaaa} className="d-block w-100 h-100" alt="..." />
                                                </div>
                                                <div className="carousel-item" data-bs-interval={2000}>
                                                    <img src={aaaa} className="d-block w-100 h-100" alt="..." />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src={aaaa} className="d-block w-100 h-100" alt="..." />
                                                </div>
                                            </div>
                                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </div>

                                    </div> */}

                    {/* footer */}

                    {/* <Addtoslot products={products} /> */}
                  </div>
                </div>
                <div class="cart_cost">
                  <div class="cart_left">
                    {userData?.Wallet >= 100 ? (
                      <>
                        {discountPrice ? (
                          <span className="total_Cost">
                            Total Cost - ₹
                            {parseInt(totalCost) - 100 - discountPrice}/-
                          </span>
                        ) : (
                          <span className="total_Cost">
                            Total Cost - ₹{parseInt(totalCost) - 100}/-
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {discountPrice ? (
                          <span className="total_Cost">
                            Total Cost - ₹{parseInt(totalCost) - discountPrice}
                            /-
                          </span>
                        ) : (
                          <span className="total_Cost">
                            Total Cost - ₹{parseInt(totalCost)}/-
                          </span>
                        )}
                      </>
                    )}
                    <span className="total_time">Total Time - {totalTime}</span>
                  </div>
                  <div
                    class="cart_right"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      // addData();
                      openPopup();
                    }}
                  >
                    <span class="service_cartbtn">
                      {islodings ? (
                        <>CHECKOUT</>
                      ) : (
                        <BeatLoader color="#ffffff" size={10} />
                      )}
                    </span>
                  </div>

                  <div
                    class="cart_addressbtn"
                    style={{ cursor: 'pointer' }}
                    onClick={openPopup}
                  >
                    <span class="service_cartbtn">
                      {' '}
                      {islodings ? (
                        <>ADD ADDRESS AND TIME SLOT</>
                      ) : (
                        <BeatLoader color="#ffffff" size={10} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Checkoutmodal isOpen={isPopupOpen} onClose={closePopup} />
        </>
      ) : (
        <>
          <div className="no_cart">
            <img src={cartImg} alt=" " className="no_cart_img" />
            <div className="no_cart_text">
              <h6>Your cart is empty!</h6>
              <p>Explore our wide selection and find something you like</p>
            </div>
            <div className="addbtn" style={{ margin: '0' }}>
              <Link to="/">
                <button>Back to Home</button>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}
